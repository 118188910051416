import React, { useState, useEffect } from "react";
import generalApi from '../../services/generalApi'
import Loader from "./../../component/shared/loader";
function RefferalDashboard(props) {
    const [loading, setLoading] = useState(true);
    const [myWallet, setWallet] = useState([])
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            certificate: userData["certificate"]
        }
        generalApi.getData('SSMReferalDashboardPartner', json).then(response => {
            console.log("setVideo", response["data"]);
            setWallet(response["data"])
            setLoading(false);
        });
    }, []);
    if (loading) {
        return <Loader></Loader>
    }
    return (
        <div className="container container-ver2">
            <div className="dashboard__list">
                <div className="order-column">
                    <div className="card-body text-center">
                        <i className="iconsminds-clock"></i>
                        <p className="font-weight-bold f-17 mb-0">Referral</p>
                        <p className="lead text-center">{myWallet["referal"]}</p>
                    </div>
                </div>
                <div className="order-column">
                    <div className="card-body text-center">
                        <i className="iconsminds-clock"></i>
                        <p className="font-weight-bold f-17 mb-0">Transction this month</p>
                        <p className="lead text-center">{myWallet["referal"]}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default RefferalDashboard;
