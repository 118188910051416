import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../../assets/css/checkboxStyle.css";
import generalApi from "./../../services/generalApi";
import paytmApi from "./../../services/paytmApi";
import { CartContext } from "./../../context/cartContext";
import { HomeContext } from "./../../context/homeContext";
import { OrderContext } from "./../../context/orderContext";
import { LoginContext } from "./../../context/loginContext";
import PaymentOption from "./../../component/payment/paymentOption";
import { priceinfo, shippingCharges, ismember, memberdiscountprice } from "./../../config/helper";
import OrderDetails from "./../../component/cart/orderDetails";
import * as dayjs from "dayjs";
import { useParams, withRouter } from "react-router-dom";
import Geocode from "react-geocode";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useTranslation } from "react-multi-lang";
function Checkout(props) {
    let { status, bankcode } = useParams();
    const t = useTranslation();
    let deliveryDate = dayjs().add("1", "day").format("YYYY-MM-DD");
    let deliveryTime = "08:00";
    let addressLandmark = "";
    let locationType = 1;
    let geoLocation = "";
    let latLocation = "";
    console.log("status", status);
    console.log("status", bankcode);
    if (status !== undefined && localStorage.getItem("userorderinfo")) {
        let orderinfo = JSON.parse(localStorage.getItem("userorderinfo"));
        deliveryDate = orderinfo["deliveryDate"];
        addressLandmark = orderinfo["addressLandmark"];
        deliveryTime = orderinfo["deliveryTime"];
        locationType = orderinfo["locationType"];
        geoLocation = orderinfo["geoLocation"];
        latLocation = orderinfo["latLocation"];
    }
    const [cartData, setcartData] = useContext(CartContext);
    const [isAuth] = useContext(LoginContext);
    const [homeData] = useContext(HomeContext);
    const [paymentDetails, SetPaymentDetails] = useState([]);
    const [order, setOrder] = useContext(OrderContext);
    let priceDes = priceinfo(cartData);
    let shippingCharge = shippingCharges(
        priceDes["totaloldprice"],
        priceDes["discount"],
        homeData["shippingwaiver"],
        homeData["shippingamount"]
    );
    Geocode.setApiKey("AIzaSyAfn0186e_Uq5kHupvAXXYrG8ER6kcqP8Y");
    const [isactive, setActive] = useState(false);
    const [locationdenied, setLocationDenied] = useState(false);
    const [billingData, setBillingData] = useState({
        deliveryDate: deliveryDate,
        deliveryTime: deliveryTime,
        addressLandmark: addressLandmark,
        locationType: locationType,
        geoLocation: geoLocation,
        latLocation: latLocation,
        locationError: "",
    });
    let temporder = order;
    temporder["deliveryDate"] = deliveryDate;
    temporder["addressLandmark"] = addressLandmark;
    temporder["locationType"] = locationType;
    temporder["geoLocation"] = geoLocation;
    temporder["latLocation"] = latLocation;
    setOrder(temporder);
    useEffect(() => {
        if (
            bankcode !== undefined &&
            status !== undefined &&
            status === "TXN_SUCCESS" &&
            localStorage.getItem("orderid")
        ) {
            let orderid = localStorage.getItem("orderid");
            let orderinfo = JSON.parse(localStorage.getItem("userorderinfo"));
            submitOrder(orderid, bankcode, "CARD");
        }
    }, []);
    useEffect(() => {
        if (billingData["locationType"] == 1) {
            getLocation();
        }
    }, []);
    useEffect(() => {
        console.log("paymentDetails", paymentDetails);
        if (paymentDetails["statuscode"] !== undefined) {
            document.getElementById("paytm_form").submit();
        }
    }, [paymentDetails]);
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            // billingData["locationError"] = SetError({ Clocation: "Geolocation is not supported by this browser." })
        }
    };
    const showPosition = (position) => {
        const L = position.coords.latitude + "," + position.coords.longitude;
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {
                const address = response.results[0].formatted_address;
                let tempOrder = order;
                tempOrder["geoLocation"] = L;
                tempOrder["latLocation"] = address;
                setOrder(tempOrder);
                setBillingData({
                    ...billingData,
                    geoLocation: L,
                    locationType: 1,
                    latLocation: address,
                });
            },
            (error) => {
                console.error(error);
            }
        );
    };
    const showError = (error) => {
        setBillingData({ ...billingData, locationType: 2 });
        switch (error.code) {
            case error.PERMISSION_DENIED:
                setLocationDenied(true);
                // billingData["locationError"] = SetError({ Clocation: "User denied the request for Geolocation." })
                break;
        }
    };
    let currencySymbol = homeData["currencysymbol"];
    const validateField = () => {
        let deliveryDateplus = dayjs().add("1", "day").format("YYYY-MM-DD");

        if (deliveryDateplus > billingData["deliveryDate"]) {
            toast.error(t('checkout.delivery_date_v'));
            return true;
        } else if (billingData["addressLandmark"] === "") {
            toast.error(t('checkout.landmark_v'));

            return true;
        } else if (parseInt(billingData["locationType"]) === 2) {
            if (billingData["geoLocation"] === "") {
                toast.error(t('checkout.deliverylocation_v'));
                return true;
            }
        } else if (billingData["geoLocation"] === "") {
            toast.error(t('checkout.deliverylocation_v'));
            return true;
        }
        setActive(true);
        payBill();
    };
    const handleAddress = (address) => {
        setBillingData({ ...billingData, latLocation: address });
    };
    const handleChange = (e) => {
        setBillingData({ ...billingData, [e.target.name]: e.target.value });
        if (e.target.name == "locationType" && e.target.value == 1) {
            getLocation();
        }
    };
    const handleSelect = (address) => {
        // setLocationData({ ...locationData, address: address });
        geocodeByAddress(address)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => {
                let l = latLng.lat + "," + latLng.lng;
                let tempOrder = order;
                tempOrder["geoLocation"] = l;
                tempOrder["latLocation"] = address;
                setOrder(tempOrder);
                setBillingData({
                    ...billingData,
                    geoLocation: l,
                    latLocation: address,
                });
            })
            .catch((error) => console.error("Error", error));
    };
    const payBill = () => {
        let user_data = JSON.parse(localStorage.getItem("user_data"));
        let Orderid = "";
        const orderjson = {
            customer: user_data["customerid"],
            certificate: user_data["certificate"],
        };
        generalApi.getData("SSMGenerateOrderID", orderjson).then((response) => {
            if (response["data"]["statuscode"] == "000") {
                Orderid = response["data"]["orderid"];
                let totalprice = (
                    parseFloat(priceDes["taxtotalprice"]) +
                    parseFloat(shippingCharge) -
                    parseFloat(order["coupndiscount"])
                ).toFixed(2);

                const json = {
                    customer: user_data["customerid"],
                    certificate: user_data["certificate"],
                    channelref: "Web",
                    netamount: totalprice,
                    vatamount: priceDes["taxamount"],
                    shippingamount: shippingCharge,
                    outlet: homeData["globaloutlet"],
                    orderid: "WEB" + Orderid,
                    status: "SUCCESS",
                    fbtoken: localStorage.getItem("fcm-token"),
                };
                if (order["paymentoption"] == "WALLET") {
                    walletPayment(json);
                } else if (order["paymentoption"] == "CASH") {
                    cashPayment(json);
                } else if (order["paymentoption"] == "CARD") {
                    paytmPayment(json);
                }
            } else {
                toast.error(response["data"]["statusdesc"]);
            }
        });
    };
    const walletPayment = (json) => {
        generalApi.getData("SSMPayByWalletPreOrder", json).then((response) => {
            if (response["data"]["statuscode"] == "000") {
                submitOrder(response["data"]["orderid"], response["data"]["paymentref"]);
            } else {
                setActive(false);
                toast.error(response["data"]["statusdesc"]);
            }
        });
    };
    const cashPayment = (json) => {
        generalApi.getData("SSMPayByCOdPreOrder", json).then((response) => {
            if (response["data"]["statuscode"] == "000") {
                submitOrder(response["data"]["orderid"], response["data"]["paymentref"]);
            } else {
                setActive(false);
                toast.error(response["data"]["statusdesc"]);
            }
        });
    };
    const paytmPayment = (json) => {
        json["reference"] = "Grocery Department";
        generalApi.getData("SSMPayTabsCheckSum2", json).then((response) => {
            if (response["data"]["statuscode"] == "000") {
                localStorage.setItem("orderid", response["data"]["orderid"]);
                // SetPaymentDetails(response["data"]);
                paytabs(response["data"]);
                console.log("Paytemresponse", response["data"]);
            } else {
                toast.error(response["data"]["statusdesc"]);
            }
        });
    };
    const paytabs = (response) => {
        let user_data = JSON.parse(localStorage.getItem("user_data"));

        let json = {
            payorder: response["payorder"],
            secretkey: response["mid"],
            emailid: response["emailid"],
            postalcode: response["postalcode"],
            country: response["country"],
            currencycode: response["currencycode"],
            txntitle: response["txntitle"],
            customeremail: response["customeremail"],
            mobile: response["mobile"],
            amount: response["amount"],
            first_name: user_data["firstname"],
            last_name: user_data["lastname"],
            address: billingData["latLocation"],
        };
        paytmApi.getData("http://freshin.testintu.us/generate_url.php", json).then((res) => {
            console.log("paytabs", res["data"]["payment_url"]);
            localStorage.setItem("p_id", res["data"]["p_id"]);
            localStorage.setItem("userorderinfo", JSON.stringify(billingData));
            window.location.replace(res["data"]["payment_url"]);
        });
    };
    const submitOrder = (orderid, paymentref, paymenttype = "") => {
        let user_data = JSON.parse(localStorage.getItem("user_data"));
        console.log("cartDatacartDatajj", cartData);
        let multiChoice = [];
        let multiorderdata = [];
        let discountprice = 0;
        for (let index = 0; index < cartData.length; index++) {
            let quantityType = cartData[index]["quantitytype"];
            let dunitqty = cartData[index]["p_info"]["qtylist"][quantityType]["dunitqty"];
            let dquantitydesc = cartData[index]["p_info"]["qtylist"][quantityType]["dquantitydesc"];
            let element = {};
            let itemprice = 0;
            let itemtotalprice = 0;
            // Discount amount price calculate
            if (parseInt(cartData[index]["p_info"]["qtylist"][quantityType]["disoffer"]) !== 0) {
                discountprice += ismember(isAuth["isLogin"])
                    ? parseFloat(cartData[index]["oldtotalprice"]) -
                    memberdiscountprice(parseFloat(cartData[index]["itemtotalPrice"]))
                    : parseFloat(cartData[index]["oldtotalprice"]) -
                    parseFloat(cartData[index]["itemtotalPrice"]);
                itemprice = ismember(isAuth["isLogin"])
                    ? memberdiscountprice(cartData[index]["itemprice"])
                    : cartData[index]["itemprice"];
                itemtotalprice = parseFloat(itemprice) * cartData[index]["itemquantity"];
            } else {
                discountprice += ismember(isAuth["isLogin"])
                    ? parseFloat(cartData[index]["oldtotalprice"]) -
                    memberdiscountprice(parseFloat(cartData[index]["oldtotalprice"]))
                    : 0;
                itemprice = ismember(isAuth["isLogin"])
                    ? memberdiscountprice(cartData[index]["itemoldprice"])
                    : cartData[index]["itemoldprice"];
                itemtotalprice = parseFloat(itemprice) * cartData[index]["itemquantity"];
            }
            element["multiitem"] = cartData[index]["itemcode"];
            element["multiname"] = cartData[index]["itemname"];
            element["multiprice"] = itemprice.toString();
            element["multitotal"] = itemtotalprice.toString();
            element["multiqty"] = cartData[index]["itemquantity"].toString();
            element["itmmltunt"] = dunitqty + dquantitydesc;
            element["itmtaxpct"] = cartData[index]["p_info"]["taxpercent"];
            //
            let d_element = {};
            d_element["itmmltcod"] = cartData[index]["itemcode"];
            d_element["itmmltnam"] = cartData[index]["itemname"];
            d_element["itmmltprc"] = itemprice.toString();
            d_element["itmmltamt"] = itemtotalprice.toString();
            d_element["itmmltqty"] = cartData[index]["itemquantity"].toString();
            d_element["itmmltunt"] = dunitqty + dquantitydesc;
            d_element["itmtaxpct"] = cartData[index]["p_info"]["taxpercent"];
            // d_element["itmmltqty"] = 'UN';
            multiChoice.push(element);
            multiorderdata.push(d_element);
        }
        console.log("multiChoice", multiChoice);
        console.log("multiChoice", multiorderdata);
        console.log("multiChoice", cartData);
        let totalprice = (
            parseFloat(priceDes["taxtotalprice"]) +
            parseFloat(shippingCharge) -
            parseFloat(order["coupndiscount"])
        ).toFixed(2);
        console.log("orderid", orderid);
        let prefix_order = orderid.substring(0, 3);
        if (prefix_order === "WEB") {
            orderid = orderid.substring(3);
        }
        const json = {
            customer: user_data["customerid"],
            certificate: user_data["certificate"],
            catalogcode: "00000000000000000000",
            channelref: "WEB",
            orderid: "WEB" + orderid,
            deliverydate: dayjs(billingData["deliveryDate"] + billingData["deliveryTime"]).format(
                "DD:MM:YYYY:HH:mm:ss"
            ),
            city: billingData["latLocation"],
            itemcode: "0000000",
            coupon: order["offercode"],
            additionalinfo: "FRESSH",
            coupontype: order["offerType"],
            managedservice: "",
            address: billingData["addressLandmark"] + " " + billingData["latLocation"],
            geolocation: billingData["geoLocation"],
            fbtoken: localStorage.getItem("fcm-token"),
            sameaddress: "",
            multichoice: multiChoice,
            multiorderdata: multiorderdata,
            paymentdate: dayjs().format("DD:MM:YYYY:HH:mm:ss"),
            paymentref: paymentref,
            paymenttype: paymenttype !== "" ? paymenttype : order["paymentoption"],
            netamount: totalprice.toString(),
            voucheramount: order["coupndiscount"],
            discountamount: discountprice.toString(),
            taxamount: priceDes["totaltaxamount"],
            shippingamount: shippingCharge,
            memberdiscount: user_data["maxspend"],
        };
        console.log("Order Json ", json);

        generalApi.getData("SSMCreateServiceOrder", json).then((response) => {
            if (response["data"]["statuscode"] == "000") {
                localStorage.removeItem("userorderinfo");
                localStorage.removeItem("orderid");
                localStorage.removeItem("coupeninfo");
                localStorage.removeItem("cartData");
                let msg = {
                    heading: t("message.success"),
                    des: t("message.ordermessage", { orderid: "WEB" + orderid }),
                    link: "/current-order",
                    linkname: t("message.myorder"),
                };
                setcartData([]);
                localStorage.setItem("message", JSON.stringify(msg));
                props.history.push("/message");
            } else {
                setActive(false);
                toast.error(response["data"]["statusdesc"]);
            }
        });
    };
    return (
        <div className="cart-box-container check-out">
            <div className="container container-ver2">
                <div className="row">
                    <div className="col-md-12 space-30">
                        <div className="box">
                            <h3 className="title-brand text-center raashan-color">
                                {t("checkout.your_order")}
                            </h3>
                            <OrderDetails></OrderDetails>
                        </div>
                    </div>
                </div>
                <div className="row checkout-address">
                    <div className="col-md-12">
                        <h3 className="title-brand raashan-color">
                            {t("checkout.billing_address")}
                        </h3>
                        <div className="form-group col-md-12">
                            <label className="control-label">
                                {t("checkout.delivery_date")} <span className="color">*</span>
                            </label>
                            <input
                                type="date"
                                name="deliveryDate"
                                onChange={handleChange}
                                value={billingData["deliveryDate"]}
                                className="form-control englisg-text"
                            />
                        </div>
                        <div className="form-group col-md-12">
                            <label className="control-label">
                                {t("checkout.delivery_time")} <span className="color">*</span>
                            </label>
                            {/* <input type="time" name="deliveryTime" onChange={handleChange} value={billingData["deliveryTime"]} className="form-control" /> */}
                            <select
                                name="deliveryTime"
                                className="form-control englisg-text"
                                defaultValue={billingData["deliveryTime"]}
                                onChange={handleChange}
                            >
                                <option value="08:00">08 AM to 11 AM</option>
                                <option value="11:00">11 AM to 02 PM</option>
                                <option value="14:00">02 PM to 05 PM</option>
                                <option value="17:00">05 PM to 08 PM</option>
                            </select>
                        </div>
                        <div className="form-group col-md-12">
                            <label className=" control-label">
                                {t("checkout.address_landmark")}
                                <span className="color">*</span>
                            </label>
                            <textarea
                                name="addressLandmark"
                                onChange={handleChange}
                                className="form-control"
                                cols="30"
                                rows="3"
                            >
                                {billingData["addressLandmark"]}
                            </textarea>
                        </div>
                        <div className="form-group col-md-12">
                            <label className="control-label">{t("checkout.location")} </label>
                            <div className="profile-gender location__option">
                                <label className="paymentcontainer">
                                    <h4>{t("checkout.current_location")}</h4>
                                    <input
                                        type="radio"
                                        onChange={handleChange}
                                        disabled={locationdenied ? true : false}
                                        checked={billingData["locationType"] == 1 ? true : false}
                                        value={1}
                                        name="locationType"
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="paymentcontainer">
                                    <h4>{t("checkout.custom_location")}</h4>
                                    <input
                                        type="radio"
                                        onChange={handleChange}
                                        checked={billingData["locationType"] == 2 ? true : false}
                                        value={2}
                                        name="locationType"
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        {parseInt(billingData["locationType"]) === 2 ? (
                            <div className="form-group col-md-12">
                                <label className=" control-label">
                                    {t("checkout.custom_location")}
                                    <span className="color">*</span>
                                </label>
                                <PlacesAutocomplete
                                    value={billingData["latLocation"]}
                                    onChange={handleAddress}
                                    name="address"
                                    onSelect={handleSelect}
                                >
                                    {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                    }) => (
                                            <div className="">
                                                <input
                                                    {...getInputProps({
                                                        placeholder: t("checkout.enter_location"),
                                                        name: "address",
                                                        autoComplete: "off",
                                                        className:
                                                            "location-search-input englisg-text form-control",
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container englisg-text">
                                                    {loading && <div>{t("checkout.loading")}</div>}
                                                    {suggestions.map((suggestion) => {
                                                        const className = suggestion.active
                                                            ? "suggestion-item--active"
                                                            : "suggestion-item";
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? {
                                                                backgroundColor: "#fafafa",
                                                                cursor: "pointer",
                                                            }
                                                            : {
                                                                backgroundColor: "#ffffff",
                                                                cursor: "pointer",
                                                            };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                </PlacesAutocomplete>
                            </div>
                        ) : null}
                        <PaymentOption></PaymentOption>
                        <button
                            className="link-v1 box rt full btn market-bcolor"
                            disabled={isactive ? true : false}
                            onClick={validateField}
                        >
                            {isactive ? t("checkout.processing") : t("checkout.place_order")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(Checkout);
