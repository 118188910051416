import React, { useState, createContext } from "react";
// Create Context of Booking
export const CartContext = createContext();

// Create Provider that hold the sharable information
export const CartProvider = props => {
    let cart = JSON.parse(localStorage.getItem('cartData'))
    console.log("cartcart", cart);

    const [cartData, setCartData] = useState(cart ? cart : []);
    return (
        <CartContext.Provider value={[cartData, setCartData]}>
            {props.children}
        </CartContext.Provider>
    );
};
