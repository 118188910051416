import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductQuantity from "./../shared/productQuantity";
import { useTranslation } from "react-multi-lang";
import Arrow from "./Arrow";
import { Link } from "react-router-dom";
function FeautreProduct(props) {
    const t = useTranslation();
    let productImages = props.productImages;

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <Arrow arrowType="right" arrow="angle-right" />,
        prevArrow: <Arrow arrowType="left" arrow="angle-left" />,
        responsive: [
            {
                breakpoint: 1248,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className="container container-ver2">
            <div className="categories__heading">
                <div className="row">
                    <div className="col-xs-7">
                        <h1 className="title">{t("home.feautres_products")}</h1>
                    </div>
                    <div className="col-xs-5 text-right">
                        <Link to="/deal-list" className="link">
                            {t("home.view_all")}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="featured-products">
                <div className="tab-container space-50">
                    <div id="tab_03" className="tab-content active">
                        <div className="products">
                            <Slider {...settings}>
                                {productImages.map((info, i) => (
                                    <div className="product__wrapper" key={i}>
                                        <div className="product" style={{ float: "none" }} key={i}>
                                            <ProductQuantity
                                                showfav={props.showfav}
                                                key={info["itemcode"]}
                                                productinfo={info}
                                                qtylist={info["qtylist"]}
                                            ></ProductQuantity>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default FeautreProduct;
