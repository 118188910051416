import React, { useState, createContext } from "react";
import { IsLogin, IsVendor } from './../config/auth'
// Create Context of Booking
export const LoginContext = createContext();
// Create Provider that hold the sharable information
export const LoginProvider = props => {
    const [isAuth, setAuth] = useState(
        { isLogin: IsLogin(), isVendor: IsVendor() });
    return (
        <LoginContext.Provider value={[isAuth, setAuth]}>
            {props.children}
        </LoginContext.Provider>
    );
};
