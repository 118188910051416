import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import generalApi from '../../services/generalApi'
import { toast } from 'react-toastify';
import ItemDetailsModal from './itemDetailsModel'
import { useTranslation } from 'react-multi-lang'
import Modal from "../shared/modal";
function OrderBtn(props) {
    const t = useTranslation()
    let orderinfo = props.orderinfo
    const [loading, setLoading] = useState(false);
    const [isopen, setopen] = useState(false)
    const [iscopen, setCOpen] = useState(false)
    const toggleModal = () => {
        setopen(!isopen)
    }
    const cancalOrder = () => {
        setLoading(true);
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            customer: userData["customerid"],
            certificate: userData["certificate"],
            orderid: orderinfo["orderid"],
            fbtoken: localStorage.getItem("fcm-token"),
        }
        generalApi.getData('SSMCancelServiceOrderCustomer', json).then(response => {
            if (response["data"]["statuscode"] == "000") {
                let message = { heading: "Success!", des: `Order # ${orderinfo["orderid"]} successfully Cancelled`, link: "/current-order", linkname: "New Order" }
                localStorage.setItem('message', JSON.stringify(message))
                props.history.push('/message')
            } else {
                setLoading(false);
                toast.error(response["data"]["statusdesc"])
            }
        });
    }
    return (
        <React.Fragment>
            <Modal classes="rm__p" isOpen={iscopen}>
                <div className="modal-header">
                    <button type="button" onClick={() => setCOpen(!iscopen)} class="close" data-dismiss="modal">
                        ×
                        </button>
                    <div className="modal-title">{t('order.sure_cancel')}</div>
                </div>
                <div className="modal-body"></div>
                <div className="modal-footer modal-footer-sm">
                    <button className="btn btn-success market-bcolor" onClick={() => setCOpen(!iscopen)}>Cancel</button>
                    <button className="btn btn-info raashan-bcolor" onClick={cancalOrder}>Ok</button>
                </div>
            </Modal>
            <div className="order__action-btn">
                <button onClick={toggleModal} className="btn mt-1 btn-order__custom btn-sm btn-primary raashan-bcolor">
                    {t('order.showdetails')}
                </button>
                {orderinfo["logstatus"] === "1" ?
                    <button onClick={() => setCOpen(!iscopen)} className="btn mt-1 btn-order__custom btn-sm btn-danger market-bcolor">
                        {loading ? t('order.processing') : t('order.cancelorder')}
                    </button>
                    : null}
            </div>
            <ItemDetailsModal orderinfo={orderinfo} closeModal={toggleModal} isOpen={isopen} />
        </React.Fragment>
    );
}
export default withRouter(OrderBtn);
