import React, { useState, createContext } from "react";
// Create Context of Booking
export const OrderContext = createContext();
// Create Provider that hold the sharable information
export const OrderProvider = props => {
    let coupeninfo = JSON.parse(localStorage.getItem('coupeninfo'))
    let offercode = coupeninfo ? coupeninfo["offercode"] : "";
    let offerType = coupeninfo ? coupeninfo["offerType"] : "";
    let coupndiscount = coupeninfo ? coupeninfo["coupndiscount"] : 0;
    const [order, setOrder] = useState(
        {
            deliveryDate: "",
            addressLandmark: "",
            geoLocation: "",
            latLocation: "",
            locationType: "",
            paymentoption: "",
            offercode: offercode,
            offerType: offerType,
            coupndiscount: coupndiscount
        }
    );
    return (
        <OrderContext.Provider value={[order, setOrder]}>
            {props.children}
        </OrderContext.Provider>
    );
};
