import React, { useState, useEffect, useContext } from "react";
import Routes from "./config/route";
import generalApi from "./services/generalApi";
import Loader from "./component/shared/loader";
import { HomeContext } from "./context/homeContext";
import { OrderProvider } from "./context/orderContext";
import { LoginProvider } from "./context/loginContext";
import { LanguageContext } from "./context/languageContext";
import "./assets/css/responsiveStyle.css";
function App() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [language] = useContext(LanguageContext);
    const [homeData, setHomeData] = useContext(HomeContext);
    useEffect(() => {
        if (language === "arabic") {
            require("./assets/css/style.css");
            require("./assets/css/customStyle.css");
            // require("./assets/css/arabic.css");
        } else {
            require("./assets/css/style.css");
            require("./assets/css/customStyle.css");
        }
    }, []);
    useEffect(() => {
        generalApi.getData("SSMinitialize", []).then((response) => {
            setHomeData(response["data"]);
            setData(response["data"]);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (language === "english") {
            document.querySelector("html").setAttribute("lang", "en");
        } else {
            document.querySelector("html").setAttribute("lang", "ar");
        }
    }, [language]);

    if (loading) {
        return <Loader></Loader>;
    }
    return (
        <div className="wrappage">
            <LoginProvider>
                <OrderProvider>
                    <Routes homeData={data}></Routes>
                </OrderProvider>
            </LoginProvider>
        </div>
    );
}

export default App;
