import React, { useState } from 'react';
import generalApi from '../../services/generalApi';
import { useTranslation } from 'react-multi-lang';
function Notification(props) {
    const t = useTranslation();
    let notificationinfo = props.notificationinfo;
    const [isread, setRead] = useState(notificationinfo['isread'] === "0" ? false : true);
    const [isdelete, setDelete] = useState(false);
    const [text, setText] = useState(notificationinfo['narration120']);
    const deletenoti = () => {
        let userData = JSON.parse(localStorage.getItem('user_data'));
        setText(notificationinfo['narration']);
        const json = {
            customer: userData['customerid'],
            certificate: userData['certificate'],
            reference: notificationinfo["sequence"]
        };
        generalApi.getData('SSMmessageDeleteCustomer', json).then((response) => {
            setDelete(true)
        });
    }
    const readmore = () => {
        let userData = JSON.parse(localStorage.getItem('user_data'));
        setText(notificationinfo['narration']);
        setRead(true)
        const json = {
            customer: userData['customerid'],
            certificate: userData['certificate'],
            reference: notificationinfo["sequence"]
        };
        generalApi.getData('SSMmessageReadCustomer', json).then((response) => {

        });
    };
    return (
        <div className={`notification__list ${isdelete ? 'd-none' : ''}`}>
            <div className={`notification__item  d-flex align-items-center ${!isread ? "isunread" : ""}`}>
                <div className='notification__text'>

                    {!isread ? text : notificationinfo['narration']}
                    <span className='read-more' onClick={readmore}>
                        {!isread ? t('other.readmore') : ''}
                    </span>
                </div>
                <div className='d-flex flex-column'>
                    <div className='notification__actions d-flex justify-content-center align-items-center'>
                        <div className='notification__unread mr-2'></div>
                        <i className='fa fa-trash delete__notification text-danger' onClick={deletenoti}></i>
                    </div>

                    <div className='notification__date'>{notificationinfo['transactiondate']}</div>
                </div>
            </div>
        </div>
    );
}
export default Notification;
