import React, { useState, createContext } from "react";
// Create Context of Booking
export const HomeContext = createContext();

// Create Provider that hold the sharable information
export const HomeProvider = props => {
    const [homeData, setHomeData] = useState([]);
    return (
        <HomeContext.Provider value={[homeData, setHomeData]}>
            {props.children}
        </HomeContext.Provider>
    );
};
