import React, { useState, useContext } from "react";
import generalApi from './../services/generalApi'
import { toast } from "react-toastify";
import { LoginContext } from './../context/loginContext'
function SetFavourte(props) {
    const [isSaved, setSaved] = useState(props.isfav !== '' ? true : false);
    const [isAuth] = useContext(LoginContext)
    const setfavourte = () => {
        if (isAuth["isLogin"]) {
            favourte(!isSaved)
            setSaved(!isSaved);
        } else {
            toast.error("Please login to add favourte")
        }
    }
    const favourte = (status) => {
        let user_data = JSON.parse(localStorage.getItem("user_data"));
        const json = {
            customer: user_data["customerid"],
            certificate: user_data["certificate"],
            status: status ? 0 : 1,
            itemcode: props.itemcode
        };
        generalApi.getData("SSMAddFavourite", json).then(response => {
            if (response["data"]["statuscode"] === "000") {

            } else {
                toast.error(response["data"]["statusdesc"]);
            }
        });
    }
    return (
        <React.Fragment>
            <div className="save__btn" onClick={setfavourte}>
                <i className={`icon fa ${isSaved ? "fa-heart" : "fa-heart-o"}`}></i>
            </div>
        </React.Fragment>
    );
}
export default SetFavourte;
