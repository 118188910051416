import React from "react";
import { Link } from "react-router-dom";
// import UserNavbar from "./menu";
function UserNavbar(props) {
    return (
        <React.Fragment>
            <li className="sub__menu">
                <Link to="/vendor/dashboard">
                    <i className="fa fa-tachometer"></i>
                    Dashboard
                </Link>
            </li>
            <li className="sub__menu">
                <Link to="/">
                    <i className="fa fa-list"></i>
                    Order Management
                    </Link>
                <ul className="dropdown__sub-menu">
                    <li>
                        <Link to="/vendor/new-order">
                            <i className="fa fa-plus-square-o"></i>
                            New Order</Link>
                    </li>
                    <li>
                        <Link to="/vendor/load-order">
                            <i className="fa fa-spinner"></i>
                            Load Order</Link>
                    </li>
                    <li>
                        <Link to="/vendor/transit-order">
                            <i className="fa fa-money"></i>
                            Transit Order</Link>
                    </li>
                    <li>
                        <Link to="/vendor/balance-order">
                            <i className="fa fa-balance-scale"></i>
                            Balance Order</Link>
                    </li>
                    <li>
                        <Link to="/vendor/order-history">
                            <i className="fa fa-history"></i>
                            Order History</Link>
                    </li>
                </ul>
            </li>
            <li className="sub__menu">
                <Link to="/">
                    <i className="fa fa-list"></i>
                    Employee
                    </Link>
                <ul className="dropdown__sub-menu">
                    <li>
                        <Link to="/vendor/employee">
                            <i className="fa fa-plus-square-o"></i>
                            All Employee</Link>
                    </li>
                    <li>
                        <Link to="/vendor/add-employee">
                            <i className="fa fa-plus-square-o"></i>
                            Add Employee</Link>
                    </li>
                </ul>
            </li>
            {/* <li className="sub__menu">
                <Link to="/notification">
                    <i className="fa fa-bell"></i>
                    Notification
                    </Link>
            </li> */}
            <li className="sub__menu">
                <Link to="/vendor/setting">
                    <i className="fa fa-cog"></i>
                    Setting
                    </Link>
            </li>
            <li className="sub__menu">
                <Link to="/vendor/refferal-dashboard">
                    <i className="fa fa-circle-o-notch"></i>
                    Refferal Dashboard
                    </Link>
            </li>
            <li className="sub__menu">
                <Link to="/vendor/my-wallet">
                    <i className="fa fa-google-wallet"></i>
                    My Wallet
                    </Link>
            </li>
            <li className="sub__menu">
                <Link to="/vendor/my-video">
                    <i className="fa fa-video-camera"></i>
                    My Video
                    </Link>
            </li>
            <li className="sub__menu">
                <Link to="/vendor/change-password">
                    <i className="fa fa-lock"></i>
                    Change Password
                    </Link>
            </li>
            <li className="sub__menu">
                <Link to="/vendor/profile">
                    <i className="fa fa-user-md"></i>
                    Profile</Link>
            </li>
            <li className="sub__menu">
                <Link to="/vendor/signout">
                    <i className="fa fa-sign-out"></i>
                    logout
                    </Link>
            </li>
        </React.Fragment>
    );
}
export default UserNavbar;
