export const stripHtml = (html) => {
    var div = document.createElement("div");
    div.innerHTML = html;
    var text = div.textContent || div.innerText || "";
    return text;
};
export const setnumber = (number) => {
    return parseFloat(number).toFixed(2);
};
export const to_float = (number) => {
    return parseFloat(number).toFixed(2);
};
export const ismember = (islogin) => {
    if (!islogin) {
        return false;
    } else {
        const user_data = JSON.parse(localStorage.getItem("user_data"));
        if (parseInt(user_data["segmentcode"]) == 1000) {
            return false;
        } else {
            return true;
        }
    }
};
export const memberdiscountprice = (orignalPrice) => {
    const user_data = JSON.parse(localStorage.getItem("user_data"));
    let discount = parseFloat(user_data["maxspend"]);
    discount = (parseFloat(orignalPrice) * discount) / 100;
    return parseFloat(orignalPrice) - discount;
};
export const priceinfo = (priceinfo) => {
    let totaloldprice = 0;
    let totalprice = 0;
    let taxpercent = 0;
    let taxtotalprice = 0;
    let taxamount = 0;
    priceinfo.forEach(function (info) {
        taxtotalprice += parseFloat(info["taxtotalprice"]);
        totaloldprice += parseFloat(info["oldtotalprice"]);
        totalprice += parseFloat(info["taxtotalprice"]) - parseFloat(info["taxamount"]);
        taxpercent += parseFloat(info["p_info"]["taxpercent"]);
        taxamount += parseFloat(info["taxamount"]);
    });

    return {
        taxtotalprice: taxtotalprice,
        totaloldprice: totaloldprice,
        discount: totaloldprice - totalprice,
        totalprice: totalprice.toFixed(2),
        totaltaxamount: taxamount.toFixed(2),
        taxpercent: taxpercent,
    };
};
export const shippingCharges = (totaloldprice, discount, shippingwaiver, shippingamount) => {
    let shippingCharge = 0;
    let tempValue = parseFloat(totaloldprice) - parseFloat(discount);
    shippingCharge = parseFloat(shippingamount);
    if (tempValue > parseFloat(shippingwaiver)) {
        shippingCharge = 0;
    }
    return shippingCharge;
};
export const cgst = (totalprice, shippingCharge, coupndiscount, totalpercant) => {
    // let Ccgst =
    //     (parseFloat(totaloldprice) -
    //         parseFloat(discount) -
    //         parseFloat(coupndiscount) +
    //         parseFloat(shippingCharge)) *
    //     totalpercant;
    let cgstminus = totalprice - coupndiscount;
    cgstminus = cgstminus + shippingCharge;
    cgstminus = (cgstminus / 100) * totalpercant;
    return 0;
};
