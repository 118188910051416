import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
const Jumbotron = () => {
    const t = useTranslation();
    return (
        <div className="container container-ver2">
            <div className="content-box service-provider">
                <div className=" service__provider-jumbotron">
                    <div className="container text-center dflex">
                        <h3>{t("home.service_provider")}</h3>
                    </div>
                    <div className="text-center buttons">
                        <Link to="/vendor/login" className="jumbotron__btn link-v1 market-bcolor ml-0">
                            {t("home.login")}
                        </Link>
                        <Link to="/vendor/signup" className="jumbotron__btn link-v1 market-bcolor-outline">
                            {t("home.signup")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Jumbotron;
