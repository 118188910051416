import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import generalApi from "../../services/generalApi";
import { toast } from "react-toastify";
function Setting(props) {
    let userData = JSON.parse(localStorage.getItem("user_data"));
    const [active, serActive] = useState(false);
    const [citylimit, setCityLimit] = useState(userData["nocitylimit"]);
    const [pushoffer, setPushoffer] = useState(userData["acceptoffer"]);
    const oncitychange = (e) => {
        setCityLimit(e.target.checked ? "1" : "0");
        console.log("e.target.checked", e.target.checked);

    };
    const onpushchange = (e) => {
        setPushoffer(e.target.checked ? "1" : "0");
        console.log("e.target.checked", e.target.checked);
    };
    const updatesetting = (e) => {
        e.preventDefault();
        let userData = JSON.parse(localStorage.getItem("user_data"));
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            certificate: userData["certificate"],
            citynolimit: citylimit,
            pushoffers: pushoffer,
        };
        generalApi.getData("SSMVendorSettings", json).then((response) => {
            if (response["data"]["statuscode"] == "000") {
                userData["acceptoffer"] = pushoffer;
                userData["nocitylimit"] = citylimit;
                localStorage.setItem("user_data", JSON.stringify(userData));
                let message = {
                    heading: "Success!",
                    des: `Successfully update the setting`,
                    link: "/vendor/dashboard",
                    linkname: "Dashboard",
                };
                localStorage.setItem("message", JSON.stringify(message));
                props.history.push("/message");
            } else {
                toast.error(response["data"]["statusdesc"]);
            }
        });
    };
    return (
        <div className="container container-ver2">
            <div className="page-login box space-50">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 sign-in space-30">
                        <h3>Update Setting</h3>
                        <form onSubmit={updatesetting} className="form-horizontal">
                            <div className="checkbox">
                                <input
                                    id="citylimit"
                                    onChange={oncitychange}
                                    type="checkbox"
                                    name="citylimit"
                                    checked={citylimit === "1" ? true : false}
                                    value={citylimit}
                                />
                                <label htmlFor="citylimit" className="label-check">
                                    I Will accept orders for the full city limits
                                </label>
                            </div>
                            <div className="checkbox">
                                <input
                                    id="pushoffer"
                                    onChange={onpushchange}
                                    type="checkbox"
                                    name="pushoffer"
                                    checked={pushoffer === "1" ? true : false}
                                    value={pushoffer}
                                />
                                <label htmlFor="pushoffer" className="label-check">
                                    I Will participate in offers and promotion campaigns
                                </label>
                            </div>
                            <div className="mt-1">
                                <button
                                    type="submit"
                                    disabled={active ? true : false}
                                    className="link-v1 rt"
                                >
                                    {active ? "Processing..." : "Update Setting"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(Setting);
