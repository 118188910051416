import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import VendorContext from "./../../../../context/vendorSignupData";
import ImageUploader from 'react-images-upload';
class Document extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            profileimage: '',
            residentimage: '',
            tradelicenseimage: "",
            drivinglicenseimage: "",
            residentno: '',
            tradelicense: '',
            drivinglicense: '',
            errormsg: '',
            isDisabled: true
        }
    }
    static contextType = VendorContext;
    profileimage = (picture, pictureUrl) => {
        if (pictureUrl.length > 0) {
            console.log("PRofile 1", pictureUrl[0]);
            console.log("PRofile 2", pictureUrl[0].split(',')[1]);

            this.setState({ ...this.state, "profileimage": pictureUrl[0].split(',')[1] });
        }
    }
    residentimage = (picture, pictureUrl) => {
        if (pictureUrl.length > 0) {
            this.setState({ ...this.state, "residentimage": pictureUrl[0].split(',')[1] });
        }
    }
    tradelicenseimage = (picture, pictureUrl) => {
        if (pictureUrl.length > 0) {
            this.setState({ ...this.state, "tradelicenseimage": pictureUrl[0].split(',')[1] });
        }
    }
    drivinglicenseimage = (picture, pictureUrl) => {
        if (pictureUrl.length > 0) {
            this.setState({ ...this.state, "drivinglicenseimage": pictureUrl[0].split(',')[1] });
        }
    }
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })

    }
    validateError = () => {
        let doccument = [];
        if (this.state.residentimage !== '') {
            doccument.push(this.state.residentimage)
        }
        if (this.state.tradelicenseimage !== '') {
            doccument.push(this.state.tradelicenseimage)
        }
        if (this.state.drivinglicenseimage !== '') {
            doccument.push(this.state.drivinglicenseimage)
        }
        if (this.validator.allValid()) {
            if (doccument.length < 2) {
                this.setState({ ...this.state, "errormsg": 'Two document compulsory to upload' });
                return
            } else {
                const [VendorData, setVendorData] = this.context
                let info = VendorData;
                info["documents"] = this.state;
                setVendorData({ ...info })
                this.props.nextStep()
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div className="userType">
                <h2>Document Upload </h2>
                <div className="row">
                    <div className="col-lg-4 form-group">
                        <label >Resident ID Card</label>
                        <input name="residentno" onChange={this.handleChange} type="text" value={this.state.residentno} className="form-control" placeholder="Resident ID Card" />
                    </div>
                    <div className="col-lg-4 form-group">
                        <label >Trade License</label>
                        <input name="tradelicense" onChange={this.handleChange} type="text" value={this.state.tradelicense} className="form-control" placeholder="Trade License" />
                    </div>
                    <div className="col-lg-4 form-group">
                        <label >Driving License</label>
                        <input name="drivinglicense" onChange={this.handleChange} type="text" value={this.state.drivinglicense} className="form-control" placeholder="Driving License" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 form-group">
                        <ImageUploader
                            withIcon={true}
                            name="profileimage"
                            withPreview={true}
                            singleImage={true}
                            buttonText='Profile Image'
                            label="Max file size 1 mp and only image upload"
                            onChange={this.profileimage}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={1048576}
                        />
                        <span className="text-danger">{this.validator.message('profileimage', this.state.profileimage, 'required')}</span>
                    </div>
                    <div className="col-lg-3 form-group">
                        <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Resident ID Card'
                            label="Max file size 1 mp and only image upload"
                            onChange={this.residentimage}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={1048576}
                        />
                        <span className="text-danger">{this.state.errormsg}</span>
                    </div>
                    <div className="col-lg-3 form-group">
                        <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Trade License'
                            label="Max file size 1 mp and only image upload"
                            onChange={this.tradelicenseimage}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={1048576}
                        />
                    </div>
                    <div className="col-lg-3 form-group">
                        <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            singleImage={true}
                            buttonText='Driving License'
                            label="Max file size 1 mp and only image upload"
                            onChange={this.drivinglicenseimage}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={1048576}
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <p><button className="btn btn-info " onClick={this.props.previousStep}>Previous Step</button></p>
                    </div>
                    <div className="col-lg-6">
                        <p className="text-right"><button className="btn btn-primary" onClick={() => { this.validateError() }}>Next Step</button></p>
                    </div>
                </div>
            </div >
        )
    }
}

export default Document;