import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import generalApi from '../../../services/generalApi'
import SimpleReactValidator from 'simple-react-validator'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { T, withTranslation } from 'react-multi-lang'
class ForgetOtp extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T
        }
        this.validator = new SimpleReactValidator();
        this.state = { isactive: false, idnumber: '', reset_pin: JSON.parse(localStorage.getItem('vendor_reset_pin')) }

    }

    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    submitForm = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            this.setState({ otp: this.state.otp });
            const s = { ...this.state["reset_pin"] };
            const json = { ...s, idnumber: this.state.idnumber }
            console.log(json);

            generalApi.getData('SSMValidateToken', json).then(response => {
                if (response["data"]["statuscode"] == "000") {
                    toast.success(response["data"]["statusdesc"]);

                    this.props.history.push('/vendor/reset-password')
                } else {
                    this.setState({ isactive: false });
                    toast.error(response["data"]["statusdesc"]);
                }

            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        if (!this.state.reset_pin) {
            return <Redirect to="/vendor/login"></Redirect>
        }
        const { t } = this.props
        return (
            <div className="container container-ver2">
                <ToastContainer autoClose={8000} />
                <div className="page-login box space-50">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 sign-in space-30">
                            <h3>{t('forgot_otp.otp_form')}</h3>
                            <form className="form-horizontal" onSubmit={this.submitForm}>
                                <div className="group box">
                                    <label className="control-label">{t('forgot_otp.otp')}</label>
                                    <input
                                        className="form-control englisg-text"
                                        type="text"
                                        name="idnumber"
                                        onChange={this.handleChange}
                                        onBlur={() => this.validator.showMessageFor('idnumber')}
                                        value={this.state.idnumber}
                                        placeholder={t('forgot_otp.6_digit_sms')}
                                    />
                                    <span className="text-danger">
                                        {this.validator.message(
                                            "pin",
                                            this.state.idnumber,
                                            "required|min:6|max:6"
                                        )}
                                    </span>
                                </div>
                                <div className="mt-1">
                                    <button
                                        type="submit"
                                        disabled={this.state.isactive ? true : false}
                                        className="link-v1 rt"
                                    >
                                        {this.state.isactive ? t('forgot_otp.processing') : t('forgot_otp.validate')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default withRouter(withTranslation(ForgetOtp));