import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import { useTranslation } from 'react-multi-lang'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";
function CurrentLocation(props) {
    const t = useTranslation()
    Geocode.setApiKey("AIzaSyAfn0186e_Uq5kHupvAXXYrG8ER6kcqP8Y");
    const [location, setLocation] = useState(props.location ? props.location : "");
    const [latlan, setLatLan] = useState(props.geolocation);
    const [locationerror, setLocationError] = useState('');
    useEffect(() => {
        getLocation()
    }, [])
    useEffect(() => {
        if (props.geolocation === '') {
            props.currentlocation(
                {
                    'geolocation': '',
                    'location': '',
                }
            )
        }
    }, [locationerror])
    const handleChange = (address) => {
        setLocation(address);
    }
    const handleSelect = address => {
        // setLocationData({ ...locationData, address: address });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                let l = latLng.lat + "," + latLng.lng;
                setLatLan(l)
                setLocation(address)
            })
            .catch(error => console.error("Error", error));
    };
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            setLocationError(t('signup.not_support'))
        }
    }

    useEffect(() => {
        props.currentlocation(
            {
                'geolocation': latlan,
                'location': location,
            }
        )
    }, [location])
    const showPosition = (position) => {
        const L = position.coords.latitude + "," + position.coords.longitude;
        setLatLan(L)
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            response => {
                const address = response.results[0].formatted_address;
                setLocation(address)
            },
            error => {
                console.error(error);
            }
        );
    }
    const showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                setLocationError(t('signup.denied_location'))
                break;
            case error.POSITION_UNAVAILABLE:
                setLocationError(t('signup.info_not_avail'))
                break;
            case error.TIMEOUT:
                setLocationError(t('signup.timeout_location'))
                break;
            case error.UNKNOWN_ERROR:
                setLocationError(t('signip.unknown_error'))
                break;
        }
    }
    return (
        <React.Fragment>
            <label className="control-label" >{t('signup.current_location')}</label>
            <PlacesAutocomplete
                value={location}
                onChange={handleChange}
                name="location"
                onSelect={handleSelect}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                }) => (
                        <React.Fragment>
                            <div className="icon__input">
                                <i className="fa fa-map input__icon"></i>
                                <input
                                    {...getInputProps({
                                        placeholder: t('signup.enter_location'),
                                        name: "location",
                                        autoComplete: 'off',
                                        className: "location-search-input englisg-text form-control"
                                    })}
                                />
                            </div>
                            <div className="autocomplete-dropdown-container englisg-text">
                                {loading && <div>{t('signup.loading')}</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    )}
            </PlacesAutocomplete>
            <span className="text-danger">{locationerror}</span>
        </React.Fragment>
    );
}
export default CurrentLocation;
