import React, { useState, useEffect } from "react";
function OrderBtn(props) {
    let iteminfo = props.iteminfo
    let actualQuantity = iteminfo["quantity"];
    const [deliverQuantity, setdeliverQuantity] = useState(parseInt(iteminfo["deliverqty"]))
    const changeQuantity = (e) => {
        let quantity = e.target.value;
        quantity = quantity === '' || quantity < 0 ? 0 : quantity;
        quantity = quantity > actualQuantity ? actualQuantity : quantity
        props.itemchange({
            itemcode: iteminfo["itemcode"],
            deliverquantity: quantity,
        })
        setdeliverQuantity(quantity);
        console.log("items", quantity);
    }
    useEffect(() => {
        console.log("deliverQuantity", deliverQuantity);
        if (deliverQuantity > 0) {
            // props.itemchange({
            //     itemcode: iteminfo["itemcode"],
            //     deliverquantity: deliverQuantity,
            // })
        }
    }, [deliverQuantity])
    // useEffect(() => {
    //     props.itemchange({
    //         itemcode: iteminfo["itemcode"],
    //         itemname: iteminfo["itemname"],
    //         itemunit: iteminfo["itemunit"],
    //         quantity: iteminfo["quantity"],
    //         deliverquantity: iteminfo["deliverQuantity"],
    //     })
    // }, [])

    const setValue = () => {
        let info = {
            itemcode: iteminfo["itemcode"],
            itemname: iteminfo["itemname"],
            itemunit: iteminfo["itemunit"],
            quantity: iteminfo["quantity"],
            deliverquantity: iteminfo["deliverQuantity"],
        }
        return info
    }
    return (
        <React.Fragment>
            <tr>
                <td>{iteminfo["itemcode"]}</td>
                <td>{iteminfo["itemname"]}</td>
                <td>{iteminfo["itemunit"]}</td>
                <td>{actualQuantity}</td>
                <td>
                    <input
                        type="number"
                        value={deliverQuantity}
                        required
                        disabled={props.neworder === 1 ? false : true}
                        onChange={changeQuantity}
                        className="deliver__qty-input"
                    />
                </td>
            </tr>
        </React.Fragment>
    );
}
export default OrderBtn;
