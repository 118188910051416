import React, { useState, useContext, useEffect } from "react";
import LoadedState from "./dummyImage";
import { Link } from "react-router-dom";
import { CartContext } from "./../../context/cartContext";
import { HomeContext } from "./../../context/homeContext";
import { LoginContext } from "./../../context/loginContext";
import { ismember, memberdiscountprice, setnumber } from "./../../config/helper";
import SetFavourte from "../setFavourte";
import { useTranslation } from "react-multi-lang";
function ProductQuantity(props) {
    const t = useTranslation();
    let qtyList = props.qtylist;
    const maxincrement = 20;
    let productinfo = props.productinfo;

    // const [isSaved, setSaved] = useState(false);
    const [isAuth] = useContext(LoginContext);
    const [cartData, setCartData] = useContext(CartContext);
    const [homeData] = useContext(HomeContext);
    // Product save on Cart
    let currencySymbol = homeData["currencysymbol"];
    // let tempData = cartData.filter((it) => it.itemcode === productinfo["itemcode"]);
    let isCartT = false;
    let oldPriceT = qtyList.length > 0 ? qtyList[0]["dretailprice"] : 0;
    let totalOldPriceT = qtyList.length > 0 ? qtyList[0]["dretailprice"] : 0;
    let priceT = qtyList.length > 0 ? qtyList[0]["dofferprice"] : 0;
    let quantityT = 1;
    let totalPriceT = qtyList.length > 0 ? qtyList[0]["dofferprice"] : 0;
    let quantityTypeT = 0;
    // if (tempData.length > 0) {
    //     isCartT = true;
    //     oldPriceT = tempData[0]["itemoldprice"];
    //     totalOldPriceT = tempData[0]["oldtotalprice"];
    //     priceT = tempData[0]["itemprice"];
    //     quantityT = tempData[0]["itemquantity"];
    //     totalPriceT = tempData[0]["itemtotalPrice"];
    //     quantityTypeT = tempData[0]["quantitytype"];
    // }
    const [isCart, setCart] = useState(isCartT);

    const [oldPrice, setOldPrice] = useState(oldPriceT);
    const [price, setPrice] = useState(priceT);
    const [quantityType, setQuantityType] = useState(quantityTypeT);
    const [totalPrice, setTotalPrice] = useState(totalPriceT);
    const [oldTotalPrice, setOldTotalPrice] = useState(totalOldPriceT);
    const [quantity, setQuantity] = useState(quantityT);
    useEffect(() => {
        let tempData = cartData.filter((it) => it.itemcode === productinfo["itemcode"]);
        if (tempData.length > 0) {
            setCart(true);
            setOldPrice(tempData[0]["itemoldprice"]);
            setOldTotalPrice(tempData[0]["oldtotalprice"]);
            setPrice(tempData[0]["itemprice"]);
            setQuantity(tempData[0]["itemquantity"]);
            setTotalPrice(tempData[0]["itemtotalPrice"]);
            setQuantityType(tempData[0]["quantitytype"]);
        } else {
            setCart(false);
        }
    }, [cartData]);
    // When Change Cart btn
    useEffect(() => {
        if (isCart) {
            let tempData = cartData.filter((it) => it.itemcode === productinfo["itemcode"]);
            console.log("tempData.length", tempData.length);

            if (tempData.length === 0) {
                addToCart();
            }
        } else {
            deleteItem();
        }
    }, [isCart]);
    const setInfo = () => {
        let taxamount = 0;
        let taxtotalprice = 0;
        if (parseInt(qtyList[quantityType]["disoffer"]) !== 0) {
            taxamount = (parseFloat(totalPrice) * parseFloat(productinfo["taxpercent"])) / 100;
            taxtotalprice = totalPrice;
            if (ismember(isAuth["isLogin"])) {
                taxtotalprice = memberdiscountprice(totalPrice);
                taxamount =
                    (parseFloat(memberdiscountprice(totalPrice)) *
                        parseFloat(productinfo["taxpercent"])) /
                    100;
            }
        } else {
            taxamount = (parseFloat(oldTotalPrice) * parseFloat(productinfo["taxpercent"])) / 100;
            taxtotalprice = oldTotalPrice;
            if (ismember(isAuth["isLogin"])) {
                taxtotalprice = memberdiscountprice(oldTotalPrice);
                taxamount =
                    (parseFloat(memberdiscountprice(oldTotalPrice)) *
                        parseFloat(productinfo["taxpercent"])) /
                    100;
            }
        }
        return {
            itemcode: productinfo["itemcode"],
            itemname: productinfo["itemname"],
            itemimage: productinfo["imagelargeurl"],
            itemprice: price,
            itemoldprice: oldPrice,
            oldtotalprice: oldTotalPrice,
            itemquantity: quantity,
            itemtotalPrice: totalPrice,
            quantitytype: quantityType,
            taxamount: taxamount,
            taxtotalprice: parseFloat(taxamount) + parseFloat(taxtotalprice),
            p_info: productinfo,
        };
    };
    const addToCart = () => {
        let tempData = cartData;
        tempData = [...tempData, setInfo()];
        localStorage.setItem("cartData", JSON.stringify(tempData));
        setCartData(tempData);
    };
    const deleteItem = () => {
        let tempData = cartData.filter((it) => it.itemcode !== productinfo["itemcode"]);
        localStorage.setItem("cartData", JSON.stringify(tempData));
        setCartData(tempData);
    };
    const updateCart = () => {
        let tempData = cartData.filter((it) => it.itemcode !== productinfo["itemcode"]);
        tempData = [...tempData, setInfo()];
        localStorage.setItem("cartData", JSON.stringify(tempData));
        setCartData(tempData);
    };
    useEffect(() => {
        if (cartData.length > 0 && isCart) {
            updateCart();
        }
    }, [price]);
    useEffect(() => {
        if (cartData.length > 0 && isCart) {
            updateCart();
        }
    }, [quantity]);
    useEffect(() => {
        if (cartData.length > 0 && isCart) {
            updateCart();
        }
    }, [quantityType])
    const quantityChange = (e) => {
        let index = e.target.value;
        setQuantityType(index);
        setOldPrice(qtyList[index]["dretailprice"]);
        setPrice(qtyList[index]["dofferprice"]);
        setOldTotalPrice(parseFloat(qtyList[index]["dretailprice"]) * quantity);
        setTotalPrice(parseFloat(qtyList[index]["dofferprice"]) * quantity);
    };
    const quantityIncrement = () => {
        let q = quantity + 1;
        q = q > maxincrement ? maxincrement : q
        setTotalPrice(parseFloat(price) * q);
        setOldTotalPrice(parseFloat(oldPrice) * q);
        setQuantity(q);
    };
    const quantityDecrement = () => {
        let q = parseInt(quantity - 1);
        q = q > 0 ? q : 1;
        setTotalPrice(parseFloat(price) * q);
        setOldTotalPrice(parseFloat(oldPrice) * q);
        setQuantity(q);
    };
    return (
        <React.Fragment>
            <div className="product__inner">
                {props.showfav === 0 ? null : (
                    <SetFavourte
                        itemcode={productinfo["itemcode"]}
                        isfav={productinfo["isfav"]}
                    ></SetFavourte>
                )}
                <div className="product-images">
                    <Link to="#" title={productinfo["itemname"]}>
                        <LoadedState
                            key={1}
                            width="240"
                            height="150"
                            classname="primary_image"
                            src={productinfo["imagelargeurl"]}
                        />
                    </Link>
                </div>
                <Link to="#" title={productinfo["itemname"]}>
                    <p className="product-title">
                        {/* {productinfo["itemname"].split(" ").slice(0, 3).join(" ")} */}
                        {productinfo["itemname"]}
                    </p>
                </Link>
                <div className="total__price">
                    {price > 0 ? (
                        <p className="product-price-old">
                            {currencySymbol} {setnumber(oldPrice)}
                        </p>
                    ) : null}
                    <p className="product-price">
                        {currencySymbol} {price > 0 ? setnumber(price) : setnumber(oldPrice)}
                    </p>
                </div>
                {isCart ? (
                    <div className="total__price">
                        <p className="total__price-item">
                            <span className="">{t("order.amount")}</span>{" "}
                            <span>
                                {currencySymbol}{" "}
                                {parseInt(qtyList[quantityType]["disoffer"]) !== 0
                                    ? ismember(isAuth["isLogin"])
                                        ? setnumber(memberdiscountprice(totalPrice))
                                        : setnumber(totalPrice)
                                    : ismember(isAuth["isLogin"])
                                        ? setnumber(memberdiscountprice(oldTotalPrice))
                                        : setnumber(oldTotalPrice)}
                            </span>
                        </p>

                        <p className="total__price-item saving">
                            <span className="">{t("order.saving")}</span>{" "}
                            <span className="price">
                                {currencySymbol}{" "}
                                {price > 0
                                    ? // setnumber(oldTotalPrice - totalPrice) : 0.00
                                    ismember(isAuth["isLogin"])
                                        ? setnumber(oldTotalPrice - memberdiscountprice(totalPrice))
                                        : setnumber(oldTotalPrice - totalPrice)
                                    : ismember(isAuth["isLogin"])
                                        ? setnumber(oldTotalPrice - memberdiscountprice(oldTotalPrice))
                                        : 0.0}
                            </span>
                        </p>
                    </div>
                ) : null}

                <div className="main-quantity">
                    <div className="quantity mb-1">
                        <select
                            name="select_size"
                            onChange={quantityChange}
                            value={quantityType}
                            className="form-control quantity-selector"
                        >
                            {qtyList.map((list, s_i) => (
                                <option value={s_i} key={s_i}>
                                    {list["dunitqty"] + " " + list["dquantitydesc"]}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <button
                    onClick={() => setCart(!isCart)}
                    className={!isCart ? "btn btn-info rt d-block cart__btn" : "d-none"}
                >
                    <span className="fa fa-cart-plus fa-lg"></span>
                </button>
                <div className={isCart ? "d-flex justify-content-center" : "d-none"}>
                    <div className={isCart ? "Changequantity" : "Changequantity"}>
                        <span
                            onClick={quantityDecrement}
                            className="fa fa-minus-circle fa-2x green m-7 cursorPointer"
                        ></span>
                        <input
                            type="text"
                            value={quantity}
                            readOnly
                            className="form-control bg-white"
                        />
                        <span
                            onClick={quantityIncrement}
                            className="fa fa-plus-circle fa-2x green m-7 cursorPointer"
                        ></span>
                        <span
                            className="fa fa-trash fa-2x market-color m-7 cursorPointer"
                            onClick={() => setCart(!isCart)}
                        ></span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default ProductQuantity;
