import React, { Component } from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import generalApi from '../../services/generalApi'
import SimpleReactValidator from 'simple-react-validator'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { T, withTranslation } from 'react-multi-lang'
class ResetPin extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T
        }
        const { t } = this.props
        this.validator = new SimpleReactValidator({
            messages: {
                required: t('validation.required'),
                phone: t('validation.phone'),
                min: t('validation.pinmin'),
                max: t('validation.pinmax'),
                email: t('validation.email')
            }
        });
        this.state = { isactive: false, idnumber: '', resetData: JSON.parse(localStorage.getItem('reset_pin')) }
    }
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    submitForm = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            const s = { ...this.state["resetData"] }
            const json = { ...s, idnumber: this.state.idnumber }
            // console.log(json);

            generalApi.getData('SSMsavePIN', json).then(response => {
                if (response["data"]["statuscode"] == "000") {
                    localStorage.removeItem('reset_pin');
                    this.props.history.push('/login')
                } else {
                    this.setState({ isactive: false });
                    toast.error(response["data"]["statusdesc"]);
                }

            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        if (!this.state.resetData) {
            return <Redirect to="/"></Redirect>
        }
        const { t } = this.props
        return (
            <div className="container container-ver2">
                <div className="page-login box space-50">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 sign-in space-30">
                            <h3>{t('reset_pin.reset_form')}</h3>
                            <form className="form-horizontal" onSubmit={this.submitForm}>
                                <div className="group box">
                                    <label className="control-label">{t('reset_pin.new_pin')}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-lock input__icon"></i>
                                        <input
                                            className="form-control englisg-text"
                                            type="password"
                                            name="idnumber"
                                            onChange={this.handleChange}
                                            onBlur={() => this.validator.showMessageFor('idnumber')}
                                            value={this.state.idnumber}
                                            placeholder={t('reset_pin.enter_pin')}
                                        />
                                    </div>
                                    <span className="text-danger">
                                        {this.validator.message(
                                            "pin",
                                            this.state.idnumber,
                                            "required|min:4|max:4"
                                        )}
                                    </span>
                                </div>
                                <div className="mt-1">
                                    <button
                                        type="submit"
                                        disabled={this.state.isactive ? true : false}
                                        className="link-v1 rt market-bcolor"
                                    >
                                        {this.state.isactive ? t('reset_pin.processing') : t('reset_pin.reset')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default withRouter(withTranslation(ResetPin));