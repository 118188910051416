import React, { useContext } from "react";
import { CartContext } from "./../../context/cartContext";
import { Link } from "react-router-dom";
import { LoginContext } from './../../context/loginContext'
import { ismember, memberdiscountprice, setnumber } from './../../config/helper'
import { useTranslation } from 'react-multi-lang'
function SidebarCheckout(props) {
    const t = useTranslation()
    let cartinfo = props.cartinfo;
    const [isAuth] = useContext(LoginContext)
    const [cartData, setCartData] = useContext(CartContext);
    const quantityType = cartinfo["quantitytype"];
    let isoffer = parseInt(cartinfo["p_info"]["qtylist"][quantityType]["disoffer"])
    const deleteItem = () => {
        let tempData = cartData.filter(it => it.itemcode !== cartinfo["itemcode"]);
        localStorage.setItem("cartData", JSON.stringify(tempData));
        setCartData(tempData);
    };
    return (
        <li>
            <Link to="#" title="" className="cart-product-image">
                <img src={cartinfo["itemimage"]} alt={cartinfo["itemname"]} />
            </Link>
            <div className="text">
                <p className="product-name">
                    {cartinfo["itemname"]
                        .split(" ")
                        .slice(0, 3)
                        .join(" ")}
                </p>
                <p className="product-price">
                    <span className="price-old">
                        {isoffer !== 0 || ismember(isAuth["isLogin"]) ?
                            props.currencySymbol + " " + setnumber(cartinfo["itemoldprice"])
                            : null
                        }
                    </span>
                    <span className="price">
                        {props.currencySymbol}&nbsp;
                        {isoffer !== 0 ?
                            ismember(isAuth["isLogin"]) ? setnumber(memberdiscountprice(cartinfo["itemprice"])) : setnumber(cartinfo["itemprice"])
                            : ismember(isAuth["isLogin"]) ? setnumber(memberdiscountprice(cartinfo["itemoldprice"])) : setnumber(cartinfo["itemoldprice"])}
                    </span>
                </p>
                <p className="qty">
                    <span className="qtyspan" >{t('shoppingcart.QTY')}</span>
                    &nbsp;&nbsp;&nbsp;
                    <span>{cartinfo["itemquantity"]}</span>{" "}
                </p>
            </div>
            <Link to="#" className="close" title="close" onClick={deleteItem}>
                <i className="fa fa-times-circle "></i>
            </Link>
        </li>
    );
}
export default SidebarCheckout;
