import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import generalApi from '../../../services/generalApi'
import ItemDetailsModal from "./itemDetailsModel";
import ChangeDelivery from "./changeDelivery";
import { toast } from 'react-toastify';
import Loader from "./../../shared/fileLoader";
import Modal from "../../shared/modal";
function OrderBtn(props) {
    let orderinfo = props.orderinfo
    let vichlelist = props.vichlelist
    const [loading, setLoading] = useState(false);
    const [isopen, setopen] = useState(false)
    const [isCopen, setCopen] = useState(false)
    const [checkinModel, setCheckinModel] = useState(false)
    const [rejectModel, setRejectModel] = useState(false)
    const [cancelModel, setCancelModel] = useState(false)
    const toggleModal = () => {
        setopen(!isopen)
    }
    const changedelivery = () => {
        setCopen(!isCopen)
    }
    const rejectOrder = () => {
        setLoading(true);
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            certificate: userData["certificate"],
            orderid: orderinfo["orderid"],
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            fbtoken: "000"
        }
        generalApi.getData('SSMDeclineServiceOrder', json).then(response => {
            if (response["data"]["statuscode"] == "000") {
                let message = { heading: "Success!", des: `Order # ${orderinfo["orderid"]} successfully Cancelled`, link: "/vendor/new-order", linkname: "New Order" }
                localStorage.setItem('message', JSON.stringify(message))
                props.history.push('/message')
            } else {
                setLoading(false);
                toast.error(response["data"]["statusdesc"])
            }
        });
    }
    const cancalOrder = () => {
        setLoading(true);
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            customer: orderinfo["customercode"],
            certificate: userData["certificate"],
            orderid: orderinfo["orderid"],
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            fbtoken: "000"
        }
        generalApi.getData('SSMCancelServiceOrderPartner', json).then(response => {
            if (response["data"]["statuscode"] == "000") {
                let message = { heading: "Success!", des: `Order # ${orderinfo["orderid"]} successfully Cancelled`, link: "/vendor/new-order", linkname: "New Order" }
                localStorage.setItem('message', JSON.stringify(message))
                props.history.push('/message')
            } else {
                setLoading(false);
                toast.error(response["data"]["statusdesc"])
            }
        });
    }
    const checkinorder = () => {
        setLoading(true);
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            certificate: userData["certificate"],
            orderid: orderinfo["orderid"],
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            fbtoken: "000"
        }
        generalApi.getData('SSMLoadOrder', json).then(response => {
            if (response["data"]["statuscode"] == "000") {
                let message = { heading: "Success!", des: `Order # ${orderinfo["orderid"]} successfully Loaded`, link: "/vendor/transit-order", linkname: "Transit Order" }
                localStorage.setItem('message', JSON.stringify(message))
                props.history.push('/message')
            } else {
                setLoading(false);
                toast.error(response["data"]["statusdesc"])
            }
        });
    }


    return (
        <React.Fragment>
            {loading ? <Loader></Loader> : null}
            <div className="order__action-btn">
                <button onClick={toggleModal} className="btn mt-1 btn-order__custom btn-sm market-bcolor">
                    Show Detail
                   </button>
                {props.neworder === 1 ?
                    <React.Fragment>
                        <button onClick={() => setRejectModel(!rejectModel)} className="btn mt-1 btn-order__custom btn-sm btn-info market-bcolor-outline">
                            Reject Order
                        </button>
                        <button onClick={() => setCancelModel(!cancelModel)} className="btn mt-1 btn-order__custom btn-sm btn-danger">
                            Cancel Order
                        </button>
                    </React.Fragment>
                    : null}
                {props.checkin === 1 ?
                    <button onClick={() => setCheckinModel(!checkinModel)} className="btn mt-1 btn-order__custom btn-sm btn-warning raashan-bcolor">
                        Check In
                </button>
                    : null}
                {props.showaddress === 1 ?
                    <a className="btn mt-1 btn-order__custom btn-sm btn-success"
                        href={`https://www.google.com/maps/dir/?api=1&origin=&destination=25.2048493,55.2707828&destination_place_id=${orderinfo["geolocation"]}`} target="_blank">
                        Show Address
                    </a>
                    : null}
                {props.changeorder === 1 ?
                    <button onClick={changedelivery} className="btn mt-1 btn-order__custom btn-sm btn-warning raashan-bcolor">
                        Change Delivery
                </button>
                    : null}
            </div>
            <ChangeDelivery vichlelist={vichlelist} orderinfo={orderinfo} closeModal={changedelivery} isOpen={isCopen} />
            <ItemDetailsModal transitorder={props.transitorder} neworder={props.neworder} vichlelist={vichlelist} orderinfo={orderinfo} closeModal={toggleModal} isOpen={isopen} />
            {/* Checin Model*/}
            <Modal classes="rm__p" isOpen={checkinModel}>
                <div className="modal-header">
                    <button type="button" onClick={() => setCheckinModel(!checkinModel)} class="close" data-dismiss="modal">
                        ×
                        </button>
                    <div className="modal-title">Are you sure to Checkin this order!</div>
                </div>
                <div className="modal-body"></div>
                <div className="modal-footer modal-footer-sm">
                    <button className="btn btn-success market-bcolor" onClick={() => setCheckinModel(!checkinModel)}>Cancel</button>
                    <button className="btn btn-info raashan-bcolor" onClick={checkinorder}>Ok</button>
                </div>
            </Modal>
            {/* Reject Order */}
            <Modal classes="rm__p" isOpen={rejectModel}>
                <div className="modal-header">
                    <button type="button" onClick={() => setRejectModel(!rejectModel)} class="close" data-dismiss="modal">
                        ×
                        </button>
                    <div className="modal-title">Are you sure to reject this order!</div>
                </div>
                <div className="modal-body"></div>
                <div className="modal-footer modal-footer-sm">
                    <button className="btn btn-success market-bcolor" onClick={() => setRejectModel(!rejectModel)}>Cancel</button>
                    <button className="btn btn-info raashan-bcolor" onClick={rejectOrder}>Ok</button>
                </div>
            </Modal>
            {/* Cancel Order */}
            <Modal classes="rm__p" isOpen={cancelModel}>
                <div className="modal-header">
                    <button type="button" onClick={() => setCancelModel(!cancelModel)} class="close" data-dismiss="modal">
                        ×
                        </button>
                    <div className="modal-title">Are you sure to cancel this order!</div>
                </div>
                <div className="modal-body"></div>
                <div className="modal-footer modal-footer-sm">
                    <button className="btn btn-success market-bcolor" onClick={() => setCancelModel(!cancelModel)}>Cancel</button>
                    <button className="btn btn-info raashan-bcolor" onClick={cancalOrder}>Ok</button>
                </div>
            </Modal>
        </React.Fragment>
    );
}
export default withRouter(OrderBtn);
