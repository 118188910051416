import React, { useContext, useState } from "react";
import "./../../assets/css/checkboxStyle.css";
import CheckoutPriceFooter from "./checkoutPriceFooter";
import { HomeContext } from "./../../context/homeContext";
import { CartContext } from "./../../context/cartContext";
import { LoginContext } from "./../../context/loginContext";
import { ismember, memberdiscountprice, setnumber } from "./../../config/helper";
import { useTranslation } from "react-multi-lang";
function Checkout() {
    const t = useTranslation();
    const [isAuth] = useContext(LoginContext);
    const [homeData] = useContext(HomeContext);
    const [cartData] = useContext(CartContext);
    let currencySymbol = homeData["currencysymbol"];

    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th className="text-center text-uppercase">
                                {t("checkout.product_name")}
                            </th>
                            <th className="text-center text-uppercase">
                                {t("checkout.standred_price")}
                            </th>
                            <th className="text-center text-uppercase">
                                {ismember(isAuth["isLogin"])
                                    ? t("checkout.member_price")
                                    : t("checkout.offer_price")}
                            </th>
                            <th className="text-center text-uppercase">{t("checkout.qty")}</th>
                            <th className="text-center text-uppercase">{t("checkout.discount")}</th>
                            <th className="text-center text-uppercase">
                                {t("checkout.total_before_tax")}
                            </th>
                            <th className="text-center text-uppercase">{t("checkout.tax")}</th>
                            <th className="text-center text-uppercase">
                                {t("checkout.tax_amount")}
                            </th>
                            <th className="text-center text-uppercase">
                                {t("checkout.item_total")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartData.map((info) => (
                            <tr key={info["itemcode"]}>
                                <td>{info["itemname"].split(" ").slice(0, 3).join(" ")}</td>
                                <td className="text-right">
                                    {currencySymbol} {setnumber(info["itemoldprice"])}
                                </td>
                                <td className="text-right">
                                    {currencySymbol}{" "}
                                    {parseInt(
                                        info["p_info"]["qtylist"][info["quantitytype"]]["disoffer"]
                                    ) !== 0
                                        ? ismember(isAuth["isLogin"])
                                            ? setnumber(memberdiscountprice(info["itemprice"]))
                                            : setnumber(info["itemprice"])
                                        : ismember(isAuth["isLogin"])
                                        ? setnumber(memberdiscountprice(info["itemoldprice"]))
                                        : setnumber(info["itemoldprice"])}
                                </td>
                                <td className="text-center">{info["itemquantity"]}</td>
                                <td className="text-right">
                                    {currencySymbol}{" "}
                                    {parseInt(
                                        info["p_info"]["qtylist"][info["quantitytype"]]["disoffer"]
                                    ) !== 0
                                        ? ismember(isAuth["isLogin"])
                                            ? setnumber(
                                                  parseFloat(info["oldtotalprice"]) -
                                                      memberdiscountprice(
                                                          parseFloat(info["itemtotalPrice"])
                                                      )
                                              )
                                            : setnumber(
                                                  parseFloat(info["oldtotalprice"]) -
                                                      parseFloat(info["itemtotalPrice"])
                                              )
                                        : ismember(isAuth["isLogin"])
                                        ? setnumber(
                                              parseFloat(info["oldtotalprice"]) -
                                                  memberdiscountprice(
                                                      parseFloat(info["oldtotalprice"])
                                                  )
                                          )
                                        : 0}
                                </td>
                                <td className="text-right">
                                    {currencySymbol}{" "}
                                    {parseInt(
                                        info["p_info"]["qtylist"][info["quantitytype"]]["disoffer"]
                                    ) !== 0
                                        ? ismember(isAuth["isLogin"])
                                            ? setnumber(
                                                  memberdiscountprice(
                                                      parseFloat(info["itemtotalPrice"])
                                                  )
                                              )
                                            : setnumber(info["itemtotalPrice"])
                                        : ismember(isAuth["isLogin"])
                                        ? setnumber(
                                              memberdiscountprice(parseFloat(info["oldtotalprice"]))
                                          )
                                        : setnumber(info["oldtotalprice"])}
                                </td>
                                <td className="text-center">{info["p_info"]["taxpercent"]}</td>
                                <td className="text-right">
                                    {currencySymbol} {setnumber(info["taxamount"])}
                                </td>
                                <td className="text-right">
                                    {currencySymbol} {setnumber(info["taxtotalprice"])}
                                </td>
                            </tr>
                        ))}
                        <CheckoutPriceFooter currencySymbol={currencySymbol}></CheckoutPriceFooter>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}
export default Checkout;
