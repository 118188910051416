import React, { useContext } from 'react';
import { withRouter } from "react-router-dom";
import VendorContext from "./../../../context/vendorSignupData";
import 'react-toastify/dist/ReactToastify.css';
import StepWizard from 'react-step-wizard';
import UserType from './signupStep/userType'
import { HomeContext } from './../../../context/homeContext'
import Address from './signupStep/address'
import BankInfo from './signupStep/bankInfo'
import Document from './signupStep/document'
import OtpValidation from './signupStep/otpValidation'
import ProfessionalInfo from './signupStep/ProfessionalInfo'
import PromoterInfo from './signupStep/promoterForm'
function Signup() {
    const [vendorData] = useContext(VendorContext);
    const [homeData] = useContext(HomeContext);
    return (
        <div className="container container-ver2">
            <div className="row">
                <div className="col page-content align-self-center">
                    <div className="inner-box category-content">
                        <h2 className="title-2"><i className="icon-user-add"></i> Create your account, Its free </h2>
                        <StepWizard>

                            <UserType hashKey={'usertype'} />
                            {vendorData["userType"] == "professional" ?
                                <ProfessionalInfo mobileprefix={homeData["mobileprefix"]} hashKey="professionalinfo" />
                                : <PromoterInfo mobileprefix={homeData["mobileprefix"]} hashKey="promotorinfo" />}
                            <Address hashKey={'address'} />
                            {vendorData["userType"] == "professional" ?
                                <Document hashKey="document" />
                                : null}
                            <BankInfo hashKey={'bankinfo'} />
                            <OtpValidation hashKey={'otpvalidation'} />
                        </StepWizard>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default withRouter(Signup);