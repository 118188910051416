import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import VendorContext from "./../../../../context/vendorSignupData";
import { Link } from "react-router-dom";
import generalApi from './../../../../services/generalApi'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class BankInfo extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            vatnumber: '000',
            iban: '',
            bankname: '',
            accepted: "",
            isDisabled: true,
            isactive: false
        }
    }
    static contextType = VendorContext;
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    sendToken = () => {
        const [VendorData, setVendorData] = this.context;
        console.log("userinfojuju", VendorData);
        this.setState({ isactive: true });
        const json = {
            mobile: VendorData["basicInnfo"]['mobileprefix'] + VendorData["basicInnfo"]['contactno'],
            email: VendorData["basicInnfo"]['email'],
            employeeaccount: 1
        }
        console.log("json", json);

        generalApi.getData('SSMSendEnrolmentToken', json).then(response => {
            console.log('Response', response["data"]);
            if (response["data"]["statuscode"] == "000") {
                const [VendorData, setVendorData] = this.context
                let info = VendorData;
                info["referencenumber"] = response["data"]["referencenumber"];
                setVendorData({ ...info })
                this.props.nextStep()
            } else {
                this.setState({ isactive: false });
                toast.error(response["data"]["statusdesc"]);
            }
        });
    }
    validateError = () => {
        if (this.validator.allValid()) {
            this.sendToken()
            const [VendorData, setVendorData] = this.context
            let info = VendorData;
            info["bankInfo"] = this.state;
            setVendorData({ ...info })
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const userType = this.context[0]["userType"];
        return (
            <div className="userType">
                <h2>Bank Info </h2>
                <div className="row">
                    {userType == "professional" ?
                        <div className="col-lg-4 form-group">
                            <label >Vat Number</label>
                            <input name="vatnumber" type="text" value={this.state.vatnumber} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('vatnumber')} className="form-control" placeholder="Vat No" />
                            {/* <span className="text-danger">{this.validator.message('vatnumber', this.state.vatnumber, '')}</span> */}
                        </div>
                        : null}
                    <div className={`form-group ${userType == "professional" ? "col-lg-4" : "col-lg-6"}`}>
                        <label >Bank Account IBAN</label>
                        <input name="iban" type="text" value={this.state.iban} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('iban')} className="form-control" placeholder="Bank Account IBAN no" />
                        <span className="text-danger">{this.validator.message('iban', this.state.iban, 'required')}</span>
                    </div>
                    <div className={`form-group ${userType == "professional" ? "col-lg-4" : "col-lg-6"}`}>
                        <label >Bank Name</label>
                        <input name="bankname" type="text" value={this.state.bankname} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('bankname')} className="form-control" placeholder="Bank Name" />
                        <span className="text-danger">{this.validator.message('bankname', this.state.bankname, 'required')}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="termbox mb10 ml-3">
                        <label className="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                            <input type="checkbox" name="accepted" className="form-check-input" value="c" checked={this.state.accepted == "c"} onChange={this.handleChange} />
                            <span className="custom-control-indicator"></span>
                            <span className="custom-control-description"> I have read and agree to the
                                                                    <Link to="/vendor-tc" target="_blank">Terms& Conditions</Link> </span>
                            <span className="text-danger">{this.validator.message('accepted', this.state.accepted, 'required')}</span>
                        </label>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <p><button className="btn btn-info " onClick={this.props.previousStep}>Previous Step</button></p>
                    </div>
                    <div className="col-lg-6">
                        <p className="text-right"><button className="btn btn-primary" onClick={() => { this.validateError() }} disabled={this.state.isactive ? true : false}>
                            {this.state.isactive ? "Processing..." : "Complete"}
                        </button></p>
                    </div>
                </div>
            </div >
        )
    }
}

export default BankInfo;