import React, { useState, useEffect } from "react";
import generalApi from '../../../services/generalApi'
import Loader from "./../../../component/shared/loader";
import { Link } from "react-router-dom";
function Employee() {
    const [loading, setLoading] = useState(true);
    const [employeeList, setEmployee] = useState([])
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            certificate: userData["certificate"]
        }
        generalApi.getData('SSMEmployeeList', json).then(response => {
            console.log("Order data Complains", response["data"]);
            setEmployee(response["data"])
            setLoading(false);
        });
    }, []);
    if (loading) {
        return <Loader></Loader>
    }
    return (
        <div className="container container-ver2">
            <div className="row">
                <div className="col-md-12 page-content">
                    <div className="inner-box">
                        <div className="d-flex justify-content-between">
                            <h2 className="title-2 b-0 text-center"> Employee List </h2>
                            <div className="text-right">
                                <Link to="/vendor/add-employee" className="btn employee-btn btn-info m-1">Add Employee</Link>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered add-manage-table table demo">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Employee Name</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeList["employeelist"].map((info, i) => (
                                        <tr key={info["employeecode"]}>
                                            <td>{i + 1}</td>
                                            <td>{info["employeename"]}</td>
                                            <td>
                                                <label className="switch">
                                                    <input type="checkbox" disabled defaultChecked={info["checkinstatus"] == 1 ? true : false} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <Link to={`/vendor/update-employee/${info["employeecode"]}`} className="btn employee-btn btn-success">Update</Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
export default Employee