import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import generalApi from '../../../services/generalApi'
import 'react-toastify/dist/ReactToastify.css';
import { LoginContext } from "./../../../context/loginContext";
import Loader from "./../../../component/shared/loader";
function Signout(props) {
    const [loading, setLoading] = useState(true);
    const [isAuth, setAuth] = useContext(LoginContext);
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user_data'))
        const json = {
            employeeid: userData["employeeid"],
            certificate: userData["certificate"],
            outlet: userData["outletid"]
        }
        generalApi.getData('SSMPartnerLogout', json).then(response => {
            if (response["data"]["statuscode"] == "000") {
                localStorage.clear();
                const loginInfo = isAuth;
                loginInfo["isLogin"] = false;
                loginInfo["isVendor"] = false;
                setAuth({ ...loginInfo });
                setLoading(false);
                toast.success(response["data"]["statusdesc"]);
            } else {
                toast.error(response["data"]["statusdesc"]);
            }

        });
    }, [])
    if (loading) {
        return <Loader></Loader>
    }
    return <Redirect to="/"></Redirect>
}
export default Signout;