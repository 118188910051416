import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import generalApi from '../../../../services/generalApi'
import SimpleReactValidator from 'simple-react-validator'
import { toast } from 'react-toastify';
import VendorContext from "./../../../../context/vendorSignupData";
import 'react-toastify/dist/ReactToastify.css';
class OtpValidation extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = { otp: '', isactive: false }
    }
    static contextType = VendorContext;
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }

    professionalRegister = () => {
        this.setState({ isactive: true });
        const [VendorData, setVendorData] = this.context
        const documentList = [
            { imagetype: 'A', image: VendorData["documents"]["residentimage"], idnumber: VendorData["documents"]["residentno"] },
            { imagetype: 'T', image: VendorData["documents"]["tradelicenseimage"], idnumber: VendorData["documents"]["tradelicense"] },
            { imagetype: 'D', image: VendorData["documents"]["drivinglicenseimage"], idnumber: VendorData["documents"]["drivinglicense"] }
        ]
        const json = {
            fullname: VendorData["basicInnfo"]["fullname"],
            mobile: VendorData["basicInnfo"]["contactno"],
            mobile2: VendorData["basicInnfo"]["contactno1"],
            email: VendorData["basicInnfo"]["email"],
            address: VendorData["location"]["geolocationaddress"],
            gender: VendorData["basicInnfo"]["gender"],
            geolocation: VendorData["location"]["geolocation"]["lat"] + ',' + VendorData["location"]["geolocation"]["lng"],
            bankaccount: VendorData["bankInfo"]["iban"],
            ifsccode: "ABHY0065001",
            companytype: VendorData["basicInnfo"]["companytype"],
            serviceList: VendorData["basicInnfo"]["services"],
            reference: VendorData["referencenumber"],
            idnumber: "",
            fromdate: VendorData["basicInnfo"]["yearformation"],
            sgst: VendorData["basicInnfo"]["teamsize"],
            otp: this.state.otp,
            qualification: VendorData["basicInnfo"]["qualfication"],
            profilepicture: VendorData["documents"]["profileimage"],
            gstnumber: "",
            contact: VendorData["basicInnfo"]["contactname"],
            radius: VendorData["location"]["operatingradius"],
            documentList: documentList
        }
        generalApi.getData('SSMPartnerRegister', json).then(response => {
            console.log('Response', response["data"]);
            if (response["data"]["statuscode"] == "000") {
                toast.success(response["data"]["statusdesc"]);
                let msg = "Welcome to KhidmaNow and thank you for registering with us. We are glad to have you as our Service Provider. Our representative will contact you shortly for KYC confirmation process and your account will be activated once the KYC process is completed. "
                localStorage.setItem('message', msg);
                this.props.history.push('/approvel-msg')
            } else {
                this.setState({ isactive: false });
                toast.error(response["data"]["statusdesc"]);
            }
        });

    }

    promoterRegister = () => {
        this.setState({ isactive: true });
        const [VendorData, setVendorData] = this.context
        const json = {
            fullname: VendorData["basicInnfo"]["fullname"],
            mobile: VendorData["basicInnfo"]["contactno"],
            contact: VendorData["basicInnfo"]["contactno1"],
            email: VendorData["basicInnfo"]["email"],
            address: VendorData["location"]["geolocationaddress"],
            gender: VendorData["basicInnfo"]["gender"],
            geolocation: VendorData["location"]["geolocation"]["lat"] + ',' + VendorData["location"]["geolocation"]["lng"],
            bankaccount: VendorData["bankInfo"]["iban"],
            ifsccode: "ABHY0065001",
            reference: VendorData["referencenumber"],
            otp: this.state.otp,
        }
        generalApi.getData('SSMPromoterRegister', json).then(response => {
            console.log('Response', response["data"]);
            if (response["data"]["statuscode"] == "000") {
                toast.success(response["data"]["statusdesc"]);
                let msg = {
                    heading: 'Confratlion',
                    des: 'Welcome to KhidmaNow and thank you for registering with us. We are glad to have you as our Service Provider. Our representative will contact you shortly for KYC confirmation process and your account will be activated once the KYC process is completed.',
                    link: '/',
                    linkname: 'Home',
                };
                localStorage.setItem('message', JSON.stringify(msg));
                this.props.history.push('/message')
            } else {
                this.setState({ isactive: false });
                toast.error(response["data"]["statusdesc"]);
            }
        });
    }
    submitForm = () => {
        if (this.validator.allValid()) {
            const userType = this.context[0]["userType"];
            if (userType == "professional") {
                this.professionalRegister()
            } else {
                this.promoterRegister()
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        return (
            <div className="main-container" >
                <div className="container">
                    <div className="row">
                        <div className="col form-group">
                            <label >Please enter 6 digit SMS code send to your mobile/email:</label>
                            <input placeholder="6 Digit SMS Code" name="otp" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('otp')} value={this.state.otp} className="form-control" type="text" />
                            <span className="text-danger">{this.validator.message('otp', this.state.otp, 'required|min:6|max:6')}</span>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-6">
                            <p><button className="btn btn-info " onClick={this.props.previousStep}>Previous Step</button></p>
                        </div>
                        <div className="col-lg-6">
                            <p className="text-right"><button className="btn btn-primary" onClick={() => { this.submitForm() }} disabled={this.state.isactive ? true : false}>
                                {this.state.isactive ? "Processing..." : "Validate"}
                            </button></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default withRouter(OtpValidation);