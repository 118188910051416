import React, { Component, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import generalApi from "../../services/generalApi";
import { LoginContext } from "./../../context/loginContext";
import SimpleReactValidator from "simple-react-validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { T, withTranslation } from "react-multi-lang";
import { HomeContext } from "./../../context/homeContext";
// import { getToken } from "./../../fcm/generateFcm.js";
class Login extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T,
        };
        // tr
        localStorage.removeItem("reset_pin");
        const { t } = this.props;
        this.validator = new SimpleReactValidator({
            messages: {
                required: t("validation.required"),
                phone: t("validation.phone"),
                min: t("validation.pinmin"),
                max: t("validation.pinmax"),
                email: t("validation.email"),
            },
        });

        this.state = {
            pin: "",
            mobile: "",
            mobileprefix: "+971",
            isactive: false,
            isactiveforget: false,
        };
        // getToken()
    }
    handleChange = (e) => {
        if (e.target.name === "mobile") {
            let mobileprefix = this.state.mobileprefix;
            let mobile = e.target.value.slice(mobileprefix.length);
            if (mobile.length > 9) {
                mobile = mobile.substring(0, 9);
            }
            this.setState({ ...this.state, [e.target.name]: mobile });
        } else {
            this.setState({ ...this.state, [e.target.name]: e.target.value });
        }
    };

    registerfcm = (response) => {
        const json = {
            customer: response["customerid"],
            certificate: response["certificate"],
            reference: localStorage.getItem("fcm-token"),
        };
        generalApi.getData("SSMUpdateFCMKey", json).then((response) => {
            console.log("Token", response);
        });
    };
    submitForm = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            const json = {
                customer: this.state.mobileprefix + this.state.mobile,
                password: this.state.pin,
            };
            generalApi.getData("SSMvalidateUser", json).then((response) => {
                if (response["data"]["statuscode"] === "000") {
                    localStorage.setItem("user_data", JSON.stringify(response["data"]));
                    const [isAuth, setAuth] = this.context;
                    const loginInfo = isAuth;
                    loginInfo["isLogin"] = true;
                    loginInfo["isVendor"] = false;
                    setAuth({ ...loginInfo });
                    // this.registerfcm(response["data"]);
                    let previoushistory = localStorage.getItem("historypath");
                    previoushistory = previoushistory === "/shopping-cart" ? previoushistory : "/";
                    this.props.history.push(previoushistory);
                } else {
                    this.setState({ isactive: false });
                    toast.error(response["data"]["statusdesc"]);
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };
    forgetPassword = () => {
        if (this.validator.fieldValid("mobile")) {
            this.setState({ isactiveforget: true });
            const json = { mobile: this.state.mobileprefix + this.state.mobile };
            generalApi.getData("SSMSendEnrolmentToken", json).then((response) => {
                if (response["data"]["statuscode"] == "000") {
                    const json = {
                        customer: this.state.mobileprefix + this.state.mobile,
                        reference: response["data"]["referencenumber"],
                    };
                    localStorage.setItem("reset_pin", JSON.stringify(json));
                    this.props.history.push("forgot-otp");
                } else {
                    this.setState({ isactiveforget: false });
                    toast.error(response["data"]["statusdesc"]);
                }
            });
        } else {
            this.validator.showMessageFor("mobile");
            this.forceUpdate();
        }
    };
    static contextType = LoginContext;
    render() {
        const { t } = this.props;
        return (
            <div className="container container-ver2">
                <ToastContainer autoClose={8000} />
                <div className="page-login box space-50">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 sign-in space-30">
                            <h3>{t("login.signin")}</h3>
                            <div className="social space-30 box">
                                <a className="full-link" href="#" title="facebook">
                                    <i className="fa fa-facebook"></i>
                                    {t("login.sign_facebook")}
                                </a>
                                {/* <a className="float-right" href="#" title="TWITTER">
                                    <i className="fa fa-twitter"></i>
                                    {t('login.sign_google')}
                                </a> */}
                            </div>
                            <form className="form-horizontal" onSubmit={this.submitForm}>
                                <div className="group box space-20">
                                    <label className="control-label">{t("login.mobile")}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-mobile input__icon"></i>
                                        <input
                                            className="form-control englisg-text"
                                            type="text"
                                            name="mobile"
                                            onChange={this.handleChange}
                                            onBlur={() => this.validator.showMessageFor("mobile")}
                                            value={this.state.mobileprefix + this.state.mobile}
                                            placeholder={t("login.login_primary_mobile")}
                                        />
                                    </div>
                                    <span className="text-danger">
                                        {this.validator.message(
                                            "mobile",
                                            this.state.mobile,
                                            "required"
                                        )}
                                    </span>
                                </div>
                                <div className="group box">
                                    <label className="control-label">{t("login.pin")}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-lock input__icon"></i>
                                        <input
                                            className="form-control englisg-text"
                                            type="password"
                                            name="pin"
                                            onChange={this.handleChange}
                                            onBlur={() => this.validator.showMessageFor("pin")}
                                            value={this.state.pin}
                                            placeholder={t("login.pin")}
                                        />
                                    </div>

                                    <span className="text-danger">
                                        {this.validator.message(
                                            "pin",
                                            this.state.pin,
                                            "required|min:4|max:4"
                                        )}
                                    </span>
                                </div>
                                <div className="mt-1">
                                    <button
                                        type="submit"
                                        disabled={this.state.isactive ? true : false}
                                        className="link-v1 rt full market-bcolor"
                                    >
                                        {this.state.isactive
                                            ? t("login.processing")
                                            : t("login.login")}
                                    </button>
                                </div>
                                <div className="mt-1 text-center">
                                    <Link
                                        className="forgotten__text"
                                        onClick={this.forgetPassword}
                                        to="#"
                                    >
                                        {this.state.isactiveforget
                                            ? t("login.processing")
                                            : t("login.forget")}
                                    </Link>
                                </div>
                                <div className="mt-1">
                                    <Link
                                        to="/signup"
                                        className="btn btn-info rt d-block market-bcolor-outline cart__btn lh-27"
                                    >
                                        {t("signup.create_account")}
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(withTranslation(Login));
