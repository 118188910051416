import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import { useTranslation } from 'react-multi-lang'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";
function AutoFileAddress(props) {
    const t = useTranslation()
    Geocode.setApiKey("AIzaSyAfn0186e_Uq5kHupvAXXYrG8ER6kcqP8Y");
    const [location, setLocation] = useState(props.location);
    const [latlan, setLatLan] = useState('');
    const [locationerror] = useState('');

    useEffect(() => {
        props.currentlocation(
            {
                'geolocation': '',
                'location': '',
            }
        )
    }, [locationerror])
    const handleChange = (address) => {
        setLocation(address);
    }
    const handleSelect = address => {
        // setLocationData({ ...locationData, address: address });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                let l = latLng.lat + "," + latLng.lng;
                setLatLan(l)
                setLocation(address)
            })
            .catch(error => console.error("Error", error));
    };

    useEffect(() => {
        props.currentlocation(
            {
                'geolocation': latlan,
                'location': location,
            }
        )
    }, [location])
    return (
        <React.Fragment>
            <label className="control-label" >{t('signup.current_location')}</label>
            <PlacesAutocomplete
                value={location}
                onChange={handleChange}
                name="location"
                onSelect={handleSelect}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                }) => (
                        <div className="">
                            <input
                                {...getInputProps({
                                    placeholder: t('signup.enter_location'),
                                    name: "location",
                                    autoComplete: 'off',
                                    className: "location-search-input form-control"
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>{t('signup.loading')}</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
            </PlacesAutocomplete>
            <span className="text-danger">{locationerror}</span>
        </React.Fragment>
    );
}
export default AutoFileAddress;
