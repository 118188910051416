import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import VendorContext from "./../../../../context/vendorSignupData";
import generalApi from '../../../../services/generalApi'
class PromoterInfo extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            fullname: '',
            contactno: '+971303407962',
            contactno1: '+971303407962',
            email: 'usmanzia7864561@gmail.com',
            gender: '',
        }
    }
    static contextType = VendorContext;
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
        console.log("selectedList", this.state.services);
    }
    validateError = () => {
        console.log("showMessages", this.validator.getErrorMessages());

        if (this.validator.allValid()) {
            const [VendorData, setVendorData] = this.context
            let info = VendorData;
            info["basicInnfo"] = this.state;
            console.log("all basic info", info);
            setVendorData({ ...info })
            this.props.nextStep()
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const userType = this.context[0]["userType"];
        return (

            <div className="userType">
                <h2>Basic Info</h2>
                <div className="row">
                    <div className="col-lg-12 form-group">
                        <label >Full Name</label>
                        <input name="fullname" type="text" value={this.state.fullname} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('fullname')} className="form-control" placeholder="Full name" />
                        <span className="text-danger">{this.validator.message('fullname', this.state.fullname, 'required')}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 form-group">
                        <label >Contact No</label>
                        <input name="contactno" type="text" value={this.state.contactno} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('contactno')} className="form-control" placeholder="Contact No" />
                        <span className="text-danger">{this.validator.message('contactno', this.state.contactno, 'required|phone')}</span>
                    </div>
                    <div className="col-lg-6 form-group">
                        <label >Contact No</label>
                        <input name="contactno1" type="text" value={this.state.contactno1} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('contactno1')} className="form-control" placeholder="Contact No 2" />
                        <span className="text-danger">{this.validator.message('contactno1', this.state.contactno1, 'required|phone')}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 form-group">
                        <label >Email</label>
                        <input name="email" type="email" value={this.state.email} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('email')} className="form-control" placeholder="Email" />
                        <span className="text-danger">{this.validator.message('email', this.state.email, 'required|email')}</span>
                    </div>
                    <div className="col-lg-6 form-group">
                        <label >Gender</label>
                        <div className="d-inline mt-1">
                            <label className="control control--radio mb-0">
                                Male
                                <input name="gender" type="radio" checked={this.state.gender == 1} value={1} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('gender')} className="form-check-input" />
                                <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio mb-0 ml-1">
                                Fmale
                                <input name="gender" type="radio" checked={this.state.gender == 2} value={2} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('gender')} className="form-check-input" />
                                <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio mb-0 ml-1">
                                N/A
                                <input name="gender" type="radio" checked={this.state.gender == 3} value={3} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('gender')} className="form-check-input" />
                                <div className="control__indicator"></div>
                            </label>
                        </div>
                        <span className="text-danger">{this.validator.message('gender', this.state.gender, 'required')}</span>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <p><button className="btn btn-info " onClick={this.props.previousStep}>Previous Step</button></p>
                    </div>
                    <div className="col-lg-6">
                        <p className="text-right"><button className="btn btn-primary" onClick={() => { this.validateError() }}>Next Step</button></p>
                    </div>
                </div>
            </div >
        )
    }
}

export default PromoterInfo;