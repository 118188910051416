import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import VendorContext from "./../../../../context/vendorSignupData";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";
class Address extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        let userData = JSON.parse(localStorage.getItem("user_data"))
        this.userType = userData["servicecategory"] == "118" ? "promoter" : "professional"
        let userInfo = props.userinfo
        this.userType = userData["servicecategory"] == "118" ? "promoter" : "professional"
        let latlng = userInfo["geolocation"].split(',')
        this.state = {
            address: userInfo["address"],
            geolocation: {
                lat: latlng[0],
                lng: latlng[1]
            },
            geolocationaddress: userInfo["address"],
            operatingradius: userInfo["radius"]
        }
    }
    static contextType = VendorContext;
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
        console.log("Checked", e.target.checked);
        console.log('Basic Info', this.state)
    }
    handleAddress = (adress) => {
        this.setState({ geolocationaddress: adress })
    }
    handleSelect = address => {
        // setLocationData({ ...locationData, address: address });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                let l = latLng.lat + "," + latLng.lng;
                this.setState({
                    ...this.state,
                    geolocation: {
                        lat: latLng.lat,
                        lng: latLng.lng
                    },
                    geolocationaddress: address
                });
            })
            .catch(error => console.error("Error", error));
    };
    validateError = () => {
        if (this.validator.allValid()) {
            const [VendorData, setVendorData] = this.context
            let info = VendorData;
            info["location"] = this.state;
            setVendorData({ ...info })
            this.props.nextStep()
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        return (
            <div className="userType">
                <h2>Address </h2>
                <div className="row">
                    <div className={`form-group ${this.userType == "professional" ? "col-lg-4" : "col-lg-6"}`}>
                        <label >Address/Landmark</label>
                        <input name="address" type="text" value={this.state.address} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('address')} className="form-control" placeholder="Address/Landmark" />
                        <span className="text-danger">{this.validator.message('address', this.state.address, 'required')}</span>
                    </div>
                    <div className={`form-group ${this.userType == "professional" ? "col-lg-4" : "col-lg-6"}`}>
                        <label >GeoLocation</label>
                        <PlacesAutocomplete
                            value={this.state.geolocationaddress}
                            onChange={this.handleAddress}
                            name="geolocation"
                            onSelect={this.handleSelect}
                        >
                            {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading
                            }) => (
                                    <div className="">
                                        <input
                                            {...getInputProps({
                                                placeholder: "Enter Location",
                                                name: "geolocation",
                                                autoComplete: 'off',
                                                className: "location-search-input form-control"
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                        </PlacesAutocomplete>
                        <span className="text-danger">{this.validator.message('geolocation', this.state.geolocation, 'required')}</span>
                    </div>
                    {this.userType == "professional" ?
                        <div className="col-lg-4 form-group">
                            <label >Operating Radius (Min 3 KM)</label>
                            <input name="operatingradius" type="number" value={this.state.operatingradius} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('operatingradius')} className="form-control" placeholder="Operating Radius (Min 3 KM)" />
                            <span className="text-danger">{this.validator.message('operatingradius', this.state.operatingradius, 'required')}</span>
                        </div>
                        : null}
                </div>
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <p><button className="btn btn-info " onClick={this.props.previousStep}>Previous Step</button></p>
                    </div>
                    <div className="col-lg-6">
                        <p className="text-right"><button className="btn btn-primary" onClick={() => { this.validateError() }}>Next Step</button></p>
                    </div>
                </div>
            </div >
        )
    }
}

export default Address;