import React, { useState, useEffect } from "react";
import generalApi from '../../services/generalApi'
import Loader from "./../../component/shared/loader";
function MyWallet(props) {
    const [loading, setLoading] = useState(true);
    const [myWallet, setWallet] = useState([])
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            certificate: userData["certificate"]
        }
        generalApi.getData('SSMVendorWalletStatement', json).then(response => {
            console.log("setVideo", response["data"]);
            setWallet(response["data"])
            setLoading(false);
        });
    }, []);
    if (loading) {
        return <Loader></Loader>
    }
    return (
        <div className="container container-ver2">
            <div className="dashboard__list">
                <div className="order-column">
                    <div className="card-body text-center">
                        <i className="iconsminds-clock"></i>
                        <p className="font-weight-bold f-17 mb-0">Balance</p>
                        <p className="lead text-center">{myWallet["balance"]}</p>
                    </div>
                </div>
                <div className="order-column">
                    <div className="card-body text-center">
                        <i className="iconsminds-clock"></i>
                        <p className="font-weight-bold f-17 mb-0">Cash Received</p>
                        <p className="lead text-center">{myWallet["balance"]}</p>
                    </div>
                </div>
                <div className="order-column">
                    <div className="card-body text-center">
                        <i className="iconsminds-clock"></i>
                        <p className="font-weight-bold f-17 mb-0">Last Settlement</p>
                        <p className="lead text-center">{myWallet["balance"]}</p>
                    </div>
                </div>
            </div>
            <div className="wallet-statement">
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Details</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {myWallet["walletstatement"].map((info, i) => (
                            <tr key={info["slno"]}>
                                <td>{i + 1}</td>
                                <td>{info["transactiondate"]}</td>
                                <td className="ads-details-td">
                                    <div>
                                        <p><strong>{info["narration1"]} </strong></p>

                                        <p><strong> Ref: </strong>:{info["narration2"]} </p>
                                    </div>
                                </td>
                                <td>
                                    {info["currency"] + info["amount"]}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default MyWallet;
