import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import VendorContext from "./../../../../context/vendorSignupData";
import generalApi from '../../../../services/generalApi'
import { Multiselect } from 'multiselect-react-dropdown';
class ProfessionalInfo extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            fullname: '',
            contactname: '',
            yearformation: '',
            teamsize: '',
            contactno: '',
            contactno1: '',
            mobileprefix: props.mobileprefix,
            email: '',
            companytype: 'company',
            gender: '1',
            qualfication: '',
            services: [],
            serviceList: []
        }
        this.serviceList()
    }
    static contextType = VendorContext;
    serviceList = () => {
        generalApi.getData("SSMServiceList", []).then(response => {
            let services = [];
            response["data"]["servicelist"].map((info, i) => (
                services[i] = { det: info["servicecode"], name: info["servicename"] }
            ))
            this.setState({ serviceList: services })
        });
    }
    handleChange = (e) => {
        if (e.target.name === 'contactno' || e.target.name === 'contactno1') {
            let mobileprefix = this.state.mobileprefix
            let mobile = e.target.value.slice(mobileprefix.length)
            if (mobile.length > 9) {
                mobile = mobile.substring(0, 9);
            }
            this.setState({ ...this.state, [e.target.name]: mobile })
        } else {
            this.setState({ ...this.state, [e.target.name]: e.target.value })
        }
        // this.setState({ ...this.state, [e.target.name]: e.target.value })
        console.log("selectedList", this.state.services);
    }
    onSelect = (selectedList, selectedItem) => {
        console.log("selectedList", selectedList);
        this.setState({ services: selectedList })
        console.log("selectedList2", this.state.services);
    }

    onRemove = (selectedList, removedItem) => {
        this.setState({ services: selectedList })
        console.log("selectedList2", this.state.services);
    }
    validateError = () => {
        console.log("showMessages", this.validator.getErrorMessages());

        if (this.validator.allValid()) {
            const [VendorData, setVendorData] = this.context
            let info = VendorData;
            info["basicInnfo"] = this.state;
            info["basicInnfo"]['contactno'] = this.state.contactno
            info["basicInnfo"]['contactno1'] = this.state.contactno1
            console.log("all basic info", info);
            setVendorData({ ...info })
            this.props.nextStep()
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const userType = this.context[0]["userType"];
        return (

            <div className="userType">
                <h2>Basic Info</h2>
                <div className="row">
                    <div className="col-lg-4 form-group">
                        <label >Full Name</label>
                        <input name="fullname" type="text" value={this.state.fullname} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('fullname')} className="form-control" placeholder="Full name" />
                        <span className="text-danger">{this.validator.message('full name', this.state.fullname, 'required|max:40')}</span>
                    </div>
                    {userType == "professional" ?
                        <React.Fragment>
                            <div className="col-lg-4 form-group">
                                <label >Contact Name</label>
                                <input name="contactname" type="text" value={this.state.contactname} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('contactname')} className="form-control" placeholder="Contact name" />
                                <span className="text-danger">{this.validator.message('contact name', this.state.contactname, 'required|max:40')}</span>
                            </div>
                            <div className="col-lg-4 form-group">
                                <label >Year Formation</label>
                                <input name="yearformation" type="number" value={this.state.yearformation} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('yearformation')} className="form-control" placeholder="Year Formation" />
                                <span className="text-danger">{this.validator.message('year formation', this.state.yearformation, 'required|min:4|max:4')}</span>
                            </div>
                        </React.Fragment>
                        : null}
                </div>
                <div className="row">
                    {userType == "professional" ?
                        <div className="col-lg-4 form-group">
                            <label >Team Size</label>
                            <input name="teamsize" type="number" min="1" value={this.state.teamsize} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('teamsize')} className="form-control" placeholder="Team Size" />
                            <span className="text-danger">{this.validator.message('team size', this.state.teamsize, 'required')}</span>
                        </div>
                        : null}
                    <div className="col-lg-4 form-group">
                        <label >Contact No</label>
                        <input name="contactno" type="text" value={this.state.mobileprefix + this.state.contactno} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('contactno')} className="form-control" placeholder="Contact No" />
                        <span className="text-danger">{this.validator.message('contactno', this.state.contactno, 'required|min:9')}</span>
                    </div>
                    <div className="col-lg-4 form-group">
                        <label >Contact No</label>
                        <input name="contactno1" type="text" value={this.state.mobileprefix + this.state.contactno1} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('contactno1')} className="form-control" placeholder="Contact No 2" />
                        <span className="text-danger">{this.validator.message('contactno1', this.state.contactno1, 'required|min:9')}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 form-group">
                        <label >Email</label>
                        <input name="email" type="email" value={this.state.email} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('email')} className="form-control" placeholder="Email" />
                        <span className="text-danger">{this.validator.message('email', this.state.email, 'required|email|max:70')}</span>
                    </div>
                    {userType == "professional" ?
                        <div className="col-lg-4 form-group">
                            <label >Company Type</label>
                            <div className="d-inline mt-1">
                                <label className="control control--radio mb-0">
                                    Company
                                <input name="companytype" type="radio" checked={this.state.companytype == "company"} value="company" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('companytype')} className="form-check-input" />
                                    <div className="control__indicator"></div>
                                </label>
                                <label className="control control--radio mb-0 ml-1">
                                    Individual
                              <input name="companytype" type="radio" checked={this.state.companytype == "individual"} value="individual" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('companytype')} className="form-check-input" />
                                    <div className="control__indicator"></div>
                                </label>
                            </div>
                            <span className="text-danger">{this.validator.message('company type', this.state.companytype, 'required')}</span>
                        </div>
                        : null}
                    <div className="col-lg-4 form-group">
                        <label >Gender</label>
                        <div className="d-inline mt-1">
                            <label className="control control--radio mb-0">
                                Male
                                <input name="gender" type="radio" checked={this.state.gender == 1} value={1} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('gender')} className="form-check-input" />
                                <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio mb-0 ml-1">
                                Female
                                <input name="gender" type="radio" checked={this.state.gender == 2} value={2} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('gender')} className="form-check-input" />
                                <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio mb-0 ml-1">
                                N/A
                                <input name="gender" type="radio" checked={this.state.gender == 3} value={3} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('gender')} className="form-check-input" />
                                <div className="control__indicator"></div>
                            </label>
                        </div>
                        <span className="text-danger">{this.validator.message('gender', this.state.gender, 'required')}</span>
                    </div>
                </div>
                {userType == "professional" ?
                    <div className="row">
                        <div className="col-lg-4 form-group">
                            <label >Qualification</label>
                            <select name="qualfication" defaultValue={'null'} className="form-control" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('qualfication')}>
                                <option disabled value="null">Select Qualification</option>
                                <option value="M">Master's Degree</option>
                                <option value="E">Engineering</option>
                                <option value="G">Graduate</option>
                                <option value="D">Diploma</option>
                                <option value="H">Higher Secondary</option>
                                <option value="P">Primary School</option>
                            </select>
                            <span className="text-danger">{this.validator.message('qualfication', this.state.qualfication, 'required')}</span>
                        </div>
                        <div className="col-lg-4 form-group">
                            <label >Services</label>
                            <Multiselect
                                options={this.state.serviceList} // Options to display in the dropdown
                                // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                onSelect={this.onSelect} // Function will trigger on select event
                                onRemove={this.onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                            />

                            <span className="text-danger">{this.validator.message('services', this.state.services, 'required')}</span>
                        </div>
                    </div>
                    : null}
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <p><button className="btn btn-info " onClick={this.props.previousStep}>Previous Step</button></p>
                    </div>
                    <div className="col-lg-6">
                        <p className="text-right"><button className="btn btn-primary" onClick={() => { this.validateError() }}>Next Step</button></p>
                    </div>
                </div>
            </div >
        )
    }
}

export default ProfessionalInfo;