import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import VendorContext from "./../../../../context/vendorSignupData";
import { withRouter } from "react-router-dom";
import generalApi from './../../../../services/generalApi'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class BankInfo extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        let userData = JSON.parse(localStorage.getItem("user_data"))
        this.userType = userData["servicecategory"] == "118" ? "promoter" : "professional"
        let userInfo = props.userinfo
        this.userType = userData["servicecategory"] == "118" ? "promoter" : "professional"
        this.state = {
            vatnumber: userInfo["ifsccode"],
            iban: userInfo["bankaccount"],
            bankname: "",
            accepted: "",
            isactive: false
        }
    }
    static contextType = VendorContext;
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    promotorRegister = () => {
        const [VendorData, setVendorData] = this.context
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            certificate: userData["certificate"],
            fullname: VendorData["basicInnfo"]["fullname"],
            mobile: VendorData["basicInnfo"]["contactno"],
            contact: VendorData["basicInnfo"]["contactno1"],
            email: VendorData["basicInnfo"]["email"],
            address: VendorData["location"]["geolocationaddress"],
            gender: VendorData["basicInnfo"]["gender"],
            geolocation: VendorData["location"]["geolocation"]["lat"] + ',' + VendorData["location"]["geolocation"]["lng"],
            bankaccount: VendorData["bankInfo"]["iban"],
            ifsccode: "ABHY0065001",
            reference: VendorData["referencenumber"]
        }
        generalApi.getData('SSMUpdatePromoterProfile', json).then(response => {
            console.log('Response', response["data"]);
            if (response["data"]["statuscode"] == "000") {
                let msg = {
                    heading: 'Congratlion',
                    des: 'Welcome to KhidmaNow and thank you for registering with us. We are glad to have you as our Service Provider. Our representative will contact you shortly for KYC confirmation process and your account will be activated once the KYC process is completed.',
                    link: '/',
                    linkname: 'Home',
                };
                localStorage.setItem('message', JSON.stringify(msg));
                this.props.history.push('/message')
            } else {
                this.setState({ isactive: false })
                toast.error(response["data"]["statusdesc"]);
            }
        });
    }
    professionalRegister = () => {
        const [VendorData, setVendorData] = this.context
        const documentList = [
            { imagetype: 'A', image: VendorData["documents"]["residentimage"], idnumber: VendorData["documents"]["residentno"] },
            { imagetype: 'T', image: VendorData["documents"]["tradelicenseimage"], idnumber: VendorData["documents"]["tradelicense"] },
            { imagetype: 'D', image: VendorData["documents"]["drivinglicenseimage"], idnumber: VendorData["documents"]["drivinglicense"] }
        ]
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            certificate: userData["certificate"],
            fullname: VendorData["basicInnfo"]["fullname"],
            mobile: VendorData["basicInnfo"]["contactno"],
            mobile2: VendorData["basicInnfo"]["contactno1"],
            email: VendorData["basicInnfo"]["email"],
            address: VendorData["location"]["geolocationaddress"],
            gender: VendorData["basicInnfo"]["gender"],
            geolocation: VendorData["location"]["geolocation"]["lat"] + ',' + VendorData["location"]["geolocation"]["lng"],
            bankaccount: VendorData["bankInfo"]["iban"],
            ifsccode: "ABHY0065001",
            companytype: VendorData["basicInnfo"]["companytype"],
            serviceList: VendorData["basicInnfo"]["services"],
            reference: VendorData["referencenumber"],
            idnumber: "",
            fromdate: VendorData["basicInnfo"]["yearformation"],
            sgst: VendorData["basicInnfo"]["teamsize"],
            qualification: VendorData["basicInnfo"]["qualfication"],
            profilepicture: VendorData["documents"]["profileimage"],
            gstnumber: "",
            contact: VendorData["basicInnfo"]["contactname"],
            radius: VendorData["location"]["operatingradius"],
            documentList: documentList
        }
        generalApi.getData('SSMupdatepartnerprofile', json).then(response => {
            console.log('Response', response["data"]);
            if (response["data"]["statuscode"] == "000") {
                let msg = {
                    heading: 'Thanks',
                    des: 'Profile Successfully update',
                    link: '/',
                    linkname: 'Home',
                };
                localStorage.setItem('message', JSON.stringify(msg));
                this.props.history.push('/message')
            } else {
                this.setState({ isactive: false })
                toast.error(response["data"]["statusdesc"]);
            }
        });

    }
    validateError = () => {

        if (this.validator.allValid()) {
            const [VendorData, setVendorData] = this.context
            let info = VendorData;
            info["bankInfo"] = this.state;
            this.setState({ isactive: true })
            setVendorData({ ...info })
            let userData = JSON.parse(localStorage.getItem("user_data"));
            if (userData["servicecategory"] == "118") {
                this.promotorRegister();
            } else {
                this.professionalRegister();
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const userType = this.context[0]["userType"];
        return (
            <div className="userType">
                <h2>Bank Info </h2>
                <div className="row">
                    {this.userType == "professional" ?
                        <div className="col-lg-4 form-group">
                            <label >Vat Number</label>
                            <input name="vatnumber" type="text" value={this.state.vatnumber} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('vatnumber')} className="form-control" placeholder="Vat No" />
                            {/* <span className="text-danger">{this.validator.message('vatnumber', this.state.vatnumber, '')}</span> */}
                        </div>
                        : null}
                    <div className={`form-group ${this.userType == "professional" ? "col-lg-4" : "col-lg-6"}`}>
                        <label >Bank Account IBAN</label>
                        <input name="iban" type="text" value={this.state.iban} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('iban')} className="form-control" placeholder="Bank Account IBAN no" />
                        <span className="text-danger">{this.validator.message('iban', this.state.iban, 'required')}</span>
                    </div>
                    <div className={`form-group ${this.userType == "professional" ? "col-lg-4" : "col-lg-6"}`}>
                        <label >Bank Name</label>
                        <input name="bankname" type="text" value={this.state.bankname} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('bankname')} className="form-control" placeholder="Bank Name" />
                        <span className="text-danger">{this.validator.message('bankname', this.state.bankname, 'required')}</span>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <p><button className="btn btn-info " onClick={this.props.previousStep}>Previous Step</button></p>
                    </div>
                    <div className="col-lg-6">
                        <p className="text-right"><button className="btn btn-primary" onClick={() => { this.validateError() }} disabled={this.state.isactive ? true : false}>
                            {this.state.isactive ? "Processing..." : "Update"}
                        </button></p>
                    </div>
                </div>
            </div >
        )
    }
}

export default withRouter(BankInfo);