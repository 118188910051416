import React, { useEffect, useContext } from "react";
import paytmApi from "./../../services/paytmApi";
import Modal from "./../shared/model";
import { useState } from "react";
import { stripHtml } from './../../config/helper'
import { withRouter } from "react-router-dom";
import { LoginContext } from "./../../context/loginContext";
function ShareWebsite(props) {
    const [isAuth] = useContext(LoginContext);
    const [isOpen, toggleOpen] = useState(false);
    const [link, setLink] = useState("");
    const sharepagelink = props.sharepagelink;
    const longtext = stripHtml(props.longtext);
    let weburl = "http://www.raashanmarket.com/referral/";
    if (isAuth["isLogin"] && isAuth["isVendor"]) {
        weburl = "http://www.raashanmarket.com/vendor/referral/";
    }
    const websiteurl = weburl;
    const referancedevice =
        "PC^OS Version^64.0^127.0.0.1^25.109344,55.196632^1920^2550^ASWERFCDE1234321^";
    const firbaselink =
        "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCc5vDef7YVMazMYRcMorfqr4zmswT8Lgk";
    const mobileno = "+971303407966";

    const sharedClicked = () => {
        if (!isAuth["isLogin"]) {
            props.history.push("/login");
        } else {
            toggleOpen(!isOpen);
        }
    };

    useEffect(() => {
        const json = {
            dynamicLinkInfo: {
                domainUriPrefix: `${sharepagelink}`,
                link: `${websiteurl}/?referdevice=${referancedevice}&mobile=${mobileno}&linktype='DOWNLOAD'&productcode`,
                androidInfo: {
                    androidPackageName: "com.example.android",
                },
                iosInfo: {
                    iosBundleId: "com.example.ios",
                },
            },
        };
        paytmApi.getData(firbaselink, json).then((res) => {
            console.log("share res", res["data"]["shortLink"]);
            setLink(res["data"]["shortLink"]);
        });
    }, []);
    const onCopyText = () => {
        const share__input = document.querySelector(".share__input");
        share__input.select();
        share__input.setSelectionRange(0, 99999);
        document.execCommand("copy");
    };

    return (
        <>
            <Modal isOpen={isOpen}>
                <div className="modal-header">
                    <h4 className="modal-title">Share Website</h4>
                    <button
                        onClick={sharedClicked}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                    >
                        ×
                    </button>
                </div>
                <div className="modal-body py-3 d-flex justify-content-center">
                    <div className="copy__input">
                        <input type="url" className="share__input" readOnly value={link} />
                        <i className="fa fa-clipboard copy__icon" onClick={onCopyText}></i>
                    </div>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.facebook.com/sharer/sharer.php?u=${link}&quote=${longtext}`}
                    >
                        <i className="fa social__icon fa-facebook" />
                    </a>
                    <a
                        target="_blank"
                        href={`https://twitter.com/intent/tweet?url=${link}&text=${longtext}`}
                    >
                        <i className="fa social__icon fa-twitter" />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`whatsapp://send?text=${longtext + link}`}
                    >
                        <i className="fa social__icon fa-whatsapp" />
                    </a>
                    <a
                        href={`mailto:${props.email}?&subject=KidmaNow Referral&body=${longtext} ${link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fa social__icon fa-envelope-o" />
                    </a>
                </div>
            </Modal>
            <div
                className="share__icon"
                title={!isAuth["isLogin"] ? "Login" : ""}
                onClick={sharedClicked}
            >
                <i className="fa fa-share-alt green_c" />
            </div>
        </>
    );
}

export default withRouter(ShareWebsite);
