import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import LoadedState from "./../shared/dummyImage";
function HomeCatagory(props) {
    let catagories = props.catagories;
    const t = useTranslation();
    return (
        <div className="container container-ver2 mt-4 banner-home4">
            <div className="catbox-container home-4">
                <div className="categories__heading">
                    <div className="row">
                        <div className="col-xs-7">
                            <h1 className="title">{t("home.topcategory")}</h1>
                        </div>
                        <div className="col-xs-5 text-right">
                            <Link to="/categories" className="link">
                                {t("home.view_all")}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {catagories.map((info) => (
                        <div className="col-md-3 col-sm-3 col-xs-6" key={info["subcategorycode"]}>
                            <Link
                                to={"/product-list/" + info["subcategorycode"]}
                                title={t("home.view_all_product")}
                            >
                                <div className="items">
                                    <div className="hover-images">
                                        <LoadedState
                                            key={1}
                                            width="270"
                                            height="270"
                                            classname="primary_image12"
                                            src={info["imagelargeurl"]}
                                        />
                                        <div className="text">
                                            <h3>{info["subcategoryname"]}</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default HomeCatagory;
