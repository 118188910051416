import React, { useContext } from "react";
import TopSlider from "./../component/home/topSlider";
import HomeInfo from "./../component/home/homeInfo";
import HomeCatagory from "./../component/home/homeCatagory";
import ContactInfo from "./../component/home/contactInfo";
import FeautreProduct from "./../component/home/feautreProduct";
import AppDownloader from "./../component/home/appDownload";
import Jumbotron from "./../component/shared/jumbotron";
import { LoginContext } from './../context/loginContext';
function Home(props) {
    console.log("Produc", props.homeData["productlist"])
    localStorage.removeItem("coupeninfo");
    localStorage.removeItem("userorderinfo");
    localStorage.removeItem("orderid");
    const [isAuth] = useContext(LoginContext);
    console.log("Home", props.homeData);

    return (
        <React.Fragment>
            <TopSlider sliderImages={props.homeData["slidelist"]}></TopSlider>
            <HomeCatagory catagories={props.homeData["quicksubcategory"]}></HomeCatagory>
            <FeautreProduct
                showfav={0}
                productImages={props.homeData["productlist"]}
            ></FeautreProduct>
            <HomeInfo></HomeInfo>
            {/* <ProductSlider productImages={props.homeData["productlist"]}></ProductSlider> */}
            <ContactInfo phone={props.homeData["jcarecall"]} />
            {isAuth['isLogin'] ? null :
                <Jumbotron />}
            <AppDownloader mobileprefix={props.homeData["mobileprefix"]}></AppDownloader>
        </React.Fragment>
    );
}
export default Home;
