import React from 'react'
import { useTranslation } from 'react-multi-lang'
function ContactInfo(props) {
    const t = useTranslation()
    return (
        <div className="banner-home3">
            <div className="container container-ver2">
                <img className="img-responsive" src="http://landing.engocreative.com/html/freshfood/demo/assets/images/banner-home3.jpg" alt="banner-home3" />
                <div className="text">
                    <img src="/images/icon-phone.png" alt="icon" />
                    <h4>{t('home.call_for_now')}</h4>
                    <h3>{props.phone}</h3>
                    <p>{t('home.contact_heading')}</p>
                </div>
                <div className="icon-banner">
                    <img src="http://landing.engocreative.com/html/freshfood/demo/assets/images/home3-images-banner1.png" alt="icon-banner" />
                </div>
            </div>
        </div>
    )
}
export default ContactInfo