import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Link, withRouter } from "react-router-dom";
import generalApi from '../../../services/generalApi'
import CurrentLocation from './../../../component/shared/currentLocation'
import { toast } from 'react-toastify';
import { HomeContext } from './../../../context/homeContext'
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./../../../component/shared/loader";
class AddEmployee extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            fullname: '',
            gender: 1,
            contactno: '',
            mobileprefix: '',
            email: '',
            address: '',
            geolocation: '',
            status: 1,
            servicecode: "",
            locationError: "",
            service: '',
            isactive: false,
            loading: true,
            serviceList: []
        }
        this.serviceList();
    }
    static contextType = HomeContext;
    currentlocation = (location) => {
        this.setState({ ...this.state, geolocation: location["geolocation"], address: location["location"] })
    }
    serviceList = () => {
        generalApi.getData("SSMServiceList", []).then(response => {
            let services = [];
            response["data"]["servicelist"].map((info, i) => (
                services[i] = { det: info["servicename"], name: info["servicecode"] }
            ))
            this.setState({ serviceList: response["data"]["servicelist"] })
            this.setState({ servicecode: response["data"]["servicelist"][0]["servicecode"], loading: false })
        });
    }
    handleChange = (e) => {
        if (e.target.name === 'contactno') {
            const [homeData] = this.context
            let mobileprefix = homeData["mobileprefix"]
            let mobile = e.target.value.slice(mobileprefix.length)
            this.setState({ ...this.state, [e.target.name]: mobile })
        } else {
            this.setState({ ...this.state, [e.target.name]: e.target.value })
        }
    }
    submitForm = (e) => {
        this.setState({ isactive: true })
        const [homeData] = this.context
        let mobileprefix = homeData["mobileprefix"]
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            certificate: userData["certificate"],
            fullname: this.state.fullname,
            mobile: mobileprefix + this.state.contactno,
            email: this.state.email,
            address: this.state.address,
            gender: this.state.gender,
            status: this.state.status,
            geolocation: this.state.geolocation,
            fbtoken: "jjjjjjjjjjj",
            service: this.state.servicecode,
        }
        generalApi.getData('SSMaddpartneremployee', json).then(response => {
            console.log('Response', response["data"]);
            if (response["data"]["statuscode"] == "000") {
                let message = { heading: "Success!", des: `Successfully add the new employee`, link: "/vendor/employee", linkname: "Employee List" }
                localStorage.setItem('message', JSON.stringify(message))
                this.props.history.push('/message')
            } else {
                this.setState({ isactive: false })
                toast.error(response["data"]["statusdesc"]);
            }
        });
    }
    validateError = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            this.submitForm()
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader></Loader>
        }
        const [homeData] = this.context
        let mobileprefix = homeData["mobileprefix"]
        return (
            <div className="container container-ver2">
                <div className="page-login box space-50">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 sign-in space-30">
                            <h3>Add new Employee</h3>
                            <form className="form-horizontal" onSubmit={this.validateError}>
                                <div className="group box space-20">
                                    <label className="control-label" >Full Name</label>
                                    <input className="form-control" type="text" name="fullname" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('fullname')} value={this.state.firstname} placeholder="Full Name" />
                                    <span className="text-danger">{this.validator.message('fullname', this.state.fullname, 'required')}</span>
                                </div>
                                <div className="group box space-20 ">
                                    <label className="control-label" >Gender </label>
                                    <div className="profile-gender">
                                        <label className="paymentcontainer">
                                            <h4>Male</h4>
                                            <input type="radio" onChange={this.handleChange} value={1} checked={this.state.gender == 1 ? true : false} name="gender" />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="paymentcontainer" >
                                            <h4>Fmale</h4>
                                            <input type="radio" onChange={this.handleChange} value={2} checked={this.state.gender == 2 ? true : false} name="gender" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <span className="text-danger">{this.validator.message('Gender', this.state.gender, 'required')}</span>
                                </div>
                                <div className="group box space-20">
                                    <label className="control-label" >Contact No</label>
                                    <input className="form-control" type="text" name="contactno" value={mobileprefix + this.state.contactno} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('contactno')} placeholder="Contact No" />
                                    <span className="text-danger">{this.validator.message('Contact No', this.state.contactno, 'required|phone')}</span>
                                </div>
                                <div className="group box space-20">
                                    <label className="control-label" >Email</label>
                                    <input className="form-control" type="email" name="email" value={this.state.email} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('email')} placeholder="Email" />
                                    <span className="text-danger">{this.validator.message('email', this.state.email, 'required|email')}</span>
                                </div>
                                <div className="group box space-20">
                                    <label className="control-label" >Service</label>
                                    <select name="servicecode" defaultValue={this.state.servicecode} onChange={this.handleChange} className="form-control">
                                        {this.state.serviceList.map((info) => (
                                            <option key={info["servicecode"]} value={info["servicecode"]}>{info["servicename"]}</option>
                                        ))}
                                    </select>
                                    <span className="text-danger">{this.validator.message('email', this.state.email, 'required|email')}</span>
                                </div>
                                <div className="group box space-20 ">
                                    <label className="control-label" >Status </label>
                                    <div className="profile-gender">
                                        <label className="paymentcontainer">
                                            <h4>Active</h4>
                                            <input type="radio" onChange={this.handleChange} value={1} checked={this.state.status == 1 ? true : false} name="status" />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="paymentcontainer" >
                                            <h4>Inactive</h4>
                                            <input type="radio" onChange={this.handleChange} value={2} checked={this.state.status == 2 ? true : false} name="status" />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <span className="text-danger">{this.validator.message('status', this.state.status, 'required')}</span>
                                </div>
                                <div className="input-group group box">
                                    <CurrentLocation geolocation={this.state.geolocation} location={this.state.location} currentlocation={this.currentlocation}></CurrentLocation>
                                    <span className="text-danger">{this.validator.message('Location', this.state.geolocation, 'required')}</span>
                                </div>
                                <div className="mt-1">
                                    <button type="submit" disabled={this.state.isactive ? true : false} className="link-v1 rt">
                                        {this.state.isactive ? "Processing..." : "Add Employee"}
                                    </button>
                                    <Link to="/vendor/employee" className="btn btn-danger btn-left lh-27  float-right link-v1">Back</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AddEmployee);