import React, { useEffect, useState } from "react";
import generalApi from "../../../services/generalApi";
import TopImage from "./../../../component/product/topImage";
import Loader from "./../../../component/shared/loader";
import OrderList from "./../../../component/vendor/order/orderList";
import OrderBtn from "./../../../component/vendor/order/orderbtn";
function LoadOrder() {
    const [loading, setLoading] = useState(true);
    const [orderData, setOrderData] = useState([]);
    const [vichle, setVichle] = useState([]);
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem("user_data"));
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            employeeaccount: userData["isadmin"] === "Y" ? "" : "employee",
            certificate: userData["certificate"],
            serviceList: [],
            status: 2,
        };
        generalApi.getData("SSMNewOrderListProduct", json).then((response) => {
            setOrderData(response["data"]);
            console.log("Employee", response["data"]);

            generalApi.getData('SSMEmployeeList', json).then(response => {
                setVichle(response["data"]["employeelist"])
                console.log("Employee", response["data"]);
                setLoading(false);
            });
        });
    }, []);
    if (loading) {
        return <Loader></Loader>;
    }
    return (
        <React.Fragment>
            <div className="cart-box-container">
                <div className="container container-ver2">
                    <TopImage text="Load Order" />
                    <div className="box cart-container mt-1">
                        <div className="order__list">
                            <h3 className="text-center">Load Order List</h3>
                            {orderData["timeline"].map((info) => (
                                <div className="order__item" key={info["orderid"]}>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <OrderList orderinfo={info} ></OrderList>
                                        </div>
                                        <div className="col-md-4">
                                            <OrderBtn showaddress={1} checkin={1} vichlelist={vichle} orderinfo={info} key={info["orderid"]}></OrderBtn>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default LoadOrder;
