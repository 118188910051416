import React from "react";
import { useTranslation } from "react-multi-lang";
function OrderList(props) {
    let orderinfo = props.orderinfo;
    const t = useTranslation();
    return (
        <React.Fragment>
            <div className="content arabic__content">
                <p className="content__item">
                    <span className="font-weight-bold">{t("order.orderid")}</span>
                    <span>{orderinfo["orderid"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">{t("order.orderdate")}</span>
                    <span>{orderinfo["logdatetime"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">{t("order.paymentdate")}</span>
                    <span>{orderinfo["paymentdate"]}</span>
                </p>
                <p className="content__item">
                    <span className="font-weight-bold">{t("order.deliverydate")}</span>
                    <span>{orderinfo["deliverydatetime"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">{t("order.payment")}</span>
                    <span>{orderinfo["paymenttype"]}</span>
                </p>
                <p className="content__item">
                    <span className="font-weight-bold">{t("order.status")}</span>
                    <span>{orderinfo["logstatusdes"]}</span>
                </p>
            </div>
        </React.Fragment>
    );
}
export default OrderList;
