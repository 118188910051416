import React from "react";
import LoadedState from "./../shared/dummyImage";
function TopImage(props) {
    let image =
        "http://landing.engocreative.com/html/freshfood/demo/assets/images/banner-catalog1.jpg";

    image = props.imageurl === undefined || props.imageurl === "" ? image : props.imageurl;
    return (
        <div className="container container-ver2 banner-header banner-lbook3 space-30">
            <div className="row">
                <div className="col-12">
                    <LoadedState
                        width="1170"
                        height="195"
                        classname="primary_image12"
                        src={image}
                    />
                </div>
            </div>
            <div className="text">
                {props.imageurl === undefined || props.imageurl === "" ? (
                    <h3>{props.text}</h3>
                ) : null}
            </div>
        </div>
    );
}
export default TopImage;
