import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import generalApi from '../../services/generalApi'
import SimpleReactValidator from 'simple-react-validator'
import { ToastContainer, toast } from 'react-toastify';
import { T, withTranslation } from 'react-multi-lang'
import { LoginContext } from "./../../context/loginContext";
class RegisterOtp extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T
        }
        const { t } = this.props
        this.validator = new SimpleReactValidator({
            messages: {
                required: t('validation.required'),
                min: t('validation.tokenmin'),
                max: t('validation.tokenmax'),
            }
        });
        this.state = { isactive: false, 'otp': '', signipData: JSON.parse(localStorage.getItem('signupData')) }

    }
    static contextType = LoginContext;
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
    }
    submitForm = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            this.setState({ otp: this.state.otp });
            const s = { ...this.state["signipData"] };
            let language = localStorage.getItem('language');
            language = language === "arabic" ? "A" : "E"
            const json = {
                ...s,
                otp: this.state.otp,
                gender: 'U',
                segment: '1000',
                additionalinfo: localStorage.getItem('referralmobileno') === null ? "" : localStorage.getItem('referralmobileno'),//Refferal
                language: language
            }

            generalApi.getData('SSMfirsttime', json).then(response => {
                if (response["data"]["statuscode"] === "000") {
                    const Sjson = { customer: this.state.signipData.mobile, password: this.state.signipData.pin }
                    generalApi.getData('SSMvalidateUser', Sjson).then(response => {
                        if (response["data"]["statuscode"] === "000") {
                            localStorage.setItem('user_data', JSON.stringify(response["data"]));
                            localStorage.removeItem('signupData')
                            const [isAuth, setAuth] = this.context;
                            const loginInfo = isAuth;
                            loginInfo["isLogin"] = true;
                            loginInfo["isVendor"] = false;
                            setAuth({ ...loginInfo });
                            // this.registerfcm(response["data"]);
                            localStorage.removeItem('referralmobileno');
                            let previoushistory = localStorage.getItem("historypath");
                            previoushistory = previoushistory === "/shopping-cart" ? previoushistory : "/";
                            this.props.history.push(previoushistory);
                            const { t } = this.props
                            let message = { heading: t('message.success'), des: t('message.success'), link: "/", linkname: t('message.home') }
                            localStorage.setItem('message', JSON.stringify(message))
                            this.props.history.push('/message')
                        } else {
                            this.setState({ isactive: false });
                            toast.error(response["data"]["statusdesc"]);
                        }
                    });
                } else {
                    this.setState({ isactive: false });
                    toast.error(response["data"]["statusdesc"]);
                }

            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        if (!this.state.signipData) {
            return <Redirect to="/signup"></Redirect>
        }
        const { t } = this.props
        return (
            <div className="container container-ver2">
                <ToastContainer autoClose={8000} />
                <div className="page-login box space-50">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 sign-in space-30">
                            <h3>{t('register_otp.otp_form')}</h3>
                            <form className="form-horizontal" onSubmit={this.submitForm}>
                                <div className="group box">
                                    <label className="control-label" >{t('register_otp.otp')}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-lock input__icon"></i>
                                        <input className="form-control englisg-text" required placeholder={t('register_otp.6_digit_sms')} name="otp" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('otp')} value={this.state.otp} type="text" />
                                    </div>
                                    <span className="text-danger">{this.validator.message('Otp', this.state.otp, 'required|min:6|max:6')}</span>
                                </div>
                                <div className="mt-1">
                                    <button type="submit" disabled={this.state.isactive ? true : false} className="link-v1 rt full market-bcolor">
                                        {this.state.isactive ? t('register_otp.processing') : t('register_otp.validate')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default withRouter(withTranslation(RegisterOtp));