import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Arrow from "./Arrow";
function TopSlider(props) {
    let sliderImages = props.sliderImages;
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        arrows: true,
        lazyLoad: "ondemand",
        cssEase: "linear",
        fade: true,
        nextArrow: <Arrow arrowType="right" arrow="angle-right" />,
        prevArrow: <Arrow arrowType="left" arrow="angle-left" />,
    };
    return (
        <div className="container container-ver2">
            <div className="tp-banner-container ver4">
                <div className="tp-banner">
                    <Slider {...settings}>
                        {sliderImages.map((info) => (
                            <div key={info["outletcode"]}>
                                <img src={info["imageurls"]} alt="" />
                            </div>
                        ))}
                    </Slider>
                    <div className="tp-bannertimer"></div>
                </div>
            </div>
        </div>
    );
}
export default TopSlider;
