import React, { Suspense, useContext, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Switch, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LoginContext } from "./../context/loginContext";
import { VendorProvider } from "./../context/vendorSignupData";
import Header from "./../component/template/header";
import Footer from "./../component/template/footer";
import Home from "./../container/home";
import Login from "./../container/account/login";
import Signup from "./../container/account/signup";
import ForgetOtp from "./../container/account/forgetOtp";
import ResetPin from "./../container/account/resetPin";
import Signout from "./../container/account/signout";
import RegisterOtp from "./../container/account/registerOtp";
import Message from "./../component/message";
import Checkout from "./../container/cart/checkout";
import PaytmCallBack from "./../container/cart/paytmCallback";
import CurrentOrder from "./../container/order/currentOrder";
import OrderHistory from "./../container/order/orderHistory";
import Notification from "./../container/notification";
import ChangePassword from "./../container/changePassword";
import AllCategory from "./../component/home/allCatagory";
import { PageLoader } from "./../component/shared/loader";
import ReferralHandler from "../component/shared/refferalHandler";
import VendorReferralHandler from "../component/shared/VendorReferralHandler";
// Static
import CustomerTC from "../container/static/customerTC";
import VendorTC from "../container/static/vendorTC";
import PrivacyPolicy from "../container/static/privacyPolicy";
import RC from "../container/static/refundCancalition";
import AboutUs from "../container/static/aboutUs";
import ContactUs from "../container/static/contactUs";
import RB from "../container/static/referralBenifit";
import CFAQ from "../container/static/customerFAQ";
import VFAQ from "../container/static/vendorFAQ";
import Blog from "../container/static/blog";

// Vendor
import VendorLogin from "./../container/vendor/account/login";
import VendorSignup from "./../container/vendor/account/signup";
import VendorForgetOtp from "./../container/vendor/account/forgetOtp";
import VendorResetPin from "./../container/vendor/account/resetPin";
import VendorSignout from "./../container/vendor/account/signout";
import VendorProfile from "./../container/vendor/account/profile";
import VendorChangePassword from "./../container/vendor/account/changePassword";
import VendorNewOrder from "./../container/vendor/order/newOrder";
import VendorLoadOrder from "./../container/vendor/order/loadOrder";
import VendorBalanceOrder from "./../container/vendor/order/balanceOrder";
import VendorTransitOrder from "./../container/vendor/order/transitOrder";
import VendorOrderHistory from "./../container/vendor/order/orderHistory";
import VendorDashboard from "./../container/vendor/dashboard";
import VendorEmployee from "./../container/vendor/employee/allEmployee";
import VendorAddEmployee from "./../container/vendor/employee/addEmployee";
import VendorUpdateEmployee from "./../container/vendor/employee/updateEmployee";
import VendorSetting from "./../container/vendor/setting";
import VendorRefferal from "./../container/vendor/refferalDashboard";
import VendorVideo from "./../container/vendor/myVideo";
import VendorWallet from "./../container/vendor/MyWallet";

const Profile = React.lazy(() => import("./../container/account/profile"));
const ShoppingCart = React.lazy(() => import("./../container/cart/shoppingCart"));
const ProductList = React.lazy(() => import("./../container/productList"));
const FaviorteList = React.lazy(() => import("./../container/faviouriateList"));
const DealOffer = React.lazy(() => import("./../container/feautreList"));
const MyWallet = React.lazy(() => import("./../container/myWallet"));
const ReferralDasdboard = React.lazy(() => import("./../container/refDashboard"));
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};
function Routes(props) {
    const homeData = props.homeData;
    return (
        <Router>
            <ToastContainer autoClose={8000} />
            <ScrollToTop></ScrollToTop>

            <Header />
            <Switch>
                <Route path="/message">
                    <Message></Message>
                </Route>
                <LoginRoute path="/login">
                    <Login></Login>
                </LoginRoute>
                <LoginRoute path="/forgot-otp">
                    <ForgetOtp></ForgetOtp>
                </LoginRoute>
                <LoginRoute path="/reset-password">
                    <ResetPin></ResetPin>
                </LoginRoute>
                <LoginRoute path="/signup">
                    <Signup></Signup>
                </LoginRoute>
                <LoginRoute path="/register-otp">
                    <RegisterOtp></RegisterOtp>
                </LoginRoute>
                <PrivateUserRoute path="/signout">
                    <Signout></Signout>
                </PrivateUserRoute>
                <Route path="/product-list/:id?">
                    <Suspense fallback={PageLoader}>
                        <ProductList></ProductList>
                    </Suspense>
                </Route>
                <Route path="/deal-list">
                    <Suspense fallback={PageLoader}>
                        <DealOffer></DealOffer>
                    </Suspense>
                </Route>
                <Route path="/fav-list">
                    <Suspense fallback={PageLoader}>
                        <FaviorteList></FaviorteList>
                    </Suspense>
                </Route>
                <PrivateUserRoute path="/profile">
                    <Suspense fallback={PageLoader}>
                        <Profile></Profile>
                    </Suspense>
                </PrivateUserRoute>
                <PrivateUserRoute path="/shopping-cart">
                    <Suspense fallback={PageLoader}>
                        <ShoppingCart></ShoppingCart>
                    </Suspense>
                </PrivateUserRoute>
                <PrivateUserRoute path="/my-wallet">
                    <Suspense fallback={PageLoader}>
                        <MyWallet />
                    </Suspense>
                </PrivateUserRoute>
                <PrivateUserRoute path="/ref-dashboard">
                    <Suspense fallback={PageLoader}>
                        <ReferralDasdboard />
                    </Suspense>
                </PrivateUserRoute>
                <PrivateUserRoute path="/checkout/:status/:bankcode">
                    <Checkout></Checkout>
                </PrivateUserRoute>
                <PrivateUserRoute path="/checkout">
                    <Checkout></Checkout>
                </PrivateUserRoute>
                <PrivateUserRoute path="/paytmCallback">
                    <PaytmCallBack></PaytmCallBack>
                </PrivateUserRoute>
                <PrivateUserRoute path="/current-order">
                    <CurrentOrder></CurrentOrder>
                </PrivateUserRoute>
                <PrivateUserRoute path="/order-history">
                    <OrderHistory></OrderHistory>
                </PrivateUserRoute>
                <PrivateUserRoute path="/notification">
                    <Notification></Notification>
                </PrivateUserRoute>
                <PrivateUserRoute path="/change-password">
                    <ChangePassword></ChangePassword>
                </PrivateUserRoute>
                <LoginRoute path="/vendor/signup">
                    <VendorProvider>
                        <VendorSignup></VendorSignup>
                    </VendorProvider>
                </LoginRoute>
                <LoginRoute path="/vendor/login">
                    <VendorLogin></VendorLogin>
                </LoginRoute>
                <LoginRoute path="/vendor/forgot-otp">
                    <VendorForgetOtp></VendorForgetOtp>
                </LoginRoute>
                <LoginRoute path="/vendor/reset-password">
                    <VendorResetPin></VendorResetPin>
                </LoginRoute>
                <PrivateVendorRoute path="/vendor/signout">
                    <VendorSignout />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/profile">
                    <VendorProvider>
                        <VendorProfile />
                    </VendorProvider>
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/change-password">
                    <VendorChangePassword />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/new-order">
                    <VendorNewOrder />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/load-order">
                    <VendorLoadOrder />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/transit-order">
                    <VendorTransitOrder />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/balance-order">
                    <VendorBalanceOrder />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/order-history">
                    <VendorOrderHistory />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/dashboard">
                    <VendorDashboard />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/employee">
                    <VendorEmployee />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/add-employee">
                    <VendorAddEmployee />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/update-employee/:employeeid">
                    <VendorUpdateEmployee />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/setting">
                    <VendorSetting />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/refferal-dashboard">
                    <VendorRefferal />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/my-video">
                    <VendorVideo />
                </PrivateVendorRoute>
                <PrivateVendorRoute path="/vendor/my-wallet">
                    <VendorWallet />
                </PrivateVendorRoute>
                <Route path="/referral">
                    <ReferralHandler />
                </Route>
                <Route path="/vendor/referral">
                    <VendorReferralHandler />
                </Route>
                <Route path="/customer-tc">
                    <CustomerTC />
                </Route>
                <Route path="/vendor-tc">
                    <VendorTC />
                </Route>
                <Route path="/privacy-policy">
                    <PrivacyPolicy />
                </Route>
                <Route path="/referral-benifit">
                    <RB />
                </Route>
                <Route path="/customer-faq">
                    <CFAQ />
                </Route>
                <Route path="/vendor-faq">
                    <VFAQ />
                </Route>
                <Route path="/ustaadnow-blog">
                    <Blog />
                </Route>
                <Route path="/refund-cancalition">
                    <RC />
                </Route>
                <Route path="/about-us">
                    <AboutUs />
                </Route>
                <Route path="/contact-us">
                    <ContactUs />
                </Route>
                <Route path="/categories">
                    <AllCategory />
                </Route>
                <Route path="/">
                    <Home homeData={homeData}></Home>
                </Route>
            </Switch>
            <Footer />
        </Router>
    );
}
// is vendor login
function PrivateVendorRoute({ children, ...rest }) {
    const [isAuth, setLoginUser] = useContext(LoginContext);
    let isAuthenticated = isAuth["isLogin"];
    let isVendor = isAuth["isVendor"];
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated && isVendor ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

// is user login
function PrivateUserRoute({ children, ...rest }) {
    const [isAuth, setLoginUser] = useContext(LoginContext);
    let isAuthenticated = isAuth["isLogin"];
    let isVendor = isAuth["isVendor"];
    const { pathname } = useLocation();
    localStorage.setItem("historypath", pathname);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                isAuthenticated && !isVendor ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

//only show when user not login
function LoginRoute({ children, ...rest }) {
    const [isAuth, setLoginUser] = useContext(LoginContext);
    let isAuthenticated = isAuth["isLogin"];
    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
export default Routes;
