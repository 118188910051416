import React from "react";
import { Link } from "react-router-dom";
const Message = (props) => {
    let info = JSON.parse(localStorage.getItem('message'))

    return (
        <div className="container container-ver2 mt-1">
            <div className="box float-left order-complete center space-50">
                <div className="icon space-20">
                    <img src="/images/icon-order-complete.png" alt="icon" />
                </div>
                <p className="box center space-50 mt-1">
                    {info["heading"]}
                </p>
                <p className="box center space-50 mt-1" style={{ fontSize: "24px" }}>
                    {info["des"]}
                </p>
                <div className="box mt-1">
                    <Link className="link-v1 lh-50 margin-right-20 space-20 market-bcolor" to={info["link"]} title="HOME PAGE">{info["linkname"]}</Link>
                </div>
            </div>
        </div >

    );

};

export default Message;
