import React from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import generalApi from '../../services/generalApi'
import { withRouter } from 'react-router-dom';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ReferralHandler(props) {
    const query = useQuery();
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            reference: query.get('referdevice'),
            mobile: query.get('mobile').trim(),
            customer: query.get('referdevice'),
            additionalinfo: 'C'
        }
        generalApi.getData('SSMUpdateReferrer', json).then(response => {
            localStorage.setItem('referralmobileno', query.get('mobile').trim())
            props.history.push('/signup');
        });
    }, []);
    useEffect(() => {
        console.log(
            'referraldevice, mobile, linktype, downloadtype',
            query.get('referdevice'),
            query.get('mobile'),
            query.get('linktype')
        );
    }, []);

    localStorage.setItem('referralmobileno', '+971303407966');
    return <React.Fragment></React.Fragment>;
}
export default withRouter(ReferralHandler);
