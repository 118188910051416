import React, { useState } from "react";
import generalApi from "../../services/generalApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-multi-lang";
const AppBanner = (props) => {
    const t = useTranslation();
    const [isactive, setActive] = useState(false);
    const [phone, setPhone] = useState("");
    let mobileprefix = props.mobileprefix;
    const changeHandler = (e) => {
        let mobile = e.target.value.slice(mobileprefix.length);
        setPhone(mobile);
    };
    const sendLink = (e) => {
        e.preventDefault();
        if (phone.length < 9) {
            toast.error(t('validation.phone'));
            return;
        }
        setActive(true);
        const json = { mobile: mobileprefix + phone };
        generalApi.getData("SSMSendSMS", json).then((response) => {
            if (response["data"]["statuscode"] == "000") {
                setActive(false);
                setPhone("");
                toast.success(response["data"]["statusdesc"]);
            } else {
                setActive(false);
                toast.error(response["data"]["statusdesc"]);
            }
        });
    };
    return (
        <div className="container container-ver2">
            <div className="content-box app__banner raashan-bcolor">
                <div className="px-3">
                    <h1 className="h1 text-white text-center mb-3">{t("home.free_download")}</h1>
                    <div className="d-flex justify-content-center mt-2">
                        <a className="app-icon" href="#">
                            {/* <span className='hide-visually'>iOS app</span> */}
                            <img
                                src="/images/app_store_badge.svg"
                                alt="Available on the App Store"
                            />
                        </a>
                        <a className="app-icon ml-3" href="#">
                            {/* <span className='hide-visually'>Android App</span> */}
                            <img
                                src="/images/google-play-badge.svg"
                                alt="Available on the App Store"
                            />
                        </a>
                    </div>
                    <form className="mt-2" onSubmit={sendLink}>
                        <div className="text-center mt-4">
                            <h4 className="text-white">{t("home.enter_mobile_rec")}</h4>
                            <div className="form-inline mb-2 justify-content-center align-items-center">
                                <div className="form-group download-link mx-sm-3 ">
                                    <input
                                        type="tel"
                                        required
                                        value={mobileprefix + phone}
                                        onChange={changeHandler}
                                        className="form-control"
                                        placeholder="971303******"
                                    />
                                    <button
                                        type="submit"
                                        disabled={isactive ? true : false}
                                        className="download-btn market-bcolor"
                                    >
                                        {isactive ? "Processing..." : t("home.sendlink")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AppBanner;
