import React from 'react'
import { useTranslation } from 'react-multi-lang'
function HomeInfo() {
    const t = useTranslation()
    return (
        <div className="container container-ver2 mbt-20">
            <div className="shipping-v2 home3-shiping home4-shipping space-30">
                <div className="col-md-4 col-sm-3 col-xs-6">
                    <img src="/images/icon-shipping-1.png" alt="images" />
                    <h3>{t('home.free_shipping')}</h3>
                    <p>{t('home.free_shipping_des')}</p>
                </div>
                <div className="col-md-4 col-sm-3 col-xs-6">
                    <img src="/images/icon-shipping-2.png" alt="images" />
                    <h3>{t('home.support')}</h3>
                    <p>{t('home.life_time')}</p>
                </div>
                {/* <div className="col-md-4 col-sm-3 col-xs-6">
                    <img src="/images/icon-shipping-3.png" alt="images" />
                    <h3>{t('home.help_partner')}</h3>
                    <p>{t('home.help_all')}</p>
                </div> */}
                <div className="col-md-4 col-sm-3 col-xs-6">
                    <img src="/images/icon-shipping-4.png" alt="images" />
                    <h3>{t('home.contact_ith_us')}</h3>
                    <p>+07 (0) 7782 9137</p>
                </div>
            </div>
        </div>
    )
}
export default HomeInfo