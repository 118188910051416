import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { LoginContext } from "./../../context/loginContext";
import { HomeContext } from "./../../context/homeContext";
import SidebarCheckout from "./../cart/sideBarCheckout";
import { useTranslation, setLanguage } from "react-multi-lang";
import UserNavbar from "./userNavbar";
import VendorNavbar from "./vendorNavbar";
import AlertModal from "../shared/alert-modal";
import TopNavbar from './TopNavbar'

function Header() {
    const t = useTranslation();
    const [isIconVisible, setIconVisible] = useState(true);
    const [isAuthDropDownOpen, toggleAuthDropDown] = useState(false);
    const [isAuth] = useContext(LoginContext);
    const [homedata] = useContext(HomeContext);
    const myRef = useRef();
    const [ismodelvisible, setModelVisible] = useState(
        homedata["showpopup"] === "1" ? true : false
    );
    const handleClickOutside = (e) => {
        if (!myRef.current.contains(e.target)) {
            toggleAuthDropDown(false);
        }
    };
    const navopen = () => {
        toggleAuthDropDown(!isAuthDropDownOpen);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        document.removeEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside, true);
    });
    const closemodel = (isvisible) => {
        setModelVisible(isvisible);
    };
    return (
        <header id="header" className="header-v1 header-v3 header-v4">

            <AlertModal
                popupimage={homedata["popupimage"]}
                popupmessage={homedata["popupmessage"]}
                popuptitle={homedata["popuptitle"]}
                isvisible={ismodelvisible}
                closemodel={closemodel}
            />

            <div id="topbar">
                <div className="container container-ver2">
                    <TopNavbar homedata={homedata} />
                    {isIconVisible && homedata["shownotify"] === "1" ? (
                        <div className="alert alert-danger">
                            <div className="close__icon-font">
                                <i
                                    className="fa fa-times"
                                    onClick={() => setIconVisible(false)}
                                ></i>
                            </div>
                            {homedata["notifymessage"]}
                        </div>
                    ) : null}

                    <div className="inner-topbar box main__nav">
                        <div className="">
                            <Link to="/" title="Uno">
                                <img
                                    className="logo__image3 hide__mobile"
                                    src="/images/new_logo.png"
                                    alt="Logo of Website"
                                />
                                <img
                                    className="mobile__logo show__mobile"
                                    src="/images/rashan-market-mobile.svg"
                                    alt="Logo of Website"
                                />
                            </Link>
                        </div>

                        <div className="navbar__items">
                            <SidebarCheckout />
                            <div
                                ref={myRef}
                                className={`dropdown auth__dropdown ${
                                    isAuthDropDownOpen ? "open" : ""
                                    }`}
                            >
                                <Link to="#" className="user__icon">
                                    <i
                                        className="fa fa-user icon market-color"
                                        onClick={navopen}
                                    />
                                </Link>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    {isAuth["isLogin"] && isAuth["isVendor"] ? (
                                        <VendorNavbar islogin={isAuth["isLogin"]}></VendorNavbar>
                                    ) : (
                                            <UserNavbar islogin={isAuth["isLogin"]}></UserNavbar>
                                        )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
export default Header;
