import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import generalApi from '../../services/generalApi'
import SimpleReactValidator from 'simple-react-validator'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HomeContext } from './../../context/homeContext'
import { T, withTranslation } from 'react-multi-lang'
import CurrentLocation from './../../component/shared/currentLocation'
class SignUp extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T
        }
        localStorage.removeItem('signupData');
        const { t } = this.props
        this.validator = new SimpleReactValidator({
            messages: {
                required: t('validation.required'),
                phone: t('validation.phone'),
                min: t('validation.pinmin'),
                max: t('validation.pinmax'),
                email: t('validation.email')
            }
        });
        this.state = {
            isactive: false,
            firstname: '',
            lastname: '',
            accepted: true,
            mobile: '',
            mobileerror: '',
            email: '',
            pin: '',
            geolocation: '',
            address: '',
            city: 'DXB',
            reference: '',
            locationError: ''
        }
    }
    static contextType = HomeContext;
    currentlocation = (location) => {
        console.log("currentlocation", location);
        this.setState({ ...this.state, geolocation: location["geolocation"], address: location["location"] })
    }
    handleChange = (e) => {
        if (e.target.name === 'mobile') {
            const [homeData] = this.context;
            let mobileprefix = homeData["mobileprefix"]
            let mobile = e.target.value.slice(mobileprefix.length)
            if (mobile.length > 9) {
                mobile = mobile.substring(0, 9);
            }
            const { t } = this.props;
            let error = '';
            if (mobile.length < 9) {
                error = t('validation.phone');
            }
            this.setState({ ...this.state, [e.target.name]: mobile, mobileerror: error })
        } else {
            this.setState({ ...this.state, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
        }

        console.log("Checked", e.target.checked);

    }
    submitForm = (e) => {
        e.preventDefault()
        if (this.validator.allValid() && this.state.mobileerror === '') {
            const { t } = this.props
            const [homeData] = this.context;
            let mobileprefix = homeData["mobileprefix"]
            this.setState({ isactive: true });
            let mobileno = mobileprefix + this.state.mobile
            this.setState({ mobile: mobileno })
            const json = { mobile: mobileno, email: this.state.email }
            generalApi.getData('SSMSendEnrolmentToken', json).then(response => {
                console.log('Response', response["data"]);
                if (response["data"]["statuscode"] === "000") {
                    this.setState({ reference: response["data"]["referencenumber"] })
                    localStorage.setItem('signupData', JSON.stringify(this.state));
                    this.props.history.push('register-otp')
                } else {
                    this.setState({ isactive: false });
                    toast.error(response["data"]["statusdesc"]);
                }

            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        const { t } = this.props
        const [homeData] = this.context;
        let mobileprefix = homeData["mobileprefix"]

        return (

            <div className="container container-ver2">
                <div className="page-login box space-50">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 sign-in space-30">
                            <h3>{t('signup.create_account')}</h3>
                            <form className="form-horizontal" onSubmit={this.submitForm}>
                                <div className="group box space-20">
                                    <label className="control-label" >{t('signup.first_name')}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-user input__icon"></i>
                                        <input className="form-control" type="text" name="firstname" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('first_name')} value={this.state.firstname} placeholder={t('signup.first_name')} />
                                    </div>
                                    <span className="text-danger">{this.validator.message('first_name', this.state.firstname, 'required|max:40')}</span>
                                </div>
                                <div className="group box space-20">
                                    <label className="control-label" >{t('signup.last_name')}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-user input__icon"></i>
                                        <input className="form-control" type="text" name="lastname" value={this.state.last_name} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('last_name')} placeholder={t('signup.last_name')} />
                                    </div>
                                    <span className="text-danger">{this.validator.message('last name', this.state.lastname, 'required|max:40')}</span>
                                </div>
                                <div className="group box space-20">
                                    <label className="control-label" >{t('signup.phone_no')}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-mobile input__icon"></i>
                                        <input className="form-control englisg-text" type="text" name="mobile" value={mobileprefix + this.state.mobile} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('phone_no')} placeholder={t('signup.phone_no')} />
                                    </div>
                                    <span className="text-danger">
                                        {this.state.mobileerror}
                                        {this.validator.message('phone_no', this.state.mobile, 'required')}
                                    </span>
                                </div>
                                <div className="group box space-20">
                                    <label className="control-label" >{t('signup.email')}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-envelope-o input__icon"></i>
                                        <input className="form-control englisg-text" type="email" name="email" value={this.state.email} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('email')} placeholder={t('signup.email')} />
                                    </div>
                                    <span className="text-danger">{this.validator.message('email', this.state.email, 'required|email|max:70')}</span>
                                </div>
                                <div className="group box">
                                    <label className="control-label" >{t('signup.pin')}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-lock input__icon"></i>
                                        <input className="form-control englisg-text" type="password" value={this.state.pin} name="pin" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('password')} placeholder={t('signup.pin')} />
                                    </div>
                                    <span className="text-danger">{this.validator.message('password', this.state.pin, 'required|min:4|max:4')}</span>
                                </div>
                                <div className="input-group group box">
                                    <CurrentLocation currentlocation={this.currentlocation}></CurrentLocation>
                                    <span className="text-danger">{this.validator.message('Location', this.state.geolocation, 'required')}</span>
                                </div>
                                <div className="mt-1">
                                    <button type="submit" disabled={this.state.isactive ? true : false} className="link-v1 rt full market-bcolor">
                                        {this.state.isactive ? t('signup.processing') : t('signup.signup')}
                                    </button>
                                </div>
                            </form>
                        </div>



                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(withTranslation(SignUp));