import React, { useState, useContext, useEffect, useRef } from "react";
import { LanguageContext } from "./../../context/languageContext";
import { useTranslation, setLanguage } from "react-multi-lang";
import ShareWebsite from './shareWebsite';
function TopNavbar({ homedata }) {
    const t = useTranslation();
    const myRef = useRef();
    const [language, setLanguageData] = useContext(LanguageContext);
    const [showflag, setShowFlag] = useState(false);
    const handleClickOutside = (e) => {
        if (!myRef.current.contains(e.target)) {
            setShowFlag(false);
        }
    };

    const handleClickInside = () => {
        setShowFlag(!showflag);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        document.removeEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside, true);
    });
    const changeLanguage = (languaget) => {
        localStorage.setItem("language", languaget);
        setLanguage(languaget);
        setLanguageData(languaget);
        if (languaget !== language) {
            window.location.reload();
        }
    };
    return (
        <div className="top__navbar">
            <div className="contact__info">
                <div className="phone">
                    <img src="/images/icon-phone-header.png.png" alt="" />
                    <span className="text">
                        {t("home.call_us")}{" "}
                        <a
                            className="phone__number"
                            href={`tel:${homedata["jcarecall"]}`}
                        >
                            {homedata["jcarecall"]}
                        </a>
                    </span>
                </div>

                <div className="phone email__address">
                    <img height="20" src="/images/envelope.png" alt="" />
                    <span className="text">
                        {t("home.email_us")}{" "}
                        <span className="phone__number">{homedata["jcareemail"]}</span>
                    </span>
                </div>

                <div className="social__icons">
                    <div className="social__item">
                        <a href="https://www.facebook.com/raashanmarket" target="_blank">
                            <i className="fa fa-facebook"></i>
                        </a>
                    </div>
                    <div className="social__item">
                        <a href="https://twitter.com/raashanmarket" target="_blank">
                            <i className="fa fa-twitter"></i>
                        </a>
                    </div>
                    <div className="social__item">
                        <a href="https://www.instagram.com/raashanmarket/">
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="language__currency">
                <div className="currency__symbol">
                    <span className="font-weight-bold title">
                        {t("header.currency")} &nbsp;
                                </span>
                    <span>AED</span>
                </div>

                <div ref={myRef} className="custom__dropdown open">
                    <button
                        className="dropdown__tigger"
                        type="button"
                        data-toggle="dropdown"
                        onClick={handleClickInside}
                    >
                        <span className="language__item">
                            <img
                                src={
                                    language === "english"
                                        ? "/images/usa_flag.jpg"
                                        : "/images/uae_flag.jpg"
                                }
                                alt=""
                            />
                            {language === "english" ? "EN" : "عربى"}
                        </span>
                        <span className="caret"></span>
                    </button>
                    <ul
                        className={`dropdown-menu language__dropdown ${
                            showflag ? "" : " d-none"
                            }`}
                    >
                        <li
                            className="language__item"
                            onClick={() => changeLanguage("english")}
                        >
                            <img src="/images/usa_flag.jpg" alt="" />
                                        EN
                                    </li>
                        <li
                            className="language__item"
                            onClick={() => changeLanguage("arabic")}
                        >
                            <img src="/images/uae_flag.jpg" alt="" />
                                        عربى
                                    </li>
                    </ul>

                </div>
                <ShareWebsite email={homedata["email"]} longtext={homedata["longtext"]} sharepagelink={homedata["sharepagelink"]} />
            </div>
        </div>
    )
}
export default TopNavbar