import React from "react";
import { Link } from "react-router-dom";
import { useTranslation, setLanguage } from "react-multi-lang";

function UserNavbar(props) {
    let islogin = props.islogin
    const t = useTranslation();
    return (
        <React.Fragment>
            {!islogin ?
                <li className="sub__menu">
                    <Link to="/login">
                        <i className="fa fa-sign-in"></i>
                        {t('header.login')}</Link>
                </li>
                :
                <React.Fragment>
                    <li className="sub__menu">
                        <Link to="/">
                            <i className="fa fa-list"></i>
                            {t('header.order')}</Link>
                        <ul className="dropdown__sub-menu">
                            <li>
                                <Link to="/current-order">
                                    <i className="fa fa-plus-square-o"></i>
                                    {t('header.current_order')}</Link>
                            </li>
                            <li>
                                <Link to="/order-history">
                                    <i className="fa fa-history"></i>
                                    {t('header.order_history')}</Link>
                            </li>
                        </ul>
                    </li>
                    <li className="sub__menu">
                        <Link to="/notification">
                            <i className="fa fa-bell"></i>
                            {t('header.notification')}</Link>
                    </li>
                    <li className="sub__menu">
                        <Link to="/my-wallet">
                            <i className="fa fa-google-wallet"></i>
                            {t('header.referfriend')}</Link>
                    </li>
                    <li className="sub__menu">
                        <Link to="/ref-dashboard">
                            <i className="fa fa-share"></i>
                            {t('header.referdashboard')}</Link>
                    </li>
                    <li className="sub__menu">
                        <Link to="/change-password">
                            <i className="fa fa-lock"></i>
                            {t('header.changepassword')}</Link>
                    </li>
                    <li className="sub__menu">
                        <Link to="/fav-list">
                            <i className="fa fa-heart"></i>
                            {t('header.mylist')}</Link>
                    </li>
                    <li className="sub__menu">
                        <Link to="/profile">
                            <i className="fa fa-user-md"></i>
                            {t('header.profile')}</Link>
                    </li>
                    <li className="sub__menu">
                        <Link to="/signout">
                            <i className="fa fa-sign-out"></i>
                            {t('header.logout')}
                        </Link>
                    </li>
                </React.Fragment>
            }

        </React.Fragment>
    );
}
export default UserNavbar;
