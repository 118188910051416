import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import generalApi from '../../../services/generalApi'
import VendorContext from "./../../../context/vendorSignupData";
import 'react-toastify/dist/ReactToastify.css';
import StepWizard from 'react-step-wizard';
import UserType from './profileStep/userType'
import Address from './profileStep/address'
import BankInfo from './profileStep/bankInfo'
import Document from './profileStep/document'
import OtpValidation from './profileStep/otpValidation'
import ProfessionalInfo from './profileStep/ProfessionalInfo'
import PromoterInfo from './profileStep/promoterForm'
import Loader from "./../../../component/shared/loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Profile() {
    const [vendorData] = useContext(VendorContext);
    const [userProfile, setProfile] = useState([]);
    const [loading, setLoading] = useState(true);
    let userData = JSON.parse(localStorage.getItem("user_data"))
    let userType = userData["servicecategory"] === "118" ? "promoter" : "professional"
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            certificate: userData["certificate"]
        }
        generalApi.getData('SSMgetpartnerprofile', json).then(response => {
            if (response["data"]["statuscode"] == "000") {
                console.log("setProfile", response["data"]);
                setProfile(response["data"])
                setLoading(false);
            } else {
                toast.error(response["data"]["statusdesc"]);
            }

        });
    }, [])
    if (loading) {
        return <Loader></Loader>
    }
    return (
        <div className="container container-ver2">
            <div className="row">
                <div className="col page-content align-self-center">
                    <div className="inner-box category-content">
                        <h2 className="title-2"><i className="icon-user-add"></i> Update your account </h2>
                        <StepWizard>
                            {userType == "professional" ?
                                <ProfessionalInfo userinfo={userProfile} hashKey="professionalinfo" />
                                : <PromoterInfo userinfo={userProfile} hashKey="promotorinfo" />}
                            <Address userinfo={userProfile} hashKey={'address'} />
                            {userType === "professional" ?
                                <Document userinfo={userProfile} hashKey="document" />
                                : null}
                            <BankInfo userinfo={userProfile} hashKey={'bankinfo'} />
                        </StepWizard>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default withRouter(Profile);