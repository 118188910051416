import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import generalApi from '../../../services/generalApi'
import SimpleReactValidator from 'simple-react-validator'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            isactive: false,
            pin: '',
            oldpin: '',
            newpin: '',
        }
    }

    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
        console.log("Checked", e.target.checked);

    }
    submitForm = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            let userdata = JSON.parse(localStorage.getItem("user_data"))
            const json = {
                pin: this.state.oldpin,
                employeeid: userdata["employeeid"],
            }
            generalApi.getData('SSMValidatePasswordVendor', json).then(response => {
                if (response["data"]["statuscode"] == "000") {
                    // Save new password
                    const json = {
                        idnumber: this.state.pin,
                        certificate: userdata["certificate"],
                        customer: "+" + userdata["mobilenumber"]
                    }
                    generalApi.getData('SSMSavePINVendor', json).then(response => {
                        if (response["data"]["statuscode"] == "000") {
                            let message = { heading: "Success!", des: "Successfully change the pin", link: "/", linkname: "Home Page" }
                            localStorage.setItem('message', JSON.stringify(message))
                            this.props.history.push('/message')
                        } else {
                            this.setState({ isactive: false });
                            toast.error(response["data"]["statusdesc"]);
                        }
                    });
                } else {
                    this.setState({ isactive: false });
                    toast.error(response["data"]["statusdesc"]);
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        return (

            <div className="container container-ver2">
                <div className="page-login box space-50">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 sign-in space-30">
                            <h3>Change Pin</h3>
                            <form className="form-horizontal" onSubmit={this.submitForm}>
                                <div className="group box space-20">
                                    <label className="control-label" >Old Pin</label>
                                    <input className="form-control" type="password" name="oldpin" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('oldpin')} value={this.state.oldpin} />
                                    <span className="text-danger">{this.validator.message('oldpin', this.state.pin, 'required')}</span>
                                </div>
                                <div className="group box space-20">
                                    <label className="control-label" >New Pin</label>
                                    <input className="form-control" type="password" name="pin" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('pin')} value={this.state.pin} />
                                    <span className="text-danger">{this.validator.message('pin', this.state.pin, 'required')}</span>
                                </div>
                                <div className="mt-1">
                                    <button type="submit" disabled={this.state.isactive ? true : false} className="link-v1 rt">
                                        {this.state.isactive ? 'PROCESSING' : 'Change Password'}
                                    </button>
                                </div>
                            </form>

                        </div>



                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(ChangePassword);