import React, { useEffect, useState } from "react";
import generalApi from "../../services/generalApi";
import { stripHtml } from "../../config/helper";
import Accordion from "../../component/shared/accordian";
import "./faqStyle.css";
import SubAccordion from "../../component/shared/sub-accordian";
import Loader from "./../../component/shared/loader";
import { useTranslation } from 'react-multi-lang'
function VendorFAQ() {
    const t = useTranslation()
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const json = { category: "P" }
        generalApi.getData('SSMfaqGroupList', json).then(response => {
            setData(response["data"]["faqlist"]);
            console.log(response["data"]);
            setLoading(false)
        });
    }, []);
    if (loading) {
        return <Loader></Loader>
    }
    return (
        <div className="container container-ver2 mb-5">
            <h1 className="text-center mt-2">{t('static.v_faq')} </h1>
            <div>
                {data.map((faq, i) => (
                    <Accordion heading={faq.groupnamename} key={i}>
                        <ul className="list-style-arrow">
                            {faq["groupQA"].map((s_faq, s_i) => (
                                <SubAccordion key={s_i} question={s_faq["question"]} answer={s_faq["answer"]} />
                            ))}
                        </ul>
                    </Accordion>
                ))}
            </div>
        </div>
    );
}
export default VendorFAQ;
