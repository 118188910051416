import React, { useState, useContext, useEffect } from "react";
import './../../assets/css/checkboxStyle.css'
import { OrderContext } from './../../context/orderContext'
import { useTranslation } from 'react-multi-lang'
function Checkout() {
    const t = useTranslation()
    // 1 CARD -> 2 Wallet -> 3 Cash
    const [paymentoption, setPaymentoption] = useState("CARD")
    const [order, setOrder] = useContext(OrderContext);
    const changeHandler = (e) => {
        setPaymentoption(e.target.value)
    }
    useEffect(() => {
        let tempOrder = order;
        tempOrder["paymentoption"] = paymentoption
        setOrder(tempOrder)
    }, [paymentoption])
    return (
        <React.Fragment>
            <div className="payment-order box float-left">
                <h3 className="title-brand text-center">{t('footer.payment_method')}</h3>
                <div>
                    <label className="paymentcontainer" >
                        <h4>{t('checkout.pay_by_credit')}</h4>
                        <p className="payment-paragrapch">{t('checkout.pay_by_credit_des')} </p>
                        <input type="radio" value="CARD" onChange={changeHandler} checked={paymentoption == "CARD" ? true : false} name="paymentoption" />
                        <span className="checkmark"></span>
                    </label>
                    <label className="paymentcontainer" >
                        <h4>{t('checkout.pay_by_wallet')}</h4>
                        <p className="payment-paragrapch">{t('checkout.pay_by_wallet_des')} </p>
                        <input type="radio" onChange={changeHandler} value="WALLET" checked={paymentoption == "WALLET" ? true : false} name="paymentoption" />
                        <span className="checkmark"></span>
                    </label>
                    <label className="paymentcontainer" >
                        <h4>{t('checkout.cash_on_delivery')}</h4>
                        <p className="payment-paragrapch">{t('checkout.cash_on_delivery_des')}</p>
                        <input type="radio" onChange={changeHandler} value="CASH" checked={paymentoption == "CASH" ? true : false} name="paymentoption" />
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>
        </React.Fragment>
    );
}
export default Checkout;
