import React, { useEffect, useState } from 'react'
import generalApi from '../../services/generalApi'
import { stripHtml } from '../../config/helper'
import Loader from "./../../component/shared/loader";
import { useTranslation } from 'react-multi-lang'
function PrivacyPolicy() {
    const t = useTranslation()
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const json = { reference: "121072019000006" }
        generalApi.getData('SSMGeneralContentDisplay', json).then(response => {
            setData(response["data"]);
            setLoading(false)
        });
    }, []);
    if (loading) {
        return <Loader></Loader>
    }
    return (
        <div className="container container-ver2">
            <h1 className="text-center mt-2">{t('static.privacy_policy')} </h1>
            <p className="text-justify tc-arabic" style={{ lineHeight: "25px" }} dangerouslySetInnerHTML={{ __html: data["longtext"] }}></p>
        </div>
    );
}
export default PrivacyPolicy;