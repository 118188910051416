import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import VendorContext from "./../../../../context/vendorSignupData";
import generalApi from '../../../../services/generalApi'
import { Multiselect } from 'multiselect-react-dropdown';
class ProfessionalInfo extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        let userInfo = props.userinfo
        this.state = {
            fullname: userInfo["companyname"],
            contactname: userInfo["contact"],
            yearformation: userInfo["yearofformation"],
            teamsize: userInfo["teamsize"],
            contactno: userInfo["mobile1"],
            contactno1: userInfo["mobile2"],
            email: userInfo["emailid"],
            companytype: userInfo["individualorcompany"],
            gender: parseInt(userInfo["gender"]),
            qualfication: userInfo["qualification"],
            services: [],
            selectedServiceList: userInfo["servicelist"],
            serviceList: []
        }
        this.serviceList()
    }
    static contextType = VendorContext;
    serviceList = () => {
        generalApi.getData("SSMServiceList", []).then(response => {
            let services = [];
            let selected_service = []
            console.log("s", response["data"]["servicelist"]);

            response["data"]["servicelist"].map((info, i) => (
                services[i] = { det: info["servicecode"], name: info["servicename"] }
            ))
            this.state.selectedServiceList.map((info, i) => (
                selected_service[i] = { det: info["servicecode"], name: info["serviceitem"] }
            ))
            this.setState({ serviceList: services, selectedServiceList: selected_service, services: selected_service })
        });
    }
    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value })
        console.log("selectedList", this.state.services);
    }
    onSelect = (selectedList, selectedItem) => {
        console.log("selectedList", selectedList);
        this.setState({ services: selectedList })
        console.log("selectedList2", this.state.services);
    }

    onRemove = (selectedList, removedItem) => {
        this.setState({ services: selectedList })
        console.log("selectedList2", this.state.services);
    }
    validateError = () => {
        console.log("showMessages", this.validator.getErrorMessages());

        if (this.validator.allValid()) {
            const [VendorData, setVendorData] = this.context
            let info = VendorData;
            info["basicInnfo"] = this.state;
            console.log("all basic info", info);
            setVendorData({ ...info })
            this.props.nextStep()
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const userType = "professional";
        return (

            <div className="userType">
                <h2>Basic Info</h2>
                <div className="row">
                    <div className="col-lg-4 form-group">
                        <label >Full Name</label>
                        <input name="fullname" type="text" value={this.state.fullname} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('fullname')} className="form-control" placeholder="Full name" />
                        <span className="text-danger">{this.validator.message('fullname', this.state.fullname, 'required')}</span>
                    </div>
                    {userType == "professional" ?
                        <React.Fragment>
                            <div className="col-lg-4 form-group">
                                <label >Contact Name</label>
                                <input name="contactname" type="text" value={this.state.contactname} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('contactname')} className="form-control" placeholder="Contact name" />
                                <span className="text-danger">{this.validator.message('contactname', this.state.contactname, 'required')}</span>
                            </div>
                            <div className="col-lg-4 form-group">
                                <label >Year Formation</label>
                                <input name="yearformation" type="number" value={this.state.yearformation} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('yearformation')} className="form-control" placeholder="Year Formation" />
                                <span className="text-danger">{this.validator.message('yearformation', this.state.yearformation, 'required')}</span>
                            </div>
                        </React.Fragment>
                        : null}
                </div>
                <div className="row">
                    {userType == "professional" ?
                        <div className="col-lg-4 form-group">
                            <label >Team Size</label>
                            <input name="teamsize" type="number" value={this.state.teamsize} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('teamsize')} className="form-control" placeholder="Team Size" />
                            <span className="text-danger">{this.validator.message('teamsize', this.state.teamsize, 'required')}</span>
                        </div>
                        : null}
                    <div className="col-lg-4 form-group">
                        <label >Contact No</label>
                        <input name="contactno" type="text" value={this.state.contactno} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('contactno')} className="form-control" placeholder="Contact No" />
                        <span className="text-danger">{this.validator.message('contactno', this.state.contactno, 'required|phone')}</span>
                    </div>
                    <div className="col-lg-4 form-group">
                        <label >Contact No</label>
                        <input name="contactno1" type="text" value={this.state.contactno1} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('contactno1')} className="form-control" placeholder="Contact No 2" />
                        <span className="text-danger">{this.validator.message('contactno1', this.state.contactno1, 'required|phone')}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 form-group">
                        <label >Email</label>
                        <input name="email" type="email" value={this.state.email} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('email')} className="form-control" placeholder="Email" />
                        <span className="text-danger">{this.validator.message('email', this.state.email, 'required|email')}</span>
                    </div>
                    {userType == "professional" ?
                        <div className="col-lg-4 form-group">
                            <label >Company Type</label>
                            <div className="d-inline mt-1">
                                <label className="control control--radio mb-0">
                                    Company
                                <input name="companytype" type="radio" checked={this.state.companytype == "company"} value="company" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('companytype')} className="form-check-input" />
                                    <div className="control__indicator"></div>
                                </label>
                                <label className="control control--radio mb-0 ml-1">
                                    Individual
                              <input name="companytype" type="radio" checked={this.state.companytype == "individual"} value="individual" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('companytype')} className="form-check-input" />
                                    <div className="control__indicator"></div>
                                </label>
                            </div>
                            <span className="text-danger">{this.validator.message('companytype', this.state.companytype, 'required')}</span>
                        </div>
                        : null}
                    <div className="col-lg-4 form-group">
                        <label >Gender</label>
                        <div className="d-inline mt-1">
                            <label className="control control--radio mb-0">
                                Male
                                <input name="gender" type="radio" checked={this.state.gender == 1} value={1} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('gender')} className="form-check-input" />
                                <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio mb-0 ml-1">
                                Fmale
                                <input name="gender" type="radio" checked={this.state.gender == 2} value={2} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('gender')} className="form-check-input" />
                                <div className="control__indicator"></div>
                            </label>
                            <label className="control control--radio mb-0 ml-1">
                                N/A
                                <input name="gender" type="radio" checked={this.state.gender == 3} value={3} onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('gender')} className="form-check-input" />
                                <div className="control__indicator"></div>
                            </label>
                        </div>
                        <span className="text-danger">{this.validator.message('gender', this.state.gender, 'required')}</span>
                    </div>
                </div>
                {userType == "professional" ?
                    <div className="row">
                        <div className="col-lg-4 form-group">
                            <label >Qualification</label>
                            <select name="qualfication" className="form-control" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('qualfication')}>
                                {/* <option selected disabled value="null">Select Qualification</option> */}
                                <option value="M" selected={this.state.qualfication == 'M' ? true : false}>Master's Degree</option>
                                <option value="E" selected={this.state.qualfication == 'E' ? true : false}>Engineering</option>
                                <option value="G" selected={this.state.qualfication == 'G' ? true : false}>Graduate</option>
                                <option value="D" selected={this.state.qualfication == 'D' ? true : false}>Diploma</option>
                                <option value="H" selected={this.state.qualfication == 'H' ? true : false}>Higher Secondary</option>
                                <option value="P" selected={this.state.qualfication == 'P' ? true : false}>Primary School</option>
                            </select>
                            <span className="text-danger">{this.validator.message('qualfication', this.state.qualfication, 'required')}</span>
                        </div>
                        <div className="col-lg-4 form-group">
                            <label >Services</label>
                            <Multiselect
                                options={this.state.serviceList} // Options to display in the dropdown
                                selectedValues={this.state.selectedServiceList} // Preselected value to persist in dropdown
                                onSelect={this.onSelect} // Function will trigger on select event
                                onRemove={this.onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                            />

                            <span className="text-danger">{this.validator.message('services', this.state.services, 'required')}</span>
                        </div>
                    </div>
                    : null}
                <div className="row mt-2">
                    <div className="col-lg-6">
                        <p><button className="btn btn-info " onClick={this.props.previousStep}>Previous Step</button></p>
                    </div>
                    <div className="col-lg-6">
                        <p className="text-right"><button className="btn btn-primary" onClick={() => { this.validateError() }}>Next Step</button></p>
                    </div>
                </div>
            </div >
        )
    }
}

export default ProfessionalInfo;