import React, { useState } from "react";
import useToggle from "./../../hook/toggle";

const SubAccordion = ({ question, answer }) => {
  const { isOpen, handleClick } = useToggle();

  return (
    <div className="sub-question">
      <h6 onClick={handleClick} className={isOpen ? "active" : ""}>
        {question}
      </h6>
      <p className={isOpen ? "show" : ""}>{answer}</p>
    </div>
  );
};

export default SubAccordion;
