import React, { useState } from "react";
function ProductImage(props) {
    const [loaded, setLoaded] = useState(false)
    return (
        <div>
            {!loaded ? (
                <svg width={props.width} height={props.height}>
                    <rect width={props.width} height={props.height} fill="#CCC" />
                </svg>
            ) : null}
            <img
                src={props.src}
                alt="Dummy Image"
                className={props.classname}
                style={!loaded ? { display: 'none' } : {}}
                onLoad={() => setLoaded(true)}
            />
        </div>
    );
}
export default ProductImage;
