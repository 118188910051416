import React, { useState, createContext } from "react";
import { getLanguage } from 'react-multi-lang'
// Create Context of Booking
export const LanguageContext = createContext();

// Create Provider that hold the sharable information
export const LanguageProvider = props => {
    const [languageData, setLanguageData] = useState(getLanguage());
    return (
        <LanguageContext.Provider value={[languageData, setLanguageData]}>
            {props.children}
        </LanguageContext.Provider>
    );
};
