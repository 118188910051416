import React, { useEffect, useState } from "react";
import generalApi from "../../services/generalApi";
import TopImage from "./../../component/product/topImage";
import Loader from "./../../component/shared/loader";
import OrderList from "./../../component/order/orderList";
import OrderBtn from "./../../component/order/orderBtn";
import NoRecord from "./../../component/shared/norecord";
import { useTranslation } from "react-multi-lang";
function CurrentOrder() {
    const t = useTranslation();
    const [loading, setLoading] = useState(true);
    const [orderData, setOrderData] = useState([]);
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem("user_data"));
        const json = {
            customer: userData["customerid"],
            certificate: userData["certificate"],
        };
        generalApi.getData("SSMBookingTimeline", json).then((response) => {
            setOrderData(response["data"]);
            setLoading(false);
        });
    }, []);
    if (loading) {
        return <Loader></Loader>;
    }
    return (
        <React.Fragment>
            <div className="cart-box-container">
                <div className="container container-ver2">
                    <TopImage text={t("order.currentorder")} />
                    <div className="box cart-container mt-1">
                        <div className="order__list">
                            <h3 className="text-center">{t("order.currentorder")}</h3>
                            {orderData["timeline"].length > 0 ? (
                                orderData["timeline"].map((info) => (
                                    <div className="order__item" key={info["orderid"]}>
                                        <div className="row arabic__row">
                                            <div className="col-md-8">
                                                <OrderList orderinfo={info}></OrderList>
                                            </div>
                                            <div className="col-md-4">
                                                <OrderBtn
                                                    orderinfo={info}
                                                    key={info["orderid"]}
                                                ></OrderBtn>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <>
                                    <NoRecord />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default CurrentOrder;
