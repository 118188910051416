import React, { useState } from "react";
import { useTranslation } from "react-multi-lang";
function Blog() {
    const [iframeHight] = useState("338vh");
    const t = useTranslation();
    return (
        <div className="container container-ver2">
            <h1 className="text-center mt-2">{t("static.blog")} </h1>
            <iframe
                title="Ok"
                scrolling="no"
                style={{ width: "100%", height: iframeHight }}
                src="https://ustaadnowservicemarketplace.blogspot.com/"
            ></iframe>
        </div>
    );
}
export default Blog;
