import React from "react";
import { Link } from 'react-router-dom';
function ShortInfo(props) {
    return (
        <div className="order-column">
            <Link to={props.link} className="card">
                <div className="card-body text-center">
                    <i className="iconsminds-clock"></i>
                    <p className="font-weight-bold f-17 mb-0">{props.heading}</p>
                    <p className="lead text-center">{props.totalorder}</p>
                </div>
            </Link>
        </div>
    )
}
export default ShortInfo