import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useTranslation } from 'react-multi-lang'
const ItemDetailsModal = ({ isOpen, closeModal, orderinfo }) => {
    let Redirect = useHistory();
    const t = useTranslation()
    return (
        <div
            id="item-details"
            className={`modal modal__custom ${isOpen ? "show" : "fade"}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="item-title"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="item-title">
                            {t('order.itemdetails')}
                        </h5>
                        <button
                            onClick={closeModal}
                            className="close close__btn"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="table-responsive">
                            <table className="table table-light">
                                <thead className="thead-light">
                                    <tr>
                                        <th>{t('order.itemcode')}</th>
                                        <th>{t('order.description')}</th>
                                        <th>{t('order.unit')}</th>
                                        <th>{t('order.actualqty')}</th>
                                        <th>{t('order.deliveryqty')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderinfo["itemdetail"].map((info) => (
                                        <tr key={info["itemcode"]}>
                                            <td>{info["itemcode"]}</td>
                                            <td>{info["itemname"]}</td>
                                            <td>{info["itemunit"]}</td>
                                            <td>{info["quantity"]}</td>
                                            <td>{parseInt(info["deliverqty"])}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-info btn__custom-modal raashan-bcolor" onClick={closeModal}>{t('order.close')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ItemDetailsModal);
