import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import generalApi from '../services/generalApi'
import SimpleReactValidator from 'simple-react-validator'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { T, withTranslation } from 'react-multi-lang'
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        props = {
            t: T
        }
        const { t } = this.props
        this.validator = new SimpleReactValidator(
            {
                messages: {
                    required: t('validation.required'),
                    phone: t('validation.phone'),
                    min: t('validation.pinmin'),
                    max: t('validation.pinmax')
                }
            }
        );
        this.state = {
            isactive: false,
            pin: '',
            oldpin: '',
            newpin: '',
        }
    }

    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
        console.log("Checked", e.target.checked);

    }
    submitForm = (e) => {
        e.preventDefault()
        if (this.validator.allValid()) {
            this.setState({ isactive: true });
            let userdata = JSON.parse(localStorage.getItem("user_data"))
            const json = {
                pin: this.state.oldpin,
                certificate: userdata["certificate"],
                customer: userdata["customerid"],
            }
            // Valaidate PAssword
            generalApi.getData('SSMvalidatePassword', json).then(response => {
                if (response["data"]["statuscode"] == "000") {
                    // Save new password
                    const json = {
                        idnumber: this.state.pin,
                        certificate: userdata["certificate"],
                        customer: "+" + userdata["mobilenumber"]
                    }
                    generalApi.getData('SSMsavePIN', json).then(response => {
                        if (response["data"]["statuscode"] == "000") {
                            const { t } = this.props
                            let message = { heading: t('message.success'), des: t('message.changepassmessage'), link: "/", linkname: t('message.home') }
                            localStorage.setItem('message', JSON.stringify(message))
                            this.props.history.push('/message')
                        } else {
                            this.setState({ isactive: false });
                            toast.error(response["data"]["statusdesc"]);
                        }
                    });
                } else {
                    this.setState({ isactive: false });
                    toast.error(response["data"]["statusdesc"]);
                }
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render() {
        const { t } = this.props
        return (

            <div className="container container-ver2">
                <div className="page-login box space-50">
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3 sign-in space-30">
                            <h3>{t('login.changepassword')}</h3>
                            <form className="form-horizontal" onSubmit={this.submitForm}>
                                <div className="group box space-20">
                                    <label className="control-label" >{t('login.oldpin')}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-lock input__icon"></i>
                                        <input className="form-control englisg-text" placeholder="...." type="password" name="oldpin" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('pin')} value={this.state.oldpin} />
                                    </div>
                                    <span className="text-danger">{this.validator.message('oldpin', this.state.oldpin, 'required')}</span>
                                </div>
                                <div className="group box space-20">
                                    <label className="control-label" >{t('login.newpin')}</label>
                                    <div className="icon__input">
                                        <i className="fa fa-lock input__icon"></i>
                                        <input className="form-control englisg-text" placeholder="...." type="password" name="pin" onChange={this.handleChange} onBlur={() => this.validator.showMessageFor('pin')} value={this.state.pin} />
                                    </div>
                                    <span className="text-danger">{this.validator.message('pin', this.state.pin, 'required')}</span>
                                </div>
                                <div className="mt-1">
                                    <button type="submit" disabled={this.state.isactive ? true : false} className="link-v1 rt full market-bcolor">
                                        {this.state.isactive ? t('login.processing') : t('login.changepassword')}
                                    </button>
                                </div>
                            </form>

                        </div>



                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(withTranslation(ChangePassword));