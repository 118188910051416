import React from "react";
function NoRecord() {

    return (
        <div className="text-center not-found">
            <img src="/images/not-found.jpg" alt="Not Found" />
        </div>
    );
}
export default NoRecord;
