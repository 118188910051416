import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
export const PageLoader = (
    <div
        style={{ height: '100vh' }}
        className='text-center w-100   d-flex justify-content-center align-items-center'
    >
        <Loader type='TailSpin' color='#0c517f' height={100} width={100} />
    </div>
);

const Loading = () => {
    return (<div style={{ height: "100vh" }} className="text-center w-100   d-flex justify-content-center align-items-center">
        <Loader type="TailSpin" color="#0c517f" height={100} width={100} />
    </div>)
}
export default Loading