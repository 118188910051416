import React, { useContext } from "react";
import { CartContext } from "./../../context/cartContext";
import { Link, withRouter, Redirect } from "react-router-dom";
import { HomeContext } from "./../../context/homeContext";
import { priceinfo, setnumber } from "./../../config/helper";
import SidebarProduct from "./sideProduct";
import { useState } from "react";
import { useTranslation } from "react-multi-lang";
function SidebarCheckout(props) {
    const t = useTranslation();
    const [cartData] = useContext(CartContext);
    const [homeData] = useContext(HomeContext);
    const [isSearchActive, toggleSearch] = useState(true);
    const [searchText, setSearchText] = useState("");
    let currencySymbol = homeData["currencysymbol"];
    let price = priceinfo(cartData);
    const changeHandler = (e) => {
        setSearchText(e.target.value);
    };
    const searchProduct = () => {
        props.history.push({
            pathname: "/product-list/",
            search: "?query=searchText",
            state: { Stext: searchText },
        });
        console.log("searchText", searchText);
    };

    return (
        <div className="navbar__items">
            <div
                className="search navbar__search dropdown active"
                data-toggle="modal"
                data-target=".bs-example-modal-lg"
            >
                <div className="search__input">
                    <div className="search__box">
                        <input
                            className="search__input-field"
                            value={searchText}
                            onChange={changeHandler}
                            type="search"
                        />
                        <button className="search__btn market-bcolor" onClick={searchProduct}>
                            <i className="icon"></i>
                        </button>
                    </div>
                    {/* has-items: add this class to show dropdown */}
                    <ul className="search__dropdown">
                        <li>
                            <Link to="/">
                                <div className="search__item">
                                    <img src="/images/brand-1.png" alt="Search Item" />
                                    <div className="item__details">
                                        <h3 className="name">Item Name</h3>
                                        <div className="item__quantity">
                                            <span className="action__icon fa fa-plus"></span>
                                            <input
                                                value="0"
                                                readOnly
                                                className="quantity__input"
                                                type="number"
                                            />
                                            <span className="action__icon fa fa-minus"></span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
                <i className="icon search__icon" onClick={() => toggleSearch(true)}></i>
            </div>
            <div className="cart hover-menu">
                <p className="" title="Add to cart">
                    <i className="icon shopping__icon fa fa-shopping-cart market-color"></i>
                    <span className="cart-count">{cartData.length}</span>
                </p>

                {cartData.length > 0 ? (
                    <div className="cart-list list-menu">
                        <div className="shopping-cart-btn">
                            <Link
                                to="/shopping-cart"
                                className="link-v1 market-bcolor box lh-50 rt full"
                                title="viewcart"
                            >
                                {t("shoppingcart.view_cart")}
                            </Link>
                        </div>
                        <ul className="list">
                            {cartData.map((info) => (
                                <SidebarProduct
                                    currencySymbol={currencySymbol}
                                    cartinfo={info}
                                    key={info["itemcode"]}
                                />
                            ))}
                        </ul>
                        <p className="total">
                            <span className="left">{t("shoppingcart.total")}</span>{" "}
                            <span className="right">
                                {currencySymbol} {setnumber(price["totalprice"])}
                            </span>
                        </p>
                        <div className="shopping-cart-btn">
                            <Link
                                to="/shopping-cart"
                                className="link-v1 market-bcolor box lh-50 rt full"
                                title="viewcart"
                            >
                                {t("shoppingcart.view_cart")}
                            </Link>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
export default withRouter(SidebarCheckout);
