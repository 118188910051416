import React, { useState, useEffect } from "react";
import generalApi from "../../services/generalApi";
import Loader from "../../component/shared/loader";
function MyVideo() {
    const [loading, setLoading] = useState(true);
    const [myVideo, setVideo] = useState([]);
    useEffect(() => {
        const json = {
            reference: 2,
        };
        generalApi.getData("SSMYouTubeList", json).then((response) => {
            console.log("setVideo", response["data"]);
            setVideo(response["data"]);
            setLoading(false);
        });
    }, []);
    if (loading) {
        return <Loader></Loader>;
    }
    return (
        <div className="cart-box-container">
            <div className="container container-ver2"></div>
            <div className="row">
                {myVideo["youtubelist"].length > 0 ? (
                    myVideo["youtubelist"].map((info) => (
                        <div className="col-12 col-sm-6 col-md-4 mt-2">
                            <div className="card">
                                <iframe
                                    title={info["playid"]}
                                    className="card-img-top"
                                    src={`https://www.youtube.com/embed/${info["playid"]}`}
                                ></iframe>
                                <div className="card-body">
                                    <p className="card-text">{info["description"]}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-12 text-center">
                        <h1>No Video Found</h1>
                    </div>
                )}
            </div>
        </div>
    );
}
export default MyVideo;
