import React, { useState } from "react";
import generalApi from "../../services/generalApi";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
function Footer() {
    const t = useTranslation();
    const [isactive, setActive] = useState(false);
    const [email, setEmail] = useState("");
    const changeHandler = (e) => {
        setEmail(e.target.value);
    };
    const subscribeNews = (e) => {
        e.preventDefault();
        setActive(true);
        const json = { email: email };
        generalApi.getData("SSMNewsletterSubscription", json).then((response) => {
            if (response["data"]["statuscode"] == "000") {
                setActive(false);
                setEmail("");
                toast.success(response["data"]["statusdesc"]);
            } else {
                setActive(false);
                toast.error(response["data"]["statusdesc"]);
            }
        });
    };
    return (
        <footer id="footer" className="footer-v3 align-left raashan-bcolor">
            <div className="container container-ver2">
                <div className="footer-inner">
                    <div className="row">
                        <div className="col-md-2 col-sm-6 col-xs-6">
                            <h3 className="title-footer">{t("footer.faq")}</h3>
                            <ul className="list-footer">
                                <li>
                                    <Link to="/customer-faq" title="title">
                                        {t("footer.customer")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/vendor-faq" title="title">
                                        {t("footer.vendor")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2 col-sm-6 col-xs-6">
                            <h3 className="title-footer">{t("footer.tc")}</h3>
                            <ul className="list-footer">
                                <li>
                                    <Link to="/customer-tc" title="title">
                                        {t("footer.customer")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/vendor-tc" title="title">
                                        {t("footer.vendor")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2 col-sm-6 col-xs-6 ">
                            <h3 className="title-footer">{t("footer.referral_benifit")}</h3>
                            <ul className="list-footer">
                                <li>
                                    <Link to="/referral-benifit" title="title">
                                        {t("footer.referral_benifit_small")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2 col-sm-6 col-xs-6">
                            <h3 className="title-footer">{t("footer.account")}</h3>
                            <ul className="list-footer">
                                <li>
                                    <Link to="/refund-cancalition" title="title">
                                        {t("footer.refund")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="ustaadnow-blog" title="title">
                                        {t("footer.blog")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy" title="title">
                                        {t("footer.privacy")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact-us" title="title">
                                        {t("footer.contact_us")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about-us" title="title">
                                        {t("footer.about_us")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <h3 className="title-footer">{t("footer.mobile_apps")}</h3>
                            <div className="row arabic__row">
                                <div className="col-lg-6 col-xs-6">
                                    <Link className="app-icon" to="#">
                                        <img
                                            src="/images/app_store_badge.svg"
                                            alt="Available on the App Store"
                                        />
                                    </Link>
                                </div>
                                <div className="col-lg-6 col-xs-6">
                                    <Link className="app-icon" to="#">
                                        <img
                                            src="/images/google-play-badge.svg"
                                            alt="Available on the App Store"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <h3 className="title-footer">{t("footer.payment_method")}</h3>
                            <div className=" text-center paymanet-method-logo">
                                <img src="/images/payment/master_card.png" alt="img" />
                                <img alt="img" src="/images/payment/visa_card.png" />
                                <img alt="img" src="/images/payment/cash-and-carrry.png" />
                            </div>
                            {/* <Link to="#" title="paypal"><img src="/images/paypal-footer.png" alt="images" /></Link> */}
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <h3 className="title-footer">{t("footer.get_newslater")}</h3>
                            {/* <p>{t('footer.newslaterdes')}</p> */}
                            <div className="subscribe">
                                <form onSubmit={subscribeNews}>
                                    <input
                                        required
                                        type="email"
                                        value={email}
                                        onChange={changeHandler}
                                        placeholder={t("footer.email_address")}
                                        className="input-text required-entry validate-email englisg-text"
                                        title="Sign up for our newsletter"
                                        id="newsletter"
                                        name="email"
                                    />
                                    <button
                                        disabled={isactive ? true : false}
                                        className="button button1 hover-white market-bcolor"
                                        title="Subscribe"
                                        type="submit"
                                    >
                                        {isactive ? t("login.processing") : t("footer.subscribe")}
                                        {/* <i className="fa fa-long-arrow-right"></i> */}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom box">
                <div className="container container-ver2">
                    <div className="box bottom">
                        <p className="text-center text-white">{t("footer.right_reserved")}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer;
