import React from "react";
function OrderList(props) {
    let orderinfo = props.orderinfo
    console.log("orderinfo", orderinfo);

    return (
        <React.Fragment>
            <div className="content">
                <p className="content__item">
                    <span className="font-weight-bold">Order Id:</span>
                    <span>{orderinfo["orderid"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">
                        Order Date:
                                                </span>
                    <span>{orderinfo["logdatetime"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">
                        Delivery Date:
                                                </span>
                    <span>{orderinfo["deliverydate"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">Payment:</span>
                    <span>{orderinfo["paymenttype"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">
                        No. of Items:
                    </span>
                    <span>{orderinfo["numberofitems"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">
                        Supplier Code:
                                                </span>
                    <span>{orderinfo["vendorcode"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">
                        Delivery Vehicle Code:
                                                </span>
                    <span>{orderinfo["vehiclename"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">
                        Delivery Address:
                                                </span>
                    <span>{orderinfo["address"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">
                        Customer Contact:
                                                </span>
                    <span>{orderinfo["mobilenumber"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">
                        Lead Time to Delivery:
                                                </span>
                    <span>{orderinfo["deliveryleadtime"]}</span>
                </p>

                <p className="content__item">
                    <span className="font-weight-bold">Status:</span>
                    <span>{orderinfo["orderstatus"]}</span>
                </p>
            </div>

        </React.Fragment>
    );
}
export default OrderList;
