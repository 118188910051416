import React, { useState } from "react";
import { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import generalApi from '../../../services/generalApi'
import ItemList from './itemList'
import { toast } from 'react-toastify';
const ItemDetailsModal = ({ isOpen, closeModal, orderinfo, vichlelist, neworder, transitorder }) => {
    let Redirect = useHistory();
    const [employeeid, setEmployee] = useState(vichlelist[0]["employeecode"]);
    // 1 => Paritial , 2->Full    
    let deliverytypetemp = 2
    if (orderinfo["deliverystatus"] !== '') {
        deliverytypetemp = orderinfo["deliverystatus"] === 'F' ? 2 : 1
    }
    const [deliveryStatus, setDeliveryStatus] = useState(deliverytypetemp);
    let tempitems = []
    const [itemlist, setItemList] = useState([]);
    const [isactive, setActive] = useState(false);
    useEffect(() => {
        orderinfo["itemdetail"].forEach(element => {
            element["deliverquantity"] = parseInt(element["deliverqty"])
            tempitems.push(element)
        });
        setItemList(tempitems)
        if (isOpen) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
    }, []);
    const itemchange = (iteminfo) => {

        let temparray = itemlist;
        let objIndex = temparray.findIndex((obj => obj.itemcode == iteminfo.itemcode));
        temparray[objIndex].deliverquantity = iteminfo["deliverquantity"]
        isfulldeliver(temparray)
        setItemList(temparray)
    }

    const isfulldeliver = (items) => {
        let isfull = 1;
        items.forEach(element => {
            if (parseInt(element["quantity"]) > parseInt(element["deliverquantity"])) {
                isfull = 0;
            }
        });
        setDeliveryStatus(isfull === 0 ? 1 : 2)
    }

    const changeEmployee = (e) => {
        setEmployee(e.target.value)
    }
    const validateorder = () => {
        let isvalidate = 0
        itemlist.forEach(element => {
            if (parseInt(element["deliverquantity"]) > 0) {
                isvalidate = 1;
            }
        });
        if (isvalidate === 1) {
            saveorder();
        } else {
            toast.error("Deliver Quantity must be greater then zero")
        }

    }
    const orderdeliveredhh = (ordertype) => {
        setActive(true);
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            certificate: userData["certificate"],
            orderid: orderinfo["orderid"],
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            employeeaccount: employeeid,
            fbtoken: "0000",
            reference: ordertype
        }
        generalApi.getData('SSMCompleteDelivery', json).then(response => {
            if (response["data"]["statuscode"] == "000") {
                let message = { heading: "Success!", des: `Order # ${orderinfo["orderid"]} successfully Saved`, link: "/vendor/load-order", linkname: "Load Order" }
                localStorage.setItem('message', JSON.stringify(message))
                Redirect.push('/message')
            } else {
                setActive(false)
                toast.error(response["data"]["statusdesc"])
            }
        });
    }
    const saveorder = () => {
        setActive(true);
        let multiorderdata = [];
        let userData = JSON.parse(localStorage.getItem("user_data"))
        for (let index = 0; index < itemlist.length; index++) {
            //
            let d_element = {};
            d_element["itmordcod"] = orderinfo["orderid"];
            d_element["itmmltcod"] = itemlist[index]["itemcode"];
            d_element["itmmltnam"] = itemlist[index]["itemname"];
            d_element["itmmltprc"] = itemlist[index]["standardprice"];;
            d_element["itmmltamt"] = itemlist[index]["totalamount"];;
            d_element["itmmltqty"] = itemlist[index]["quantity"];
            d_element["itmmltunt"] = itemlist[index]["itemunit"];;
            d_element["itmmltnet"] = itemlist[index]["offerprice"];;
            d_element["itmtaxpct"] = itemlist[index]["taxpercent"];
            d_element["itmactqty"] = itemlist[index]["deliverquantity"];
            multiorderdata.push(d_element);
        }
        const json = {
            certificate: userData["certificate"],
            orderid: orderinfo["orderid"],
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            multiorderdata: multiorderdata,
            employeeaccount: employeeid,
            fbtoken: "0000",
            reference: deliveryStatus === 2 ? 'F' : 'P'
        }
        generalApi.getData('SSMPrepareServiceOrder', json).then(response => {
            if (response["data"]["statuscode"] == "000") {
                let message = { heading: "Success!", des: `Order # ${orderinfo["orderid"]} successfully Saved`, link: "/vendor/new-order", linkname: "New Order" }
                localStorage.setItem('message', JSON.stringify(message))
                Redirect.push('/message')
            } else {
                setActive(false)
                toast.error(response["data"]["statusdesc"])
            }
        });
    }
    return (
        <div
            id="item-details"
            className={`modal modal__custom ${isOpen ? "show" : "fade"}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="item-title"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="item-title">
                            Item Details
                        </h5>
                        <button
                            onClick={closeModal}
                            className="close close__btn"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="modal__container mb__custom-3">
                            <div className="row">
                                <div className="col-md-5">
                                    <p className="content__item">
                                        <span className="font-weight-bold">Order Id:</span>
                                        <span>{orderinfo["orderid"]}</span>
                                    </p>

                                    <p className="content__item">
                                        <span className="font-weight-bold">Order Date:</span>
                                        <span>{orderinfo["logdatetime"]}</span>
                                    </p>

                                    <p className="content__item">
                                        <span className="font-weight-bold">Delivery Date:</span>
                                        <span>{orderinfo["deliverydate"]}</span>
                                    </p>

                                    <p className="content__item">
                                        <span className="font-weight-bold">Payment:</span>
                                        <span>{orderinfo["paymenttype"]}</span>
                                    </p>
                                </div>

                                <div className="col-md-3">
                                    <div className="checkbox">
                                        <label>
                                            <input readOnly checked={deliveryStatus === 2 ? true : false} type="checkbox" />
                                            Full Delivered
                                        </label>
                                    </div>

                                    <div className="checkbox">
                                        <label>
                                            <input readOnly checked={deliveryStatus === 1 ? true : false} type="checkbox" />
                                            Partial Delivered
                                        </label>
                                    </div>
                                </div>
                                {neworder === 1 ?
                                    <div className="col-md-4">
                                        <div className="order__action-btn">
                                            <button disabled={isactive ? true : false} onClick={validateorder} className="btn mt-1 btn-order__custom btn-sm btn-warning rashan-bcolor">
                                                {isactive ? "Processing..." : "Save"}
                                            </button>
                                            <button onClick={closeModal} className="btn mt-1 btn-order__custom btn-sm btn-warning market-bcolor">
                                                Close
                                        </button>
                                        </div>
                                    </div>
                                    : null}
                                {transitorder === 1 ?
                                    <div className="col-md-4">
                                        <div className="order__action-btn">
                                            {deliveryStatus === 2 ?
                                                <button disabled={isactive ? true : false} onClick={() => orderdeliveredhh('F')} className="btn mt-1 btn-order__custom btn-sm btn-danger">
                                                    {isactive ? "Processing..." : "Full Delivered"}
                                                </button>
                                                : null}
                                            {deliveryStatus === 1 ?
                                                <button disabled={isactive ? true : false} onClick={() => orderdeliveredhh('P')} className="btn mt-1 btn-order__custom btn-sm btn-warning">
                                                    {isactive ? "Processing..." : "Partial Delivered"}
                                                </button>
                                                : null}
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <div className="employee">
                            <select className="form-control" defaultValue={employeeid} onChange={changeEmployee}>
                                {vichlelist.map((vic) => (
                                    <option key={vic["employeecode"]} value={vic["employeecode"]}>{vic["employeename"]}</option>
                                ))}
                            </select>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-light">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Item Code</th>
                                        <th>Description</th>
                                        <th>Unit</th>
                                        <th>Actual Qty.</th>
                                        <th>Delivered Qty.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderinfo["itemdetail"].map((info) => (
                                        <ItemList neworder={neworder} itemchange={itemchange} key={info["itemcode"]} iteminfo={info}></ItemList>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary btn__custom-modal market-bcolor" onClick={closeModal}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ItemDetailsModal);
