import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/style.css";
import "./assets/css/customStyle.css";
import "./assets/css/arabic.css";
import { toast } from "react-toastify";
import { HomeProvider } from "./context/homeContext";
import { CartProvider } from "./context/cartContext";
import { LanguageProvider } from "./context/languageContext";
import App from "./App";
import { setDefaultLanguage, setDefaultTranslations } from "react-multi-lang";
import english from "./translation/english.json";
import arabic from "./translation/arabic.json";
setDefaultTranslations({ arabic, english });

let language = localStorage.getItem("language") ? localStorage.getItem("language") : "english";
setDefaultLanguage(language);

// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//         .register("./firebase-messaging-sw.js")
//         .then(function (registration) {
//             console.log("Registration successful, scope is:", registration.scope);
//         })
//         .catch(function (err) {
//             console.log("Service worker registration failed, error:", err);
//         });
// }
// navigator.serviceWorker.addEventListener("message", (message) => {
//     let res = message["data"]["firebase-messaging-msg-data"]["notification"];
//     console.log(message["data"]);
//     toast.success("ddd");
// });
ReactDOM.render(
    <HomeProvider>
        <CartProvider>
            <LanguageProvider>
                <App />
            </LanguageProvider>
        </CartProvider>
    </HomeProvider>,
    document.getElementById("root")
);
