import React, { useState, useEffect, useContext } from 'react';
import VendorContext from "./../../../../context/vendorSignupData";
import 'react-toastify/dist/ReactToastify.css';
import "./locationStyle.css";
function UserType(props) {
    const [vendorData, setVendorData] = useContext(VendorContext);
    const [usertype, setType] = useState('professional')
    const [isDisabled, setDisabled] = useState(true)
    useEffect(() => {
        let userInfo = vendorData;
        userInfo["userType"] = 'professional';
        setVendorData({ ...userInfo })
    }, [])
    const handleChange = (e) => {
        let userInfo = vendorData;
        userInfo["userType"] = e.target.value;
        setVendorData({ ...userInfo })
        setType(e.target.value);
    }
    useEffect(() => {
        usertype !== '' ? setDisabled(false) : setDisabled(true);
    }, [usertype])
    return (
        <div className="userType">
            <h2>I AM A</h2>
            <div className="row">
                <div className="col-lg-6">
                    <div className="inputGroup bg-light" >
                        <input
                            id="currentLocation"
                            onChange={handleChange}
                            value="professional"
                            name="usertype"
                            checked={usertype == "professional"}
                            type="radio"
                        />
                        <label htmlFor="currentLocation">Service Professional</label>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="inputGroup bg-light" >
                        <input
                            id="customLocation"
                            onChange={handleChange}
                            name="usertype"
                            value="promoter"
                            checked={usertype == "promoter"}
                            type="radio"
                        />
                        <label htmlFor="customLocation">Promoter</label>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <p className="text-right"><button className="btn btn-primary" disabled={isDisabled} onClick={props.nextStep}>Next Step</button></p>
                </div>
            </div>
        </div>
    )
}

export default UserType;