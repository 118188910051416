import React from "react";
import useToggle from "./../../hook/toggle";

const Accordion = ({ heading, children }) => {
  const { isOpen, handleClick } = useToggle();

  return (
    <div className="card">
      <div className="card-header" id="headingOne">
        <h5 className="mb-0" onClick={handleClick}>
          <button className="btn btn-link accordion-toggle text-left">{heading}</button>
        </h5>
      </div>

      <div className={`collapse ${isOpen ? "show" : ""}`}>
        <div className="card-body">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
