import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import generalApi from "../../services/generalApi";
import Loader from "./../../component/shared/loader";
// import './../../assets/css/chechboxes.css'
import TopImage from "./../../component/product/topImage";
import { toast } from "react-toastify";
import ShortInfo from "./../../component/vendor/dashboard/shortInfo";
import "react-toastify/dist/ReactToastify.css";
function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [orderData, setOrderData] = useState([]);
    const [employeeList, setEmployee] = useState([]);
    let userData = JSON.parse(localStorage.getItem("user_data"));
    useEffect(() => {
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            certificate: userData["certificate"],
        };
        generalApi.getData("SSMOrderBalance", json).then((response) => {
            console.log("Complete Order data", response["data"]);
            setOrderData(response["data"]);
            generalApi.getData("SSMEmployeeList", json).then((response) => {
                console.log("Complete Order data", response["data"]);
                setEmployee(response["data"]);
                setLoading(false);
            });
        });
    }, []);
    const changeEmployeeStatus = (status, employeeCode) => {
        let userData = JSON.parse(localStorage.getItem("user_data"));
        const json = {
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            certificate: userData["certificate"],
            employeeid4status: employeeCode,
            status: status ? 1 : 0,
        };
        console.log("jsonjson", json);

        generalApi.getData("SSMEmployeeCheckInOut", json).then((response) => {
            if (response["data"]["statuscode"] == "000") {

            } else {
                toast.error(response["data"]["statusdesc"]);
            }
        });
    };
    if (loading) {
        return <Loader></Loader>;
    }
    return (
        <div className="cart-box-container">
            <div className="container container-ver2">
                <TopImage text="Dashboard" />
                <div className="mt-1">
                    <div className="row dashboard__list">
                        <ShortInfo
                            heading="New Order"
                            totalorder={userData["neworders"]}
                            link="/vendor/new-order"
                        ></ShortInfo>
                        <ShortInfo
                            heading="On Load Order"
                            totalorder={userData["loadorders"]}
                            link="/vendor/load-order"
                        ></ShortInfo>
                        <ShortInfo
                            heading="On Transit"
                            totalorder={userData["transitorders"]}
                            link="/vendor/transit-order"
                        ></ShortInfo>
                        <ShortInfo
                            heading="Balance Orders"
                            totalorder={userData["quotations"]}
                            link="/vendor/balance-order"
                        ></ShortInfo>
                        <ShortInfo
                            heading="Completed Orders"
                            totalorder={userData["completeorders"]}
                            link="/vendor/order-history"
                        ></ShortInfo>
                    </div>
                </div>
                <div className="employee_status">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Employee Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeList["employeelist"].map((info, i) => (
                                <tr key={info["employeecode"]}>
                                    <td>{i + 1}</td>
                                    <td>{info["employeename"]}</td>
                                    <td>
                                        <div className="page-login">
                                            <form>
                                                <div className="checkbox">
                                                    <input
                                                        id={info["employeecode"]}
                                                        type="checkbox"
                                                        onChange={() =>
                                                            changeEmployeeStatus(
                                                                !parseInt(info["checkinstatus"]),
                                                                info["employeecode"]
                                                            )
                                                        }
                                                        defaultChecked={
                                                            info["checkinstatus"] == 1
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <label
                                                        className="label-check"
                                                        for={info["employeecode"]}
                                                    ></label>
                                                </div>
                                            </form>
                                        </div>
                                        {/* <label className="switch">
                                            <input type="checkbox" onChange={() => changeEmployeeStatus(!parseInt(info["checkinstatus"]), info["employeecode"])} defaultChecked={info["checkinstatus"] == 1 ? true : false} />
                                            <span className="slider round"></span>
                                        </label> */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
export default Dashboard;
