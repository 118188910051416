import React, { useContext } from "react";
import { ismember, priceinfo, shippingCharges, setnumber } from "./../../config/helper";
import { HomeContext } from "./../../context/homeContext";
import { OrderContext } from "./../../context/orderContext";
import { CartContext } from "./../../context/cartContext";
import { LoginContext } from "./../../context/loginContext";
import { useTranslation } from "react-multi-lang";
function PriceDetailsFooter(props) {
    let currencySymbol = props.currencySymbol;
    const t = useTranslation();
    const [isAuth] = useContext(LoginContext);
    const [cartData] = useContext(CartContext);
    const [homeData] = useContext(HomeContext);
    const [order] = useContext(OrderContext);
    let userinfo = JSON.parse(localStorage.getItem("user_data"));
    console.log("lastpage", order);

    let price = priceinfo(cartData);
    let shippingCharge = shippingCharges(
        price["totaloldprice"],
        price["discount"],
        homeData["shippingwaiver"],
        homeData["shippingamount"]
    );

    return (
        <React.Fragment>
            <tr>
                <td colSpan="8" className="ar__text-left price_details_style">
                    {t("checkout.total_amount")}
                </td>
                <td className="text-right">
                    {currencySymbol} {setnumber(price["taxtotalprice"])}
                </td>
            </tr>
            <tr>
                <td colSpan="8" className="ar__text-left price_details_style">
                    {t("checkout.shipping_amount")}
                </td>
                <td className="text-right">
                    {currencySymbol} {setnumber(shippingCharge)}
                </td>
            </tr>
            {ismember(isAuth["isLogin"]) ? (
                <React.Fragment>
                    <tr>
                        <td colSpan="8" className="price_details_style">
                            {t("checkout.member_type")}{" "}
                        </td>
                        <td className="text-right">{userinfo["segmentname"]}</td>
                    </tr>
                    <tr>
                        <td colSpan="8" className="price_details_style">
                            {t("checkout.member_discount")}{" "}
                        </td>
                        <td className="text-right">{userinfo["maxspend"] + "%"}</td>
                    </tr>
                </React.Fragment>
            ) : (
                ""
            )}
            {parseInt(order["coupndiscount"]) > 0 ? (
                <tr>
                    <td colSpan="8" className="price_details_style">
                        {t("shoppingcart.coupen_redeem")}
                    </td>
                    <td className="text-right">
                        {currencySymbol}
                        {setnumber(order["coupndiscount"])}
                    </td>
                </tr>
            ) : null}

            <tr>
                <td colSpan="8" className="price_details_style ar__text-left">
                    {t("checkout.net_amount")}
                </td>
                <td className="text-right">
                    {currencySymbol}{" "}
                    {(
                        parseFloat(price["taxtotalprice"]) +
                        parseFloat(shippingCharge) -
                        order["coupndiscount"]
                    ).toFixed(2)}
                </td>
            </tr>
        </React.Fragment>
    );
}
export default PriceDetailsFooter;
