import { useState } from "react";

const useToggle = (isOpenDefault = false) => {
  const [isOpen, setOpen] = useState(isOpenDefault);

  const handleClick = event => {
    event.stopPropagation();
    setOpen(!isOpen);
    console.log("isopenisopenisopen");
  };

  return {
    isOpen,
    handleClick
  };
};

export default useToggle;
