import React, { useState, useEffect } from "react";
import * as dayjs from "dayjs";
import { withRouter } from "react-router-dom";
import generalApi from '../../../services/generalApi'
import { toast } from 'react-toastify';
import AutoFileAddress from './../../shared/autoFillAddress'
function ChangeDeliery(props) {
    let info = props.orderinfo
    let deliverydate = dayjs().format("YYYY-MM-DD");
    const [deliveryDate, setDate] = useState(deliverydate)
    const [landmark, setLandmark] = useState(info["address"])
    const [geolocation, setGeolocation] = useState(info["geolocation"]);
    const [location, setlocation] = useState();
    const [isactiv, setActive] = useState(false);
    let vehiclename = info["vehiclename"] === '' ? props.vichlelist[0]["employeecode"] : info["vehiclename"]
    const [vichle, setVichle] = useState(vehiclename)
    const currentlocation = (info) => {
        if (info["geolocation"] !== '') {
            setGeolocation(info["geolocation"]);
            setlocation(info["location"])
            setLandmark("");
        }
    }
    const changeDate = (e) => {
        setDate(e.target.value)
    }
    const changeLandmark = (e) => {
        setLandmark(e.target.value)
    }
    const vichleChange = (e) => {
        setVichle(e.target.value)
    }
    const updateDeliveryinfo = (e) => {
        e.preventDefault();
        setActive(true);
        let userData = JSON.parse(localStorage.getItem("user_data"))
        const json = {
            certificate: userData["certificate"],
            orderid: info["orderid"],
            outlet: userData["outletid"],
            employeeid: userData["employeeid"],
            deliverydate: dayjs(deliverydate).format("DD:MM:YYYY:HH:MM:ss"),
            address: landmark + " " + location,
            geolocation: geolocation,
            employeeaccount: vichle,
            fbtoken: "0000",
        }
        generalApi.getData('SSMChangeDeliveryDetails', json).then(response => {
            if (response["data"]["statuscode"] == "000") {
                let message = { heading: "Success!", des: `Order # ${info["orderid"]} successfully changed delivery`, link: "/vendor/new-order", linkname: "New Order" }
                localStorage.setItem('message', JSON.stringify(message))
                props.history.push('/message')
            } else {
                setActive(false);
                toast.error(response["data"]["statusdesc"])
            }
        });
    }
    return (
        <div
            id="item-details"
            className={`modal modal__custom ${props.isOpen ? "show" : "fade"}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="item-title"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="item-title">
                            Change Delivery
                        </h5>
                        <button
                            onClick={props.closeModal}
                            className="close close__btn"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={updateDeliveryinfo}>
                        <div className="modal-body">
                            <div className="group box">
                                <label className="control-label">Delivery Date</label>
                                <input type="date" value={deliveryDate} onChange={changeDate} required className="form-control" />
                            </div>
                            <div className="group box">
                                <label className="control-label">Address</label>
                                <textarea required name="landmark" value={landmark} onChange={changeLandmark} className="form-control"></textarea>
                            </div>
                            <div className="group box">
                                <AutoFileAddress location={location} currentlocation={currentlocation}></AutoFileAddress>
                            </div>
                            <div className="group box">
                                <label className="control-label">Vichle</label>
                                <select required className="form-control" defaultValue={vichle} onChange={vichleChange}>
                                    {props.vichlelist.map((vic) => (
                                        <option key={vic["employeecode"]} value={vic["employeecode"]}>{vic["employeename"]}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-info btn__custom-modal market-bcolor" onClick={props.closeModal}>Close</button>
                            <button type="submit" disabled={isactiv ? true : false} className="btn btn-danger raashan-bcolor btn__custom-modal">{isactiv ? "Processing..." : "Update"}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default withRouter(ChangeDeliery);
